import React , { useState }from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import {BsArrowRight} from 'react-icons/bs';
import { useFormik } from 'formik';
import validator from 'validator';
import './exitModal.css';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';

const ExitModal = ({ onCancel, onConfirm }) => {
  var _ = require('lodash');
    const [btnLoader, setBtnLoader] = useState(false);
    const [isThankYou, setIsThankYou] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleNumericInputChange = (val) => {
      const value = val.replace(/\D/g, ''); 
  
      return value;
    };

    const removeExtraSpace = (s) => {
       var rSpase = s.replace(/\s{2,}/g, ' ');
       return _.trimStart(rSpase);
    };

    const navigate = useNavigate();

    const quickContact  = {
        name: "",
        email: "",
        phone: "",
        message:"",
        tryNow: "Where should we send the case studies?",
    };


    const validate = values => {
        const errors = {};
    
        if (!values.name || !_.trim(values.name)) {
            errors.name = 'Name is required';
        } else if (values.name.length > 50) {
            errors.name = 'Must be 15 characters or less';
        } else if (values.name.match("[<>]")) {
            errors.name = 'Please provide a valid name';
        }
    
        if (!values.email ) {
            errors.email = 'Email is required';
        } else if ( !validator.isEmail(values.email)) {
            errors.email = 'Invalid email address';
        } 

        if (!values.phone) {
            errors.phone = 'Phone number is required';
        } else if ( !validator.isMobilePhone(values.phone)) {
            errors.phone = 'Invalid phone number';
        }

    
        return errors;
    };

    function myGreeting() {
        setIsThankYou(false);
        setIsError(false);
    };

    const quickFormik = useFormik({
        initialValues: quickContact,
        validate,
        onSubmit: (values, {resetForm}) => {
            setBtnLoader(true);

            // console.log(values)
            axios.post(`/lets-chat`, values)
            .then(function (response) {
                resetForm({values: ""});
                // navigate('/thank-you');
                localStorage.setItem('exitModal', 'Yes');
                setIsThankYou(true);
                onConfirm();
                
            })
            .catch(function (error) {
                setIsError(error?.message ? error.message: "Network Error")
            })
            .finally(() => {
                setBtnLoader(false)
                setTimeout(myGreeting, 3000);
            });
        },
    });


  return (
  
      <div className="exit-modal">
        <div className="modal-content">
          
            <button type="button" className="close pink-close" onClick={(e) => onCancel(e)} >
              <AiOutlineClose fontSize={19} />
            </button>
            
          <div className="exit-modal-top">

            {/* <h3>
              Wait!! Before you go..
            </h3> */}
          
            <h4>
                 Get absolute 40% Discount offer to start your project
            </h4>
            <p>
                Enter the below details to avail it
            </p>
          </div>

          
          <div className="schedule_consultation-box">

              <form onSubmit={quickFormik.handleSubmit} id="quickFormik">
                          
                <div className="form-group">
                  <label>Name*</label>
                  <input type="text" name="name" className='input-style' onChange={quickFormik.handleChange} autoCapitalize="none" autoComplete="new-password"
                      value={quickFormik.values.name} onBlur={quickFormik.handleBlur} placeholder="Enter your name"/>
                      {quickFormik.touched.name && quickFormik.errors.name ? <div style={{color: '#ff0000'}}>{quickFormik.errors.name}</div> : null}
                </div>
                                
                              
                <div className="form-group">
                    <label>Email*</label>
                    <input className='input-style' onChange={quickFormik.handleChange} autoCapitalize="none" autoComplete="new-password"
                    value={removeExtraSpace(quickFormik.values.email)} onBlur={quickFormik.handleBlur} type="email" name="email" placeholder="What is your email?" />
                    {quickFormik.touched.email && quickFormik.errors.email ? <div style={{color: '#ff0000'}}>{quickFormik.errors.email}</div> : null}
                </div>

                <div className="form-group">
                    <label>Phone Number*</label>
                    <PhoneInput 
                        name="phone"
                        placeholder="Enter phone number"
                        international
                        defaultCountry={"IN"}
                        autoCapitalize="none" autoComplete="new-password" 
                        countryCallingCodeEditable={false}
                        value={quickFormik.values.phone}
                        onBlur={quickFormik.handleBlur}
                        onChange={(val) => quickFormik.setFieldValue('phone', val)}/>
                    {quickFormik.touched.phone && quickFormik.errors.phone ? <div style={{color: '#ff0000'}}>{quickFormik.errors.phone}</div> : null}
                </div>

                <div className="form-group">
                    <label>Message</label>
                    <textarea name="message" onChange={quickFormik.handleChange}
                        value={removeExtraSpace(quickFormik.values.message)} onBlur={quickFormik.handleBlur}></textarea>
                        {quickFormik.touched.message && quickFormik.errors.message ? <div style={{color: '#ff0000'}}>{quickFormik.errors.message}</div> : null}
                </div>
                <br/>



                <div className='exit-modal-btns'>    
                    <button type="submit" className="default-btn">
                        Download IT Now &nbsp; &nbsp;
                        {btnLoader ? (
                            <span className='btn-ring-animation'></span>
                        ):(
                            <BsArrowRight size={18} />
                        )}
                    </button>

                    {/* <button type="submit" className="download-btn">
                        Download IT Now
                    </button> */}
                </div> 
                                    
                  
              </form>

                {isThankYou && (
                    <div className='alert alert-success mt-3'>
                        Thank you for getting in touch! 
                    </div>
                )}
                {isError && (
                    <div className='alert alert-error mt-3'>
                        {isError} 
                    </div>
                )}
          </div>
           
          
        </div>
      </div>

  )
};

export default ExitModal;