import React, { useRef, useState, useEffect } from "react";
import {
  HeaderLogo,
  FooterLogo,
  TrustedLogo1,
  TrustedLogo8,
  TrustedLogo7,
  TrustedLogo6,
  TrustedLogo5,
  TrustedLogo4,
  TrustedLogo3,
  TrustedLogo2,
  TrustedLogo9,
  TrustedLogo10,
  Trustpilot,
  googleLogo,
  Technologies1,
  Technologies2,
  Technologies4,
  Technologies3,
  Technologies5,
  Technologies6,
  Technologies7,
  Technologies8,
  Technologies9,
  Technologies10,
  Technologies11,
  Technologies12,
  Technologies13,
  Technologies14,
  Goodfirms, Clutch, Getexperticon1, Getexperticon2, Getexperticon3, Getexperticon4, OfferingIcon1, OfferingIcon2, OfferingIcon3, OfferingIcon4, OfferingIcon5, OfferingIcon6, OfferingIcon7, OfferingIcon8, WhyreddensoftRight, WhyreddensoftRightstar, ClutchN, HireExperienceWomen, batchone, batchthree, Kimberly, ADukes, PGFavors, Michel, David, Eric, ReviewFullStart, Andrew, NoImage, Bently, James, Jayson, Younges, Ryan, TrastedLogoNew,
} from "../../Assets/index";

import { Transition } from "@headlessui/react";
import ScheduleConsultation from "../ScheduleConsultation";
import WebFont from "webfontloader";
import "../FullstackDevelope/App.css";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import axios from "axios";
import Slider from 'react-slick';
import "./App.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import validator from 'validator';
import CaseStudiesModal from "../CaseStudiesModal";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { CSSTransition } from 'react-transition-group';

import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';



export default function FullStackLanding() {
  var _ = require('lodash');

  const removeExtraSpace = (s) => {
    var rSpase = s.replace(/\s{2,}/g, ' ');
    return _.trimStart(rSpase);
  };

  const trastSlider = {
    infinite:true, 
    speed:500,
    arrows: false,
    dots: false,
    autoplay: true,
    slidesToShow:8, 
    slidesToScroll:1,
    responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 7
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3
          }
        }
    ]
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Roboto Slab:200,300,400",
          "Montserrat:300,400,500,700",
          "Patua One",
          "Mulish",
        ], 
      },
      custom: {
        families: ["Patua One"], 
        urls: [
          "https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400&display=swap",
          "https://fonts.googleapis.com/css2?family=Montserrat:wght@300,400;500;700&display=swap",
          "https://fonts.googleapis.com/css2?family=Mulish&family=Patua+One&display=swap",
        ],
      },
    });

  }, []);

  const [scheduleModel, setScheduleModel] = useState(false);
  const [messageModel, setMessageModel] = useState(false);
  const [isTry, setIsTry] = useState();


  const HandelScheduleModel = () => {
    setScheduleModel(true)
  };


  const [ isStartFreeTrial, setIsStartFreeTrial ] = useState(false);

  const HandelMessageModel = (e, val) => {
    if (val) {
      setIsTry(val);
    } else {
      setIsTry();
    }

    if(val === 'Try Free for 15 Days'){
      setIsStartFreeTrial(true);
    }else{
      setIsStartFreeTrial(false);
    }
    setMessageModel(true);
  };

  const toggleModal = () => {
    setScheduleModel(false);
    setMessageModel(false);
    setIsCaseStudies(false);
  };





  const [isThankYou, setIsThankYou] = useState(false);
  const [isError, setIsError] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const [isThankYouTwo, setIsThankYouTwo] = useState(false);
  const [isErrorTwo, setIsErrorTwo] = useState(false);
  const [btnLoaderTwo, setBtnLoaderTwo] = useState(false);

  const [btnLoaderThree, setBtnLoaderThree] = useState(false);

  const [isResponsiveMenu, setIsResponsiveMenu] = useState(false);

  function myGreeting() {
    setIsThankYou(false);
    setIsError(false);
    setIsThankYouTwo(false);
    setIsErrorTwo(false);
  }

  const [guidelineModal, setGuidelineModal] = useState(false);

  const HandelGuidelineModal = () => {
      setGuidelineModal(!guidelineModal)
  }

  const navigate = useNavigate();

  const ref = useRef(null);
  const pricing = useRef(null);
  const faqMenu = useRef(null);
  const testmonialsMenu = useRef(null);
  const homeMenu = useRef(null);
  const portFolio = useRef(null);

  const HandelPageScroll = () => {
      ref.current?.scrollIntoView({behavior: 'smooth'});
  };

  const [isCaseStudies, setIsCaseStudies] = useState(false);

  const HandelCaseStudiesModel = () => {
    setIsCaseStudies(true);
  }


  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  let location = window.location.href;
  const dropdownRef = useRef(null);
  const Modalref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsResponsiveMenu(false);
      }

      if (Modalref.current && !Modalref.current.contains(event.target)) {
        setGuidelineModal(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);



  async function PortfolioApi() {
    axios.get('/portfoliodata')
   .then(await function  (response) {
       if(response && response?.data && response?.data?.data){
           setPortData(response?.data?.data)
       }
   })
   .catch(await function (error) {
       console.log(error)
   })
   .finally(() => {
     console.log('Experiment completed');
     setIsLoader(false);
   }); 
}



  useEffect(() => {
    setIsLoader(true);
    PortfolioApi();

    // window.scrollTo({ top: 0, behavior: 'smooth' });
    // const searchParams = new URLSearchParams(window.location.search);
    // const param1 = searchParams.get('price');
    // if(param1 === 'monthlt'){
    //   pricing.current?.scrollIntoView({behavior: 'smooth'});
    //   // pricing.current?.scrollTop = 0;
    // }
    
 
  }, []);

  

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [portData, setPortData] = useState();
  const [isLoader, setIsLoader] = useState(false);

  var projectSliderNav = {
    asNavFor:nav1, 
    ref: (slider2) => setNav2(slider2),
    slidesToShow:5,
    slidesToScroll: 1,
    autoplay: true,
    arrows:false,
    speed: 1000,
    autoplaySpeed: 3000,
    swipeToSlide:true,
    focusOnSelect:true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          }
        },
        {
            breakpoint: 500,
            settings: {
              slidesToShow: 2,
            }
          }
    ]
  };

  var projectSlider = {
      asNavFor: nav2,
      ref:(slider1) => setNav1(slider1),
      fade:true,
      arrows:false,
  };

  const accordionItems = [
    {
      id: 1,
      title: "Is the 15-days free trial completely free?",
      content: "Yes, it's completely free for your convenience. We permit this free trial to evaluate your satisfactory work. You get to hire the trial resource and if not desirable, we will assign one more resource to match your expectations. If nothing suits you, then feel free to cancel it right away.",
    },
    {
      id: 2,
      title: "What engaging models do you have?",
      content: "For part time remote hiring, $22.00/ hour is fixed and for full-time remote talent, $20.00/ hour.",
    },
    {
      id: 3,
      title: "Do I get the chance to work directly with the developer?",
      content: "Yes, definitely you get the chance to work directly with the developers as this comprehends them for crystal clear understanding and able to match your project’s requirements.",
    },

    {
      id: 4,
      title: "What are the working hours for developers?",
      content: "20 hours weekly is to be contributed by the part-time remote developers and 40 hours weekly is to be maintained by the full-time developers. And for further flexibility, you can take small steps as 1 hour per day.",
    },
    {
      id: 5,
      title: "Will you assign a dedicated Project Manager - Scrum as a point of contact?",
      content: "Yes. Dedicated project manager – Scrum master will be assigned throughout our engagement. With whom you can get in touch for any sort of assistance, query, or resolution.",
    },
    {
      id: 6,
      title: "What project management tools do you use?",
      content: "We use industry-standard project management tools like Jira, Trello, Pivotal Tracker, Asana, and Slack. Where you will be able to track developers' productivity and monitor the daily progress of your project, you can create the task, assign the work, and monitor the development progress.",
    },
    {
      id: 7,
      title: "What if I am not Satisfied with the hired developer’s work and would like to exit?",
      content: "Be assured that will never happen. Client satisfaction is our highest priority. Either Party to this agreement may terminate the contract, with or without cause, by providing at least 14 days (2) weeks written notice to the other Party. Upon termination of this agreement, Reddensoft shall deliver a termination invoice for work completed to date, which shall be paid by the client (in 3 business days).<br/><br/> The Termination invoice shall specify all unpaid work and the remaining work done until the termination date. On termination, all work completed to time shall be delivered to the client in a usable electronic format.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };


  const videoslider = {
    infinite:true, 
    speed:500,
    arrows: false,
    dots: false,
    autoplay: true,
    slidesToShow:4, 
    slidesToScroll:1,
    responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow:1
          }
        }
    ]
  };

  const videos = [
    {
      id: "dajz-iEApdo",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "I have been working on a 'pet' project for quite some time now. It’s...",
    },
    {
      id: "i-byBdoTZ6Y",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "I have been in the online business for a very long time now. I just wanted...",
    },
    {
      id: "aD76eU82KUw",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "I have a company called Benefit Personal Training and we have been working...",
    },
    {
      id: "riUi3czB8C0",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "They have done advanced software programs for me to run my multiple...",
    },
    {
      id: "dajz-iEApdo",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "I have been working on a 'pet' project for quite some time now. It’s...",
    },
    {
      id: "i-byBdoTZ6Y",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "I have been in the online business for a very long time now. I just wanted...",
    },
    {
      id: "aD76eU82KUw",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "I have a company called Benefit Personal Training and we have been working...",
    },
    {
      id: "riUi3czB8C0",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "They have done advanced software programs for me to run my multiple...",
    },
    
  ];

  const [openVideoModal, setVideoModal] = useState(false);
  const [currentvideoId, setCurrentvideoId] = useState('riUi3czB8C0');

  const HandelYoutubeVideo = (val) => {
    console.log(val);
    setCurrentvideoId(val);
    setVideoModal(!openVideoModal);
  }



  return (
    <section className="sales-page graphics landibgfullstack" ref={homeMenu}>
      <Helmet>
        <title>Hire Full-stack Developer | Reddensoft, USA</title>
        <script id="facebook-pixel-script">
          {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js'),
                fbq('init', '273096641131718'),
                fbq('track', 'PageView')
                `}
        </script>
        <noscript id="facebook-pixel-image">
          {`
                    <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=273096641131718&ev=PageView&noscript=1"
                />
                `}
        </noscript>
      </Helmet>

      <section className="banner">
        <div className={` header-section ${scrollPosition > 50 ? "fixed-header slide-in" : ""}`}>
          <div className="container">
            <div className="header-part">
              <a className="logo" onClick={() => homeMenu.current?.scrollIntoView({behavior: 'smooth'})}>
                <img src={HeaderLogo} alt="Reddensoft logo" />
              </a>
              <div ref={dropdownRef} className={` ${isResponsiveMenu ? 'active':''}  header_middle`}>
                <ul>
                  <li><a onClick={() => {portFolio.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Our Portfolio</a></li>
                  <li><a onClick={() => {pricing.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Pricing</a></li>
                  <li><a onClick={() => {testmonialsMenu.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Testimonial</a></li>
                  <li><a onClick={() => {faqMenu.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">FAQs</a></li>
                </ul>
              </div>


              <div className="header_right">
                <a
                  onClick={(e) => HandelMessageModel(e, 'Try Free for 15 Days')}
                  className="full-stack-top-btn "
                >
                  <span>Try Free for 15 Days</span>
                </a>

                <a
                  onClick={(e) => HandelScheduleModel(e, 'Schedule a Call')}
                  className="full-stack-top-btn fill-btn mobile-none"
                >
                  <span>Schedule a Call</span>
                </a>
                

                <button className="bars-menu" ref={dropdownRef} onClick={() => setIsResponsiveMenu(!isResponsiveMenu)}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={22} height={22}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                </button>



              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="banner-section">
            <div className="banner-left">
            <h4>USA’s #1 <span>Full-Stack Development Team</span></h4>
              <h2><span>Hire</span> Dedicated <br/> <strong className="fullstack_bg"> Full-Stack </strong> Developers <br/> within 24hrs</h2>
              <h3>Looking for Fast Developers and Deliveries?</h3>
              <p>Don't leave your development projects to chance. Hiring our developers will help to boost your business with quick deleveries.</p>
              <div className="fullstack_banner_ul">
                <ul>
                  <li>15 Days Free Trial</li>
                  <li>Dedicated Project Manager <span className="image-text">Free</span></li>
                  <li>Quick Team Set-up &amp; Onboarding </li>
                </ul>
                <ul>
                  <li>No Expense on Retaining &amp; Training</li>
                  <li>IP Security and Ownership</li>
                  <li>Quick Product Delivery </li>
                </ul>
              </div>
              
              <p>Our goal is to deliver high-quality solutions that are reasonable yet remarkable.</p>


              <p className="banner-location">
                Working with Clients across-
                <span> California &nbsp;|&nbsp; Texas  &nbsp;|&nbsp; Illinois &nbsp;|&nbsp; Florida &nbsp;|&nbsp; New York 
                  &nbsp;|&nbsp; Toronto &nbsp;|&nbsp; Montreal &nbsp;|&nbsp; Vancouver &nbsp;|&nbsp; Calgary &nbsp;|&nbsp; Mexico City &nbsp;|&nbsp; World Wide </span>
              </p>


              <div>
                  <a onClick={(e) => HandelScheduleModel(e, 'Schedule a Call')}
                    className="full-stack-top-btn fill-btn banner-full-btn"
                  >
                    <span>Schedule a Call</span>
                  </a>
              </div>



            </div>

            <div className="banner-developer-sec">
              
              {/* <div className="banner-developer">
                <div className="banner-developer-img">
                  <img src={MikeBanner} alt="" />
                </div>
                <h5>Chris Callaway</h5>
                <p>Fullstack Developer</p>
                <div className="tag-section">
                  <button className="tag" onClick={(e) => HandelMessageModel(e, 'Hire a Developer Chris Callaway')}>Connect</button>
                </div>
              </div> */}

              {/* <div className="banner-developer">
                <div className="banner-developer-img">
                  <img src={ChrisBanner} alt="" />
                </div>
                <h5>Sumit Santra</h5>
                <p>Fullstack Developer</p>
                <div className="tag-section">
                  <button className="tag" onClick={(e) => HandelMessageModel(e, 'Hire a Developer Sumit Santra')}>Connect</button>
                </div>
              </div> */}

              <div className="banner-form">
                <h3>Find Out More. Fill the Below Form to <span className="text-primary">Get a Call Back</span></h3>

                <Formik
                  initialValues={{ name:"", email: "", phone: "", message: "", pageName: location, tryNow: "Find Out More. Fill the Below Form to Get a Call Back" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = "Email is required";
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                      errors.email = "Invalid email address";
                    }

                    if (!values.phone) {
                      errors.phone = 'Phone number is required';
                    } else if ( !validator.isMobilePhone(values.phone)) {
                      errors.phone = 'Invalid phone number';
                    }

                    if (!values.name || !_.trim(values.name)) {
                      errors.name = 'Name is required';
                    } else if (values.name.length > 50) {
                      errors.name = 'Must be 15 characters or less';
                    } else if (values.name.match("[<>]")) {
                      errors.name = 'Please provide a valid name';
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setBtnLoaderThree(true);
                    setSubmitting(false);
                    axios
                      .post(`/quickContact`, values)
                      .then(function (response) {
                        // console.log(response);
                        navigate('/thank-you');
                      })
                      .catch(function (error) {
                        // console.log(error)
                        setIsErrorTwo(
                          error?.message ? error.message : "Network Error"
                        );
                      })
                      .finally(() => {
                        setBtnLoaderThree(false);
                        setTimeout(myGreeting, 5000);
                        values.email = "";
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                  }) => (

                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input type="text" onChange={handleChange} onBlur={handleBlur} name="name" value={removeExtraSpace(values.name)} className="form-control" autoCapitalize="none" autoComplete="new-password" maxLength="30" size="20" placeholder="Your Name" />
                          {touched.name && errors.name ? <div style={{color: '#ff0000'}}>{errors.name}</div> : null}
                        </div>
                        <div className="form-group">
                          <input type="text" onChange={handleChange} onBlur={handleBlur} name="email" value={removeExtraSpace(values.email)} className="form-control" autoCapitalize="none" autoComplete="new-password"  maxLength="30" size="20" placeholder="Email Address" />
                          {touched.email && errors.email ? <div style={{color: '#ff0000'}}>{errors.email}</div> : null}
                        </div>
                        <div className="form-group">
                        <PhoneInput 
                          name="phone"
                          placeholder="Enter phone number"
                          international
                          defaultCountry="US"
                          countryCallingCodeEditable={false}
                          value={values.phone}
                          onBlur={handleBlur}
                          autoCapitalize="none" autoComplete="new-password"
                          onChange={(val) => setFieldValue('phone', val)}/>
                          {/* <input type="text" onChange={handleChange} onBlur={handleBlur} name="name" value={values.name} className="form-control" maxlength="80" size="20" placeholder="Phone Number" /> */}
                          {touched.phone && errors.phone ? <div style={{color: '#ff0000'}}>{errors.phone}</div> : null}
                        </div>
                        <div className="form-group">
                          <textarea onChange={handleChange} onBlur={handleBlur} name="message" value={removeExtraSpace(values.message)} className="form-control" rows="3" placeholder="Your Message"></textarea>
                          {touched.message && errors.message ? <div style={{color: '#ff0000'}}>{errors.message}</div> : null}
                        </div>
                        <div className="">
                          <button type="submit" className="full-stack-btn" name="contactBtn">
                            <strong>Hire <strong class="fullstack_bg"> Full-Stack </strong> Developer</strong>
                            {btnLoaderThree && (
                              <span className="btn-ring-animation"></span>
                            )}
                          </button>
                        </div>
                    </form>
                  )}

                </Formik>
              </div>

              <div className="hiredesigner_batch">
                <div className="hiredesigner_batchsub">
                  <img src={batchone} alt="" />
                </div>
                <div className="hiredesigner_batchsub">
                  <img src={batchthree} alt="" />
                </div>
                <div className="hiredesigner_batchsub">
                  <img src={TrastedLogoNew} alt="" />
                </div>
              </div>

            </div>
          </div>

          <div>
            <div className="trusted-section">
              <div className="trusted-logo-box">
                <h5>Trusted By:</h5>

                <div>
                  <Slider {...trastSlider}>
                    <img src={TrustedLogo1} alt=''/>
                    <img src={TrustedLogo2} alt=''/>
                    <img src={TrustedLogo3} alt=''/>
                    <img src={TrustedLogo4} alt=''/>
                    <img src={TrustedLogo5} alt=''/>
                    <img src={TrustedLogo6} alt=''/>
                    <img src={TrustedLogo7} alt=''/>
                    <img src={TrustedLogo8} alt=''/>
                    <img src={TrustedLogo9} alt=''/>
                    <img src={TrustedLogo10} alt=''/>
                  </Slider>
                </div>
              </div>
            </div>

            <div className="trusted-section">
              <div className="trusted-logo-box">
                <div className="technologi-heading-section">
                  <h5 className="technologi-heading">Technologies we work with:</h5>
                </div>
                

                <div className="technologies-logo">
                    <div>
                      <img src={Technologies1} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies2} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies3} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies4} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies5} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies6} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies7} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies8} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies9} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies10} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies11} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies12} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies13} alt=''/>
                    </div>
                  
                    <div>
                      <img src={Technologies14} alt=''/>
                    </div>
                    
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="get_experts">
          <div className="container">
          <div className="get_experts_total">
            <div className="get_experts_left">
              <h2>Get Experts For All Your Needs</h2>
              <p className="line">End of sleepless nights for unfinished projects as we are ready to support Software Development, Lifecycle along with flexible engagements to meet requirements.</p>
              <div className="number_count">
                  <div className="number_count_sub">
                    <div className="number_count_text">
                      <h3>15+</h3>
                      <p>Years of Experience</p>
                    </div>
                  </div>
                  <div className="number_count_sub">
                    <div className="number_count_text">
                      <h3>250+</h3>
                      <p>Agile Developers</p>
                    </div>
                  </div>
                  <div className="number_count_sub">
                    <div className="number_count_text">
                      <h3>1100+</h3>
                      <p>Global Clients</p>              
                    </div>
                  </div>
                  <div className="number_count_sub">
                    <div className="number_count_text">
                      <h3>1200+</h3>
                      <p>Projects</p>              
                    </div>
                  </div>
              </div>
              <button className="requerment_btn"  onClick={(e) => HandelMessageModel(e, 'SHARE YOUR REQUIREMENTS')}> SHARE YOUR REQUIREMENTS</button>
            </div>
            <div className="get_experts_right">

            <div className="get_experts_right_box">
              <div className="get_experts_right_box_top">
              <img src={Getexperticon1} alt=''/>
              <h3>Add Resources to Your Team</h3>
              </div>
              <ul>
                  <li>Bridge the gap between in-house teams</li>
                  <li>Re-scale team as per expectations</li>
                  <li>Flexible for long and short projects </li>
              </ul>
            </div>

            <div className="get_experts_right_box">
              <div className="get_experts_right_box_top">
              <img src={Getexperticon2} alt=''/>
              <h3>Team Building from Scratch</h3>
              </div>
              <ul>
                  <li>Get Developers, designers, QA, DevOps teams & PM</li>
                  <li>You have complete ownership over your project</li>
                  <li>Flexible for long and short projects</li>
              </ul>
            </div>

            <div className="get_experts_right_box">
              <div className="get_experts_right_box_top">
              <img src={Getexperticon3} alt=''/>
              <h3>Customized Project Costs</h3>
              </div>
              <ul>
                  <li>Receive Custom solutions as per expectations</li>
                  <li>Project within assigned budget</li>
                  <li>Flexible for long and short projects</li>
              </ul>
            </div>

            <div className="get_experts_right_box">
              <div className="get_experts_right_box_top">
              <img src={Getexperticon4} alt=''/>
              <h3>Vendor Transition</h3>
              </div>
              <ul>
                  <li>Get assurance to complete projects</li>
                  <li>Promise to take over your mid-way left project</li>
                  <li>Flexible for long and short projects</li>
              </ul>
            </div>

            </div>  
          </div>
          </div>
      </section>

      <section className="offering">
        <div className="container">
          <h3>Offering Top-Notch <strong class="fullstack_bg"> Full-Stack </strong> Development Services Since 2008</h3>
          <p>Hire Full-Stack developers with versatile skills aligning with your business requirements. Our Full-Stack developers build secure, scalable, and dynamic enterprise-grade, cost-effective React web apps.</p>
          <div className="offering_allbox">

            <div>
              <img src={OfferingIcon1} alt=''/>
              <h3>SaaS Product Development</h3>
            </div>
            <div>
              <img src={OfferingIcon2} alt=''/>
              <h3>Migration & Integration Services</h3>
            </div>
            <div>
              <img src={OfferingIcon3} alt=''/>
              <h3>UI/UX Development</h3>
            </div>
            <div>
              <img src={OfferingIcon4} alt=''/>
              <h3>Plugin Development</h3>
            </div>
            <div>
              <img src={OfferingIcon5} alt=''/>
              <h3>Front-End Development</h3>
            </div>
            <div>
              <img src={OfferingIcon6} alt=''/>
              <h3>Back-End Development</h3>
            </div>
            <div>
              <img src={OfferingIcon7} alt=''/>
              <h3>Upgrade Service</h3>
            </div>
            <div>
              <img src={OfferingIcon8} alt=''/>
              <h3>Support & Maintenance</h3>
            </div>

          </div>

          <button class="requerment_btn" onClick={(e) => HandelMessageModel(e, 'Inquire Now')}>Hire Full Stack Developer</button>
        </div>
      </section>

      <section className="why_redensoft">
        <div className="container">
          <div className="why_redensoft_total">
          <div className="why_redensoft_left">
            <h2>Why <span> Reddensoft </span> is the best choice?</h2>
            <div className="why_redensoft_left_allbox">

              <div>
                <h3>Proof of Quality</h3>
                <p>Our developers are well-versed with their specific tech stacks and have 8+ years of expertise. Check developers’ work samples, client reviews, and identity verification.</p>
              </div>
              <div>
                <h3>No Cost Until You Hire</h3>
                <p>Get a list of pre-vetted candidates. Interview as many as potential fits for your projects and start with the 15 Days Free Trial.</p>
              </div>
              <div>
                <h3>Safe and Secure</h3>
                <p>Focus on your work knowing we help protect your data and privacy with fully-signed NDA. We’re here with 24/7 support if you need it.</p>
              </div>
              <div>
                <h3>Seamless Hiring</h3>
                <p>Leave all your onboarding hustles on us. We manage all the hiring aspects including recruitment, human resources, legal, and payroll.</p>
              </div>

            </div>
            <button class="requerment_btn" onClick={(e) => HandelScheduleModel(e, 'Schedule a Call')}>Schedule a Call</button>
          </div>
          <div className="why_redensoft_left">
          <div className="why_redensoft_right_img">
          <div className="rating-box">
              <img src={WhyreddensoftRightstar} alt=''/>
              <h5>4.7/5</h5>
              <p className="text-white">Client Rating</p>
              <img src={ClutchN} alt=''/>
              <p>ReddenSoft has Consistently Proven to be the Best Outsourcing Partner</p>
          </div>
          <div className="man_img_box"> <img src={WhyreddensoftRight} alt=''/> </div>
            </div>
          </div>
          </div>
        </div>
      </section>

      {/* <section className="priceing_table" id="target-section" ref={pricing}>
        <div className="container">
          <h2>Our Flexible <span> Pricing </span> Packages </h2>

          <div className="priceingallbox">
            <div className="priceing_box">
                <div className="box-radius box-radius-side">
                    <h2>$2500/Month</h2>
                    <p>1 to 3 Years of Experience</p>
                </div>

                <ul>
                  <li>Front-end development <br/><small>(Bootstrap, WordPress, React.js, Vue.js, HTML)</small></li>
                  <li>Backend development <br/><small> (Laravel, MySQL, Mongo DB, CodeIgniter)</small></li>
                  <li>DevOps Tools <br/><small>(GitHub, GitLab, Bitbucket, Zapier, Docker)</small></li>
                  <li>Database Integration</li>
                </ul>

                <ul class="deactive-list"><li><svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clip-rule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fill-rule="evenodd"></path></svg> Mobile app development <br/><small>(Android, iOS)</small></li></ul>

                <ul>
                    <li>1 Dedicated Developer</li>
                    <li>160 Working Hours</li>
                    <li>Scrum Master - 10 Hrs/mo</li>
                    <li>Part-time QA &amp; DevOps</li>
                    <li>7 Days Free Trial</li>
                </ul>
                <button className="priceing_btn" onClick={(e) => HandelMessageModel(e, '1 to 3 Years of Experience Hire $2500/month')}>START NOW</button>
            </div>
            <div className="priceing_box">
                <div className="box-radius box-radius-side">
                <span class="popular-badge-2">Popular</span>
                    <h2>$3000/Month</h2>
                    <p>3 to 5 Years of Experience</p>
                </div>
                <ul>
                  <li>Front-end development <br/><small>(Bootstrap, WordPress, React.js, Vue.js, HTML)</small></li>
                  <li>Backend development <br/><small> (Laravel, MySQL, Mongo DB, CodeIgniter)</small></li>
                  <li>DevOps Tools <br/><small>(GitHub, GitLab, Bitbucket, Zapier, Docker)</small></li>
                  <li>Database Integration</li>
                </ul>

                <ul class="deactive-list"><li><svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clip-rule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fill-rule="evenodd"></path></svg> Mobile app development <br/><small>(Android, iOS)</small></li></ul>
                <ul>
                    <li>1 Dedicated Developer</li>
                    <li>160 Working Hours</li>
                    <li>Scrum Master - 20 Hrs/mo</li>
                    <li>24*7 QA & DevOps Support</li>
                    <li>15 Days Free Trial</li>
                    <li>Free Technical Consultation</li>
                    <li>Dedicated Project Manager</li>
                </ul>
                <button className="priceing_btn" onClick={(e) => HandelMessageModel(e, '3 to 5 Years of Experience Hire $3000/month')}>START NOW</button>
            </div>
            <div className="priceing_box">
                <div className="box-radius box-radius-side">
                    <h2>$3500/Month</h2>
                    <p>5+ Years of Experience</p>
                </div>
                <ul>
                  <li>Front-end development <br/><small>(Bootstrap, WordPress, React.js, Vue.js, HTML)</small></li>
                  <li>Backend development <br/><small> (Laravel, MySQL, Mongo DB, CodeIgniter)</small></li>
                  <li>DevOps Tools <br/><small>(GitHub, GitLab, Bitbucket, Zapier, Docker)</small></li>
                  <li>Database Integration</li>
                </ul>

                <ul><li>Mobile app development (Android, iOS)</li></ul>
                <ul>
                    <li>1 Dedicated Developer</li>
                    <li>160 Working Hours</li>
                    <li>Scrum Master - 30 Hrs/mo</li>
                    <li> 24*7 QA, DevOps and BA Support</li>
                    <li>15 Days Free Trial</li>
                    <li>   Free Technical Consultation</li>
                    <li>  Dedicated Project Manager</li>
                </ul>
                <button className="priceing_btn" onClick={(e) => HandelMessageModel(e, '5+ Years of Experience Hire $5500/month')}>START NOW</button>
            </div>
          </div>

         
        </div>
      </section> */}


      <section className="pricing_scaling" id="target-section" ref={pricing}>
        <div className="container">
          <div className="head-title">
            <h2>Easy and flexible <span className="color"> pricing </span> to form a team</h2>
            <p>
              Spend on what you need and get your project done with the most lucrative pricing plans.
            </p>
          </div>
          <div className="wrapper">
            <div className="divider">
              <div className="top-bar">
                <h3>Hire (Hourly)</h3>
                <p>Remote talent</p>
              </div>
              <div className="bottom-bar">
                <div className="scr_circle">
                  <h2>
                    <span>From</span>
                    $20
                    <span>/Hour</span>
                  </h2>
                </div>
                {/* <h4>Hourly (USD)</h4> */}

                <h4>
                  We’ll provide a fully signed NDA for your Project’s confidentiality
                </h4>

                <a onClick={(e) => HandelMessageModel(e, 'Hire (Hourly)')} className="link_up">
                  get started
                </a>
                
              </div>
            </div>

            <div className="divider">
              <div className="top-bar">
                <h3>Hire (Monthly)</h3>
                <p>Remote talent</p>
              </div>
              <div className="bottom-bar">
                <div className="scr_circle">
                  <h2>
                    <span>From</span>
                    $2850
                    <span>/Month</span>
                  </h2>
                </div>
                <h4>3 to 5 Years of Exp. Senior Full Stack Developer - 160 hours.</h4>   
                <a onClick={(e) => HandelMessageModel(e, 'Hire (Monthly)')} className="link_up">
                  get started
                </a>           
              </div>
            </div>

            <div className="divider">
              <div className="top-bar">
                
                <h3>Need more flexibility?</h3>
                <p>[ You can start as little as 1 hr per day ]</p>
              </div>
              <div className="bottom-bar">

                <div className="scr_circle">
                  <h2>
                    <span>Fixed Cost Solution</span>
                   
                  </h2>
                </div>
                
                <h4>Get a Free Estimation based on Your Custom Requirements.</h4>
                
                <a onClick={(e) => HandelMessageModel(e, 'Need more flexibility?')} className="link_up">
                  get started
                </a>
              </div>
            </div>
          </div>

            <div className="price-btn">
              <button onClick={(e) => HandelMessageModel(e, 'Need more flexibility?')} className="btn-stack">
                SCHEDULE AN INTERVIEW
              </button>
            </div>

        </div>
        
      </section>

      <section className="hire_experience">
        <div className="container">
          <div className="hire_experience_total">
            <div className="hire_experience_left"><img src={HireExperienceWomen} alt=''/></div>
            <div className="hire_experience_left">
              <h2>Hire Experienced <strong class="fullstack_bg"> Full-Stack </strong> Developers who are Skilled in</h2>
              <ul>
                <li>Angular, Vue.JS, React.JS, Next.js & Blazor & C#</li>
                <li>Node, Laravel, PHP, Python, Django</li>
                <li>Flutter, React Native, iOS, Android, Ionic</li>
                <li>MongoDB, PostgreSQL, MySQL</li>
                <li>AWS, Azure, GCP, Digital Ocean, Heroku</li>
                <li>Agile/Scrum Methodology with CI/CD, DevOps</li>
                <li>JavaScript / jQuery with HTML5 and CSS</li>
                <li>Office, Tableau, Salesforce, JIRA, Confluence ,Trello & BaseCamp</li>
              </ul>
              <button class="requerment_btn"  onClick={(e) => HandelMessageModel(e, 'Hire full stack Talent')}>Hire full stack Talent</button>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial-sec-box">
          <section className="section_three" ref={portFolio}>
                <div className="container">
                    <div className="text_head_part">
                        <h2 className="fadeInUp">Recent <span> Case Studies </span></h2>
                        <p className="fadeInUp">We’ve hand-picked some case studies we think you’ll like:</p>
                    </div>
                </div>

                {isLoader && !portData && (
                  <div className="section-loader">
                    <div className="loader--ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <p>Loading...</p>
                  </div>
                )}
                {portData ? (
                    <>
                        <div className="blue_bar">
                            <div className="container">

                                <Slider {...projectSliderNav}>

                                    {portData && portData?.length > 0 && portData?.map((items, i) => {
                                        return (
                                            <div key={i}>
                                                <span className="small_img">
                                                    <img src={items.logo} alt="" />
                                                    <h5>{items.title}</h5>
                                                </span>
                                            </div>
                                        )
                                    })}
                                </Slider>

                            </div>
                        </div>
                        <div className="container">
                            <div className="sec_inner_port_holder">
                                <Slider {...projectSlider}>
                                    {portData && portData?.length > 0 && portData?.map((items, i) => {
                                    return(
                                            <div key={i}>
                                                <div className="sec_inner_port">
                                                    <div className="left">
                                                        <ul>
                                                            <li><span>Project Name:</span> {items?.title}</li>
                                                            <li></li>
                                                            <li><span>Technology :</span> {items?.technology && JSON.parse(items?.technology)?.length > 0 && JSON.parse(items?.technology)?.reverse().map((technology, t) => {
                                                                return (<> { technology.nm} {t + 1 === JSON.parse(items?.technology)?.length ? '':'  |'}</>)
                                                            })}</li>
                                                           
                                                        </ul>
                                                        <div dangerouslySetInnerHTML={{__html: items?.description}}></div>
                                                        <Link onClick={(e) => HandelCaseStudiesModel(e, `Read more ${items?.title}`)} className="btn">Read details</Link>
                                                    </div>
                                                    <div className="right">
                                                        <div className="laps">
                                                          <img src={items?.image} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )  
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </>
                ):(!isLoader ? (
                    <div className="container">
                        <div style={{textAlign: 'center',}}>
                            <span style={{margin: '10px auto'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" width={'40'} height={'40'}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                </svg>
                            </span>

                            <p>Case Studies not found!</p>
                        </div>
                    </div>
                ):(null)
                    
                )}
                
            </section>
        
      </section>

      <div ref={testmonialsMenu}></div>

      <section className="videoslider developer-video-slider">
        <div className="trusted-section">
              <div className="trusted-logo-box">
              <h2>Our Amazing Customer <span>Success Stories</span></h2>
                <div>
                  <Slider {...videoslider}>
                    {videos.map((video, index) => (
                      <div key={index} className={'youtube-video'} onClick={() => HandelYoutubeVideo(video.id)}>
                        <LiteYouTubeEmbed
                          id={video.id}
                          title={video.title}
                        />
                        <h6><span>{video.description}</span></h6>
                      </div>
                    ))}
                  </Slider>
                </div>

        </div>
        </div>
        </section>

      <section  className="faq-sec-part">
        <div className="container" ref={ref}>
          <h3>We are proud to inspire</h3>

          <div className="testmonial-colum">
               <div className="testmonial-row">
                    <div className="testmonial-box">
                        <h5>I would thoroughly recommend</h5>
                        <p>
                          “ I would thoroughly recommend Reddensoft’s work. On my project they got the 
                          brief straight away and the combination their creative input, eye for detail, 
                          and in-depth appreciation of production processes meant that we could totally 
                          trust to deliver! ”
                        </p>

                        <div className="testmonial-details">
                          <div className="testmonial-user-image">
                            <img src={Eric} alt=''/>
                          </div>
                          <div className="testmonial-user-details">
                            <h5>Mike Boggus</h5>
                            <p>Entrepreneur</p>
                          </div>
                        </div>
                        <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                    </div>

                    <div className="testmonial-box">
                        <h5>Outstanding , Creative Development services</h5>
                        <p>
                          “I was really impressed with the level of service that I received from Reddensoft. 
                          They were always responsive to my needs, and they were always willing to go the extra mile. 
                          I would definitely recommend them to anyone looking for their outstanding, creative development 
                          services. You guys are amazing.”
                        </p>

                        <div className="testmonial-details">
                          <div className="testmonial-user-image">
                            <img src={David} alt=''/>
                          </div>
                          <div className="testmonial-user-details">
                            <h5>David Miller</h5>
                            <p>Entrepreneur</p>
                          </div>
                        </div>
                        <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                    </div>

                    <div className="testmonial-box">
                        <h5>They're incredibly results-oriented</h5>
                        <p>
                          “I've worked with 2 fullstack developer services provide in the past, Reddensoft.us is by far the best. 
                          They're incredibly results-oriented, and they're always willing to go the extra mile to help me achieve 
                          my goals. I would definitely recommend them to anyone looking for fullstack development service packages.”
                        </p>

                        <div className="testmonial-details">
                          <div className="testmonial-user-image">
                            <img src={Michel} alt=''/>
                          </div>
                          <div className="testmonial-user-details">
                            <h5>Michel Ambrosio</h5>
                            <p>Founder @Quirkel</p>
                          </div>
                        </div>
                        <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                    </div>

                    <div className="testmonial-box">
                        <h5>I'm a repeat customer and happy to be so</h5>
                        <p>
                          "Reddensoft's teams are on my short list of "go-to" guys for development related work. They are fast and efficient 
                          and communication is top-notch. I'm a repeat customer and happy to be so. ”
                        </p>

                        <div className="testmonial-details">
                          <div className="testmonial-user-image">
                            <img src={PGFavors} alt=''/>
                          </div>
                          <div className="testmonial-user-details">
                            <h5>PG Favors</h5>
                            <p>Entrepreneur</p>
                          </div>
                        </div>
                        <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                    </div>

                    <div className="testmonial-box">
                        <h5>I will be doing more work with them</h5>
                        <p>
                        "
                                I hired fullstack developers from Reddensoft to develop a website system for me and they went 
                                ABOVE & BEYOND what was asked of them. They always were very responsive on 
                                email/Skype and it was a pleasure to work with.

                                I will be doing more work with them.
                            ”
                        </p>

                        <div className="testmonial-details">
                          <div className="testmonial-user-image">
                            <img src={ADukes} alt=''/>
                          </div>
                          <div className="testmonial-user-details">
                            <h5>A Dukes</h5>
                            <p>Entrepreneur</p>
                          </div>
                        </div>
                        <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                    </div>
               </div>

               <div className="testmonial-row">
                    <div className="testmonial-box">
                        <h5>Our lead generation has increased by 200%</h5>
                        <p>
                          “We've been working with reddensoft.US full-stack developers for the past 2 years on our project development services. They've helped us to increase our social presence, website with very high quality brand presence. Our lead generation has increased by 200%. We're really happy with the results, and we would definitely recommend them to other businesses. ”
                        </p>

                        <div className="testmonial-details">
                          <div className="testmonial-user-image">
                            <img src={Kimberly} alt=''/>
                          </div>
                          <div className="testmonial-user-details">
                            <h5>Kimberly</h5>
                            <p>Entrepreneur</p>
                          </div>
                        </div>
                        <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                    </div>

                    <div className="testmonial-box">
                        <h5>I give them a 5 star rating</h5>
                        <p>
                          “
                            I wanted to leave some feedback for these guys .. Talented is an understatement .. 

                            Anytime you hire someone new , your never 100 percent confident in their abilities . But all I have to say is Wow . From the beginning they were very professional. Answered all my questions and made changes as per request with no problem.

                            The site is completely custom made , not
                            Just a paid theme...They built custom forms and header and all is responsive as well.


                            I give them a 5 star rating and will be using them on my next project which will start in the next few weeks. 

                            Thanks so much guys for all your hard work

                            ”
                        </p>

                        <div className="testmonial-details">
                          <div className="testmonial-user-image">
                            <img src={Andrew} alt=''/>
                          </div>
                          <div className="testmonial-user-details">
                            <h5>Andrew Paul</h5>
                            <p>Entrepreneur</p>
                          </div>
                        </div>
                        <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                    </div>

                    <div className="testmonial-box">
                        <h5>Dealing with Reddensoft has been awesome!</h5>
                        <p>
                          "
                            

                            My experience dealing with Reddensoft has been awesome. 
                            I gave them the details of my project and they were able to
                             make it come to life. 
                             Their developments are very clean and modern which is one of the reasons I decided to go with 
                             them. Seriously, just take a look at their portfolio! That's type of work the team will deliver. 
                            I'm really glad they were able to deliver outstanding work in a reasonable amount of time. 
                            They can truly make your 
                            vision come alive!

                            ”
                        </p>

                        <div className="testmonial-details">
                          <div className="testmonial-user-image">
                            <img src={NoImage} alt=''/>
                          </div>
                          <div className="testmonial-user-details">
                            <h5>Eric  M</h5>
                            <p>Entrepreneur</p>
                          </div>
                        </div>
                        <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                    </div>

                    <div className="testmonial-box">
                        <h5>They are fair, honest and on-time</h5>
                        <p>
                          “
                            I have used Reddensoft's services for many projects. Reddensoft has always 
                            been available to answer questions, give status updates, quotes etc. As a company they are fair, honest and on-time.
                          ”
                        </p>

                        <div className="testmonial-details">
                          <div className="testmonial-user-image">
                            <img src={Ryan} alt=''/>
                          </div>
                          <div className="testmonial-user-details">
                            <h5>Ryan</h5>
                            <p>Entrepreneur</p>
                          </div>
                        </div>
                        <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                    </div>
               </div>

               <div className="testmonial-row">
                    <div className="testmonial-box">
                        <h5>They are a great partner for our business.</h5>
                        <p>
                          “They are a great partner for our business. They're always up-to-date on the latest development trends, and they're always willing to go the extra mile to help us achieve our goals. We're confident that we're in good hands with them for any development services. Our clients are super happy. We are having a customized plan and hired few full-stack developers for our agency.  ”
                        </p>

                        <div className="testmonial-details">
                          <div className="testmonial-user-image">
                            <img src={Bently} alt=''/>
                          </div>
                          <div className="testmonial-user-details">
                            <h5>Bently Ritter</h5>
                            <p>President & CEO at BeneFit Personal Training</p>
                          </div>
                        </div>
                        <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                    </div>

                    <div className="testmonial-box">
                        <h5>Thanks for the hard work Reddensoft</h5>
                        <p>
                          “I recently hired Reddensoft's fullstack developers to add a few items to my online portfolio and they got the 
                          job done quickly as usual. Thanks for the hard work Reddensoft. ”
                        </p>

                        <div className="testmonial-details">
                          <div className="testmonial-user-image">
                            <img src={James} alt=''/>
                          </div>
                          <div className="testmonial-user-details">
                            <h5>James Vang</h5>
                            <p>Entrepreneur</p>
                          </div>
                        </div>
                        <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                    </div>

                    <div className="testmonial-box">
                        <h5>They are very easy to work with</h5>
                        <p>
                          “
                            I just thought I'd drop by and say how happy I am with my new project that I had created 
                            from these guys! They are very easy to work with 
                            and are also extremely patient. I would highly recommend using these 
                            guys for fullstack development and will use them for all my future projects!
                          ”
                        </p>

                        <div className="testmonial-details">
                          <div className="testmonial-user-image">
                            <img src={Jayson} alt=''/>
                          </div>
                          <div className="testmonial-user-details">
                            <h5>Jayson L</h5>
                            <p>Entrepreneur</p>
                          </div>
                        </div>
                        <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                    </div>

                    

                    <div className="testmonial-box">
                        <h5>I am really happy to work with you guys!</h5>
                        <p>
                        “ I am an internet marketer with over 8 years experience. I have outsourced projects to different programmers and designers 
                            but they don't always deliver. I came to know about Reddensoft from their website and decided to 
                            give them a trial for my upcoming project. I couldn't believe my eyes with what am staring 
                            at right now, they are the best company to ever handle my projects. 
                            Their fullstack developers are versatile and well experienced in their respective field. 
                            I just give them my work to do and focus on other things because they 
                            always over-deliver without your supervision. They are the best for your next project with a reasonable fee. 
                            I recommend them to anyone that need their service. 
                        

                        ”
                        </p>

                        <div className="testmonial-details">
                          <div className="testmonial-user-image">
                            <img src={Younges} alt=''/>
                          </div>
                          <div className="testmonial-user-details">
                            <h5>Younges Tceo</h5>
                            <p>Entrepreneur</p>
                          </div>
                        </div>
                        <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                    </div>
                    
                </div>
          </div>
          
     
        </div>
      </section>

      <section className="faq_sec" ref={faqMenu}>
        <div className="container">
        {/* <div ref={faqMenu} className="faq-section"></div> */}
        <div className="faq_total">
         
         <div className="faq_left">
          <h3>Frequently Asked Questions</h3>
          <h5>Still Have Questions? We’ve Got Answers! </h5>
         </div>
          
          <div className="faq_rifgt">
          <div className="acordin-sec">
            <div className="accordion">
              <div className="accordion-half">
                {accordionItems.map((item, index) => (
                  <div key={index} className="accordion-item">
                    <button
                      className={`accordion-title accordion-item-header ${
                        activeIndex === item.id ? "active" : ""
                      }`}
                      onClick={() => handleAccordionClick(item.id)}
                    >
                      {item.title}
                    </button>
                     
                      <CSSTransition in={activeIndex === item.id} >
                        {state => (
                          <div
                            style={{
                              maxHeight: state === 'entered' ? '220px' : '0',
                              overflow: 'hidden',
                              transition: 'max-height 0.2s ease-in-out',
                            }}
                          >
        
                            <div className="accordion-content" dangerouslySetInnerHTML={{__html: item.content}}></div>
                          </div>
                        )}
                      </CSSTransition>
                  </div>
                ))}

              </div>

              
            </div>
          </div>
          </div>

          </div>

        </div>
      </section> 

      <section className="contact_ups">
        <div className="container">
          <div className="contact_content">
            <div className="ttx">
              <h2>Hire developers on monthly basis and get your project done.</h2>
              <p>
                We ensure faster delivery of your product by expert developers. Starting from $2500/month only.{" "}
              </p>
              <h5>Our expert will help you out to organize your project requirement:</h5>
              <ul>
                <li>Set the minimal plan to get it done</li>
                <li>
                Ensure a faster delivery{" "}
                </li>
                
              </ul>
            </div>
            <div className="frm-box-rp">
              <div className="frm-box">
                <h3>Not sure where to start?</h3>
                <p>Let’s have a chat</p>
                <Formik
                  initialValues={{ name:"", email: "", tryNow: "Not sure where to start" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = "Email is required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }

                    if (!values.name || !_.trim(values.name)) {
                      errors.name = 'Name is required';
                      } else if (values.name.length > 50) {
                      errors.name = 'Must be 15 characters or less';
                      } else if (values.name.match("[<>]")) {
                      errors.name = 'Please provide a valid name';
                      }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setBtnLoaderTwo(true);
                    setSubmitting(false);
                    axios
                      .post(`/lets-chat`, values)
                      .then(function (response) {
                        // console.log(response);
                        navigate('/thank-you');
                        setIsThankYouTwo(true);
                      })
                      .catch(function (error) {
                        // console.log(error)
                        setIsErrorTwo(
                          error?.message ? error.message : "Network Error"
                        );
                      })
                      .finally(() => {
                        setBtnLoaderTwo(false);
                        setTimeout(myGreeting, 5000);
                        values.email = "";
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className=" "
                      id=""
                    >
                      <label htmlFor="">Your name</label>
                      <input type="text" onChange={handleChange} onBlur={handleBlur} name="name" value={removeExtraSpace(values.name)} autoCapitalize="none" autoComplete="new-password"/>
                      {errors.name && touched.name && (
                          <div
                            style={{
                              color: `rgb(255, 0, 0)`,
                              marginTop: `2px`,
                            }}
                          >
                            {errors.name}
                          </div>
                        )}

                      <label htmlFor="">Your company email</label>
                      <input type="email" onChange={handleChange} onBlur={handleBlur} name="email" value={values.email} autoCapitalize="none" autoComplete="new-password"/>
                      {errors.email && touched.email && (
                          <div
                            style={{
                              color: `rgb(255, 0, 0)`,
                              marginTop: `2px`,
                            }}
                          >
                            {errors.email}
                          </div>
                        )}

                      <button type="submit" className="link_up">
                        book a call with us 
                        {btnLoaderTwo && (
                          <span className="btn-ring-animation"></span>
                        )}
                      </button>
                    </form>
                  )}
                  </Formik>

                  {isThankYouTwo && (
                    <div className="alert alert-success mt-3">
                      Thank you for your interest.
                    </div>
                  )}
                  {isErrorTwo && (
                    <div style={{ color: `rgb(255, 0, 0)`, marginTop: `2px` }}>
                      {isErrorTwo}
                    </div>
                  )}

              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="wap">
            <div className="item">
              <a href="#" className="frt-logo">
                <img src={FooterLogo} alt="" />
              </a>
              
             
              
            </div>
            {/* <div className="item">
              
              <p><a href="mailto:info@reddensoft.com">info@reddensoft.com</a></p>
            </div> */}
            <div className="item">
              <a href="tel:+1 678 608 2725" className="call-btn">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
                    <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"/>
                  </svg>
                </span>
                <span className="number-box">
                  (678) 608-2725
                </span>
              </a>
            </div>

            <div className="item">
              <p>
                <a onClick={() => HandelGuidelineModal()}>
                  Privacy Policy
                </a>
              </p>
            </div>

          </div>
        </div>
        <div className="copy-riht">
          <div className="container">
            <p>ReddenSoft Copyright 2023. All rights reserved.</p>
            {/* <ul>
              
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              
            </ul> */}
          </div>
        </div>
      </footer>

      <Transition
        show={messageModel}
        enter="ease-out duration-300"
        enterFrom="opacity-0 relative-modal"
        enterTo="opacity-100 relative-modal"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 relative-modal"
        leaveTo="opacity-0 relative-modal"
      >
        <ScheduleConsultation
          isSchedule={false}
          isFree={isStartFreeTrial}
          isNewTry={isTry}
          toggleModal={toggleModal}
        />
      </Transition>

      <Transition
        show={isCaseStudies}
        enter="ease-out duration-300"
        enterFrom="opacity-0 relative-modal"
        enterTo="opacity-100 relative-modal"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 relative-modal"
        leaveTo="opacity-0 relative-modal"
      >
        <CaseStudiesModal toggleModal={toggleModal} />
      </Transition>

      <Transition
        show={scheduleModel}
        enter="ease-out duration-300"
        enterFrom="opacity-0 relative-modal"
        enterTo="opacity-100 relative-modal"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 relative-modal"
        leaveTo="opacity-0 relative-modal"
      >
        <ScheduleConsultation isSchedule={true}  toggleModal={toggleModal} />
      </Transition>

      <Transition 
            show={guidelineModal}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal">
                <div className='Guideline-modal'>

                    <div className='Guideline-modal-box' ref={Modalref}>
                      <button className='inline-btn' onClick={() => HandelGuidelineModal()} type='button'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20px"  height="20px">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>

                      <div className="Guideline-modal-inside">
                        <h2>Privacy Policy</h2>

                        <p>
                        This privacy policy of ReddenSoft declares the process and purpose of collecting/ storing/ sharing your data while you are using our services.
                        </p>

                        <p>
                        We recommend you read this entire privacy policy thoroughly to get a clear picture of your rights and choices. Using our services means you are agreeing with the privacy policy we have.
                        </p>

                        <h4>
                          Information that we collect:-
                        </h4>

                        <h6>
                            Personal information
                        </h6>

                        <p>
                        We collect the basic information from you like your name, phone number, email id, and website/ name of the organization while you schedule a meeting with us or ask for a partnership program. The purpose is to further communicate with you and get your job done.
                        </p>

                        <h6>
                           Non-personal information
                        </h6>
                        <p>
                        Collecting non-personal identification information about Users happens when they interact with our Site. It may include the type of computer, browser name, and technical information about the user while accessing our site (i.e., the operating system and the Internet service providers used) and other similar information.
                        </p>

                        <h6>
                          Use of collected information:-
                        </h6>

                        <ul className='list-items'>
                            <li>For the betterment of customer service</li>
                            <li>Providing you with the best-personalized user experience</li>
                            <li>For the Site improvement </li>
                            <li>For Payment Processing</li>
                            <li>For the improvement of promotion/ contest/ survey / other features of the Site </li>
                            <li>For sending periodic emails</li>
                        </ul>

                        <h6>
                        Media
                        </h6>

                        <p>
                        We recommend not sharing inappropriate or sensitive images or documents on the website. On the contrary, using images or documents from the website is strictly prohibited and it could be penalized under copyright law.
                        </p>

                        <h6>
                          Embedded content from other websites
                        </h6>

                        <p>
                          Articles on this site may include embedded content (e.g., videos, images, articles, etc.) from other third-party websites. Embedded content from other websites behaves exactly the same way it appeared on the original website.
                        </p>
                        <p>
                          Those third-party we bsites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content (including tracking your interaction with the embedded content) if you are already logged into those websites.
                        </p>

                        <h6>
                        Cookies policy
                        </h6>

                        <p>
                          Our website may use "cookies" to enhance the visitor’s experience. Cookies are being placed by the web browser on their hard drive for record-keeping purposes and sometimes to track information about them. Users may opt to set their web browser to refuse cookies or give them an alert when cookies are being sent. If they do so, then some parts of the website may not function properly.
                        </p>
                        <p>
                          Visitors of our website can subscribe to different services like subscribing to blogs, starting a project with us or joining the partnership program, and for that, we need your name, email address, and website information to store in cookies. These are for your convenience so that you do not have to fill in your details again when you go for other services on the website. These cookies will last for one year.
                        </p>

                        <h6>
                        Information Sharing
                        </h6>
                        <p>
We strictly oppose sharing information with any third party. Information shared with us will only be used for the betterment of your project and it will be kept only with us. We also request you to not disclose any confidential statement about the ongoing project with any other website or third-party domain.
                        </p>
                      </div>
                    </div>
                </div>  
      </Transition>

      <Transition 
          show={openVideoModal}
          enter="ease-out duration-300"
          enterFrom="opacity-0 relative-modal"
          enterTo="opacity-100 relative-modal"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 relative-modal"
          leaveTo="opacity-0 relative-modal">

        <div className="videoModal">
          
          <div className="videoModalInside">
            <button className='inline-btn' onClick={() => setVideoModal(!openVideoModal)} type='button'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20px"  height="20px">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            {/* <LiteYouTubeEmbed
              id={currentvideoId}
              autoplay={true}
              title={"I have been working on a 'pet' project for quite some time now."}
            /> */}

            <iframe style={{
             position: "absolute",
             top: 0,
             left: 0,
             width: "100%",
             height: "400px"
             }} src={`https://www.youtube.com/embed/${currentvideoId}?autoplay=1&mute=1`}
              frameBorder='0'
              allow='autoplay; encrypted-media'
              allowFullScreen
              title='video'
            />
          </div>
        </div>
      </Transition>
    </section>
  );
}


