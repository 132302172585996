import React, { useRef, useState } from 'react';
import {  David, HeaderLogo, MailIcon, Michel, Bently, Kimberly,  curcelArrow, DesignPortfolioOne, DesignPortfolioTwo, DesignPortfolioThree,Mostpopular,} from '../../Assets/index';
import { useEffect } from 'react';
import Slider from 'react-slick';
import { Transition } from '@headlessui/react';

import {FaFacebookF, FaTwitter, FaLinkedinIn} from 'react-icons/fa';
import ScheduleConsultation from '../ScheduleConsultation';
import WebFont from 'webfontloader';

import '../Package/App.css'


import { Helmet } from 'react-helmet';

function Package() {
   

    // const [timerDays, setTimerDays] = useState("0");
    const [timerHours, setTimerHours] = useState("0");
    const [timerMinutes, setTimerMinutes] = useState("0");
    const [timerSeconds, setTimerSeconds] = useState("0");

    let interval = useRef();

    const startTimer = (countdownDate) => {
        const now = new Date().getTime();
        const distance = countdownDate - now;

        // const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            clearInterval(interval.current);
        } else {
        // setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
        }
    };


    useEffect(() => {
        WebFont.load({
            google: {
            families: ['Roboto Slab:200,300,400', 'Assistant:200,300,400,500,600,700,800', 'Patua One'] // Fonts you want to load
            },
            custom: {
            families: ['Patua One'], // Optional: If you want to load a custom font, specify its name here
            urls: ['https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400&display=swap',
                    'https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&family=Patua+One&display=swap'] // URLs of the font CSS files
            }
        });

        //Check Cookies
        const newCookies =()=>{
            const currentTime = new Date();
            
            const nextDay = new Date();
            nextDay.setDate(currentTime.getDate() + 1);

            currentTime.setHours(currentTime.getHours() + 3);
            currentTime.setMinutes(currentTime.getMinutes() + 20);
            
            countdownDate = currentTime.getTime();
            document.cookie = `offerTime=${countdownDate}; expires=${nextDay}; path=/`;
        };
        const cookies = document.cookie.split("; ");
        let countdownDate;
        if (cookies.length > 0) {
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i].split("=");                
                if (cookie[0] === "offerTime") {
                    const cookieValue = cookie[1];
                    const currentTime = new Date(cookieValue).toLocaleString();
                    console.log(cookie[1], typeof(currentTime));
                    countdownDate = cookieValue;
                    break;
                } else {
                    newCookies();
                }
            }
        } else {
            newCookies()
        }
        interval.current = setInterval(() => {
            startTimer(+countdownDate);
        }, 1000);

        return () => clearInterval(interval.current);
    }, []);


    var portfolioSlider = {
        infinite:true, 
        speed:500,
        dots: true,
        autoplay: true,
        slidesToShow:1, 
        slidesToScroll:1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };


    var _ = require('lodash');

    const [scheduleModel, setScheduleModel] = useState(false);
    

    const [messageModel, setMessageModel] = useState(false);
    const HandelMessageModel = () => {
        setMessageModel(true)
    };

    const toggleModal = () => {
        setScheduleModel(false);
        setMessageModel(false)
    };

   


  return (
    <section className='sales-page'>
        <Helmet>
                   
            <script id="facebook-pixel-script">
                {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js'),
                fbq('init', '273096641131718'),
                fbq('track', 'PageView')
                `}
            </script>
            <noscript id="facebook-pixel-image">
                {`
                    <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=273096641131718&ev=PageView&noscript=1"
                />
                `}
                
            </noscript>
        </Helmet>
        <section className='container'>

            <div className='header-part'>
                <a className='logo' href='/'>
                    <img src={HeaderLogo} alt='Reddensoft logo'/>
                </a>

                <div className='header_right'>
                <a className='mail-box' href='mailto:info@reddensoft.com'>
                    <span>
                        <img src={MailIcon} alt='Reddensoft email'/>
                    </span>
                    <span>
                        info@reddensoft.com
                    </span>
                    
                </a>
                <a onClick={(e) => HandelMessageModel(e)} className='header-gradeint-btn'>
                    
                    <span>
                        Reach Us
                    </span>
                    
                </a>

                
                </div>
            </div>

        </section>

        <section className='banner-bg'>
            <h1>Unleash Your Brand's Visual Potential with <br></br> Stunning Graphic Designs</h1>
            <p>Access any of our graphic design plans and enjoy unlimited design solutions.<br></br>
            Get complete <strong>high-quality graphic designs</strong> along with revisions as much as you want.</p>
         </section>

         <section className='container'>
          <div className='popular-pckg'>
             <div className='mst'><img src={Mostpopular} alt=""></img></div>
             <div className='packages'>
                <div className='package-box'>
                   <h2>Basic <span>Graphics</span></h2> 
                   <p>Unlock Limitless Creativity with<br></br> our Basic Pricing Package</p>
                   <div className='price-text'><del>$899 </del>$499 <span>/month</span></div>
                   <div className="elementor-divider"><span className="elementor-divider-separator"></span></div>
                   <div className='package-list'>
                     <h3>Services</h3>
                     <ul>
                        <li>Logo design</li>
                        <li>UI/UX design</li>
                        <li>Custom illustration</li>
                        <li>Presentation</li>
                     </ul>
                      <ul className='deactive-list'>

                        <li> <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clip-rule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fill-rule="evenodd"></path></svg> Web design</li>

                         <li> <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clip-rule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fill-rule="evenodd"></path></svg>Packaging Design</li>
                         <li> <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clip-rule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fill-rule="evenodd"></path></svg>Publication and Typographic Design</li>
                     </ul>
                     
                     <h3>Features</h3>
                     <ul>
                     <li>1-2 day turnaround*</li>
                     <li>Platform independent design</li>
                     <li>Designated designers</li>
                     <li>Onboarding Manager</li>
                     </ul>
                        <ul className='deactive-list'>
                        
                        <li> <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clip-rule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fill-rule="evenodd"></path></svg> Same-day turnaround</li>
                        <li> <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clip-rule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fill-rule="evenodd"></path></svg>Real-time  collaboration</li>
                       
                        {/* <li>Motion Graphics Revision Tool</li>  */}
                        <li> <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clip-rule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fill-rule="evenodd"></path></svg> Get source file</li>
                        <li> <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clip-rule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fill-rule="evenodd"></path></svg> Unlimited revisions</li>
                     </ul>
                   </div>

                   <a href='#' className='talk-btn'>Talk To Us</a>
                </div>
                <div className='package-box'>
                <div className="ribbon ribbon-top-left"><span>70%  purchased</span></div>
                   <h2>Advanced  <span> Graphics</span></h2> 
                   <p>Elevate your brand with <br></br>our advanced pricing package</p>
                   <div className='price-text'><del>$1199</del>$699 <span>/month</span></div>
                   <div className="elementor-divider"><span className="elementor-divider-separator"></span></div>
                   <div className='package-list'>
                     <h3>Services</h3>
                     <ul>
                     <li>Logo design</li>
                        <li> Web design</li>
                        <li>UI/UX design</li>
                         <li>Custom illustration</li>
                         <li>Presentation</li>
                        </ul>
                        <ul className='deactive-list'>
                         <li> <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clip-rule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fill-rule="evenodd"></path></svg> Packaging Design</li>
                         <li> <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clip-rule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fill-rule="evenodd"></path></svg> Publication and Typographic Design</li>
                     </ul>
                     
                     <h3>Features</h3>
                     <ul>
                       <li> Same-day turnaround</li>
                        <li>1-2 day turnaround*</li>
                        <li>Platform independent design</li>
                        <li>Designated designers</li>
                        <li>Onboarding Manager</li>
                        <li>Get source file</li>
                        <li>Unlimited revisions</li>
                        <li>Real-time  collaboration</li>
                       </ul>
                       
                   </div>

                   <a href='#' className='talk-btn'>Talk To Us</a>
                </div>
                <div className='package-box'>
                   <h2>Premium  <span> Graphics</span></h2> 
                   <p>Unleash the full potential<br></br> through our premium package</p>
                   <div className='price-text'> <del>$1599</del> $999 <span>/month</span></div>
                   <div className="elementor-divider"><span className="elementor-divider-separator"></span></div>
                   <div className='package-list'>
                     <h3>Services</h3>
                     <ul>
                     <li>Logo design</li>
                        <li> Web design</li>
                        <li>UI/UX design</li>
                         <li>Custom illustration</li>
                         <li>Presentation</li>
                      
                         <li>Packaging Design</li>
                         <li>Publication and Typographic Design</li>
                     </ul>
                     
                     <h3>Features</h3>
                     <ul>
                     <li> Same-day turnaround</li>
                        <li>1-2 day turnaround*</li>
                        <li>Platform independent design</li>
                        <li>Designated designers</li>
                        <li>Real-time  collaboration</li>
                        <li>Onboarding Manager</li>
                         <li>Get source file</li>
                        <li>Unlimited revisions</li>
                     </ul>
                   </div>

                   <a href='#' className='talk-btn'>Talk To Us</a>
                </div>
             </div>
         </div>
         </section>

   

        <section className='container'>


            <div className='second-section extra-space'>
                <div className='curcel-Arrow'>
                    <img src={curcelArrow} alt=''/>
                </div>
                <div className='ribbon-section'>
                    <p>Limited Period </p>
                    <h3>Offer </h3>
                </div>

                <div className='gradeint-box'>
                    <h2>
                        Reddensoft is <br/>offering  <span>great deal</span><br/> for the clients.
                    </h2>

                    <p>
                        If you have any web development <b>project</b> in mind, work with our professionals
                    </p>
                </div>

                <div className='offer-box'>
                    <h5>Hire a team or individual and</h5>
                    <h4>Get</h4>
                    <h3>30%<span>off</span></h3>
                    <h6>on the total cost</h6>
                    <button onClick={(e) => HandelMessageModel(e)} className='btn-outline btn-outline-blue'>I want to Start Now</button>

                    <div className='time-count-box'>
                        <p>OFFER ENDING SOON</p>
                        <div className='time-count'>
                              <h3>
                                  <span>
                                      <span>{timerHours < 10 ? '0' : ''}{timerHours}</span>
                                      <span className='text'>Hour</span>
                                  </span> :
                                  <span>
                                      <span>{timerMinutes < 10 ? '0' : ''}{timerMinutes}</span>
                                      <span className='text'>Minutes</span>
                                  </span> :
                                  <span>
                                     <span> {timerSeconds < 10 ? '0' : ''}{timerSeconds}</span>
                                      <span className='text'>Seconds</span>
                                  </span>
                              </h3>
                        </div>
                    </div>
                    
                </div>
            </div>

           </section>

           <section className='slider-sec-total'>
             <div className='container'>
             <div className='slider-section'>
                <h3>Recent Projects</h3>

                <div className='slider-image'>
                    <Slider {...portfolioSlider}>
                        <img src={DesignPortfolioOne} alt=''/>
                        <img src={DesignPortfolioTwo} alt=''/>
                        <img src={DesignPortfolioThree} alt=''/>
                        
                       
                    </Slider>
                </div>
            </div>

             </div>
           </section>


   

            <section className='testmonial-section'>
            <div className='container'>
                <div className='testmonial-top'>
                    <p>Our Testimonials</p>
                    <h4>We are <span className='text-grad'>
                    <span> proud to inspire </span>
                    </span></h4>
                </div>

                <div className='testmonial-grid'>
                    <div className='testmonial-box'>
                        <div className='testmonial-user'>
                            <div className='testmonial-user-image'>
                                <img src={David} alt='David Miller'/>
                            </div>

                            <div className='testmonial-user-details'>
                                <div>
                                    <h4>David Miller</h4>
                                    <h6>Business Man</h6>
                                </div>
                                {/* <a href='' className='twitter-icon' target='_blank'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                    </svg>
                                </a> */}
                            </div>
                        </div>

                        <p className='des'>
                        “I'm David and I've been in the online business for a very long time and I tried lots of different suppliers around the world and done the reefs I'm speaking today is that I just wanted to give a shout out to a team I've been using who I like very much they call ReddenSoft. They are great on app development. They're very good on website development.”
                        </p>
                    </div>

                    <div className='testmonial-box'>
                        <div className='testmonial-user'>
                            <div className='testmonial-user-image'>
                                <img src={Michel} alt=''/>
                            </div>

                            <div className='testmonial-user-details'>
                                <div>
                                    <h4>Michel Ambrosio</h4>
                                    <h6>Founder @Corkle</h6>
                                </div>
                                {/* <a href='' className='twitter-icon' target='_blank'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                    </svg>
                                </a> */}
                            </div>
                        </div>

                        <p className='des'>
                        “Hi, my name is Michel Ambrosio. I'm the owner of a site called quirkel.com is a service that you use to back up your websites and databases. Now the reason I'm telling you this because I'm really looking to tell you about a company called ReddenSoft. ReddenSoft is the development and programming company that I used corkle built.”
                        </p>
                    </div>
                    <div className='testmonial-box'>
                        <div className='testmonial-user'>
                            <div className='testmonial-user-image'>
                                <img src={Bently} alt=''/>
                            </div>

                            <div className='testmonial-user-details'>
                                <div>
                                    <h4>Bently Ritter</h4>
                                    <h6>President & CEO at BeneFit Personal Training</h6>
                                </div>
                                {/* <a href='' className='twitter-icon' target='_blank'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                    </svg>
                                </a> */}
                            </div>
                        </div>

                        <p className='des'>
                        “Hi, my name is Bently Ritter. I have a company called Benefit Personal Training and we have been working with Dipak Tewary and his team at ReddenSoft for several years. They helped us develop our website benefitpersonaltraining.com as well as multiple promotional pieces. Everything they do looks great. It's done in a very timely manner and his prices are fair. So, I would absolutely recommend and his team at ReddenSoft for all of your company needs.”
                        </p>
                    </div>
                    <div className='testmonial-box'>
                        <div className='testmonial-user'>
                            <div className='testmonial-user-image'>
                                <img src={Kimberly} alt=''/>
                            </div>

                            <div className='testmonial-user-details'>
                                <div>
                                    <h4>Kimberly</h4>
                                    <h6>Entrepreneur</h6>
                                </div>
                                {/* <a href='' className='twitter-icon' target='_blank'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                    </svg>
                                </a> */}
                            </div>
                        </div>

                        <p className='des'>
                        “Hi, my name is Kimberly and as a business owner it is most important to obviously have a user-friendly website that has great design some of the most modern and then it's also important to be able to drive traffic to that website. I can tell you that I've worked with Reddensoft for over 10 years now. The whole team there including their leader Dipak Tewary have successfully managed to design seven wonderful websites for me.”
                        </p>
                    </div>
                
                </div>



            </div>
            </section>

        <section className="sales-footer-section">
            <div className='container'>
                <ul className='social-media'>
                    <li>
                        <a rel="noreferrer" href="https://www.facebook.com/Reddensoft/" target="_blank" className="fadeInUp animated">
                            <FaFacebookF size={30}/>
                        </a> 
                    </li>
                    <li>
                        <a rel="noreferrer" href="https://twitter.com/reddensoft" target="_blank" className="fadeInUp animated">
                            <FaTwitter size={30}/>
                        </a> 
                    </li>
                    <li>
                        <a rel="noreferrer" href="https://www.linkedin.com/company/reddensoft" target="_blank">
                            <FaLinkedinIn size={30}/>
                        </a> 
                    </li>
                    
                </ul>
                <div className='footer-logo'>
                    <img src={HeaderLogo} alt='reddensoft logo' />
                </div>
                <p>
                    Copyright @ 2023 reddensoft. <br/>
                    All Rights Reserved.
                </p>
            </div>
        </section>

        <Transition 
            show={messageModel}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal">
            <ScheduleConsultation isSchedule={false} toggleModal={toggleModal}/>
        
        </Transition>
       
         <Transition 
            show={scheduleModel}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal">
            <ScheduleConsultation isSchedule={true} toggleModal={toggleModal}/>
        
        </Transition>
    </section>
  );
}

export default Package;
