import React , { useEffect, useState }from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import {BsArrowRight, BsCameraVideo} from 'react-icons/bs';
import {SiSkypeforbusiness} from 'react-icons/si';
import {SiWhatsapp} from 'react-icons/si';
import { SCHEDULECONSICON, SCHEDULECONSICONM } from '../Assets/index';
import { useFormik } from 'formik';
import validator from 'validator';
import './Model.scss';
import axios from 'axios';
import QuickContactModal from './QuickContactModal';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';

export default function ScheduleConsultation({toggleModal, isSchedule, isNewTry = false, isFree, isIndia = false}) {
  var _ = require('lodash');
  const [btnLoader, setBtnLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [stepCount, setStepCount] = useState(1);
  const [isStapeTwo, setIsStepTwo] = useState(false);
  const [error, setError] = useState({});

  const removeExtraSpace = (s) => s.replace(/\s{2,}/g, ' ');

  function HandelCloseWindow(e) {
    toggleModal(e);
    setStepCount(1);
  };

  

  let location = window.location.href;

  const scheduleData = {
    name: "",
    email: "",
    phone: "",
    website: "",
    dt: "",
    hr: "",
    contact_medium: "",
    pageName: location,
  };


  const validate = values => {
    const errors = {};
  
    if (!values.name || !_.trim(values.name)) {
      errors.name = 'Name is required';
    } else if (values.name.length > 50) {
      errors.name = 'Must be 15 characters or less';
    } else if (values.name.match("[<>]")) {
      errors.name = 'Please provide a valid name';
    }
  
    if (!values.email ) {
      errors.email = 'Email is required';
    } else if ( !validator.isEmail(values.email)) {
      errors.email = 'Invalid email address';
    } 

    if (!values.phone) {
      errors.phone = 'Phone number is required';
     } else if ( values.phone.length < 11) {
        errors.phone = 'Invalid phone number';
      }
    

    if (values.website) {
      if (!validator.isURL(values.website)) {
        errors.website = 'Invalid website address';
      } else if (values.website.match("[<>]")) {
        errors.website = 'Please provide a valid website';
      }
    }

    if (!values.hr) {
      errors.hr = 'Please select time';
    } 

    if (!values.dt){
      errors.dt = 'Please select a date';
    }

    if (!values.contact_medium) {
      errors.contact_medium = 'Please select a option';
    }
    
    return errors;
  };

  function myGreeting() {
    setIsError(false);
  }

  const navigate = useNavigate();

  const scheduleFormik = useFormik({
    initialValues: scheduleData,
    validate,
    onSubmit: (values, {resetForm}) => {
      setBtnLoader(true)
      const formDate = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        website: values.website,
        dt: values.dt,
        hr: values.hr,
        contact_medium: values.contact_medium,
        pageName: location
      } 
      axios.post(`/scheduleConsultations1`, formDate)
      .then(function (response) {
        if(response.status === 200){
          setIsStepTwo(false);
          navigate('/thank-you');
          setStepCount(1);
          resetForm({values: ""});
        }
      })
      .catch(function (error) {
        setIsError(error?.message ? error?.message:'Network Error')
        console.log('catch')
      })
      .finally(() => {
        console.log('Experiment completed');
        setBtnLoader(false);
        setTimeout(myGreeting, 10000);
      });
    },
  });
  

  useEffect(() => {

    if(Object.keys(error).length === 0 && isStapeTwo && (!scheduleFormik.errors.name && !scheduleFormik.errors.email && !scheduleFormik.errors.website && !scheduleFormik.errors.phone)){
      setStepCount(2)
    } else{
      setIsStepTwo(false);
    }
    
  },[isStapeTwo, error, scheduleFormik.errors])
  

  const HandelNextStep = () => {

    const firstStepValidation = validate(scheduleFormik.values);
    scheduleFormik.setErrors(firstStepValidation);
    // console.log(scheduleFormik);


    if(!scheduleFormik.values.name || !scheduleFormik.values.email || !scheduleFormik.values.phone) {
      setIsStepTwo(false);
    } else{
      setIsStepTwo(true);
    }
  };

 

  return (
    <div className="schedule_consultation">
        <div className="modal">
        <div className='overlay'></div>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={(e) => toggleModal(e)} >
                <AiOutlineClose fontSize={19} />
              </button>
            </div>
            <div className="modal-body">
              <div className="schedule_consultation-content">
              {isSchedule ? (
                <div className="sidepanel">
                  <h6>Schedule a</h6>
                  <h2>30 minutes</h2>
                  <h3>free consultation</h3>
                  <div className="schedule_consultation_icon_wrapper">
                    <div className="round-shape pulse" />
                    <img className="schedule_consultation_icon" src={SCHEDULECONSICON} alt=""/>
                  </div>
                </div>
              ):(
                <div className="sidepanel">
                  <h2> {isFree ? 'Start Your':'Send Us'} </h2>
                  <h3> {isFree ? 'Free-Trial Now':'Your Requirement'} </h3>
                  <div className="schedule_consultation_icon_wrapper">
                    <div className="round-shape pulse" />
                    <img className="schedule_consultation_icon" src={SCHEDULECONSICONM} alt=""/>
                  </div>
                </div>
              )}
                <div className="rightpanel">
                {isSchedule ? (<h5>Start Here</h5>):(
                  <h5>Please fill up the form below</h5>
                )}
                  
                  <div className="wizard">                   
                      {isSchedule ? (
                        <div className=''>
                          <div className="wizard-inner">
                            <div className="connecting-line"></div>
                            <ul className="nav nav-tabs" role="tablist">

                              <li role="presentation" id="present1" className="active">
                                <a href="#details" aria-controls="details" role="tab" data-toggle="tab">
                                  <span className="round-tab" /> <i>Enter details</i>
                                </a>
                              </li>

                              <li role="presentation" id="present2" className={ stepCount > 1 ? ` active`:``}>
                                <a href="#date-time" aria-controls="date-time" role="tab" data-toggle="tab">
                                  <span className="round-tab" />
                                  <i>Pick a date &amp; time of your choice</i>
                                </a>
                              </li>

                              <li role="presentation" id="present3" className={ stepCount > 2 ? ` active`:``}>
                                <a href="#complete" aria-controls="complete" role="tab" data-toggle="tab">
                                  <span className="round-tab" /> <i>You are done</i>
                                </a>
                              </li>
                            </ul>
                          </div>

                          <div className="schedule_consultation-box">                       
                              <form onSubmit={scheduleFormik.handleSubmit} name="scheduleform" method="post">

                                {stepCount === 1 && (
                                
                                  <div className="details">
                                    <div className="row">
                                      
                                      <div className="right">
                                        <div className="row">
                                          <div className="left-sec">
                                            <div className="form-group">
                                              <label>Name <span style={{ color: '#ff0000'}}>*</span></label>
                                              <input type="text" name="name" 
                                              // onChange={(scheduleFormik.handleChange)} 
                                              onChange={ scheduleFormik.handleChange}
                                              autoCapitalize="none" autoComplete="new-password"
                                              value={removeExtraSpace(scheduleFormik.values.name)} onBlur={scheduleFormik.handleBlur} placeholder="Enter your name"/>
                                              {scheduleFormik.touched.name || scheduleFormik.errors.name ? (<div style={{color: '#ff0000'}}>{scheduleFormik.errors.name}</div>) : null}
                                            </div>
                                          </div>
                                          <div className="right-sec">
                                            <div className="form-group">
                                              <label>Phone <span style={{ color: '#ff0000'}}>*</span></label>
                                              <PhoneInput
                                                placeholder="Enter phone number"
                                                international
                                                defaultCountry={isIndia ? "IN":"US"}
                                                autoCapitalize="none" autoComplete="new-password"
                                                countryCallingCodeEditable={false}
                                                value={scheduleFormik.values.phone}
                                                onChange={(val) =>  scheduleFormik.setFieldValue('phone', val)}/>

                                              {/* <input type="tel" name="phone" onChange={scheduleFormik.handleChange}
                                              value={removeExtraSpace(scheduleFormik.values.phone)} onBlur={scheduleFormik.handleBlur} placeholder={'1234567890'}/> */}


                                              {scheduleFormik.touched.phone || scheduleFormik.errors.phone ? <div style={{color: '#ff0000'}}>{scheduleFormik.errors.phone}</div> : null}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="left-sec">
                                            <div className="form-group">
                                              <label>Email <span style={{ color: '#ff0000'}}>*</span></label>
                                              <input type="email" name="email" onChange={scheduleFormik.handleChange} autoCapitalize="none" autoComplete="new-password" 
                                                value={removeExtraSpace(scheduleFormik.values.email)} onBlur={scheduleFormik.handleBlur} placeholder="emailid@example.com" />
                                              {scheduleFormik.touched.email || scheduleFormik.errors.email ? <div style={{color: '#ff0000'}}>{scheduleFormik.errors.email}</div> : null}
                                            </div>
                                          </div>
                                          <div className="right-sec">
                                            <div className="form-group">
                                              <label>Website (If any)</label>
                                              <input type="text" name="website" onChange={scheduleFormik.handleChange}
                                                value={removeExtraSpace(scheduleFormik.values.website)} onBlur={scheduleFormik.handleBlur} autoCapitalize="none" autoComplete="new-password" placeholder="www.website.com" />
                                              {scheduleFormik.touched.website && scheduleFormik.errors.website ? <div style={{color: '#ff0000'}}>{scheduleFormik.errors.website}</div> : 
                                                error?.website ? (<div style={{color: '#ff0000'}}>{error.website}</div>):null}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="buttom-sec">
                                            <div className="pull-right">
                                              <button type="button" className="default-btn next-step" onClick={() => HandelNextStep()}>Continue
                                                <BsArrowRight />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
        

                                {stepCount === 2 && (
                                  <div className="date-time details ">
                                    <div className="row">
                                      
                                      <div className="right responsive-padding">
                                        <div className="row">
                                          <div className="right-sec">
                                            <div className="form-group">
                                              
                                              <label>Choose Date <span style={{ color: '#ff0000'}}>*</span></label>
                                              <div className="row">
                                                <div className="left-select">
                                              
                                                  <ReactDatePicker
                                                    selected={scheduleFormik.values.dt} 
                                                    id={'date'}
                                                    showIcon={true}
                                                    onChange={(date) => scheduleFormik.setFieldValue('dt', date)}
                                                    // onChange={(date) => {setStartDate(date); setScheduleData({...startDate, dt:date})}}
                                                    minDate={new Date().setDate(new Date().getDate() + 1)}
                                                    maxDate={new Date().setMonth(new Date().getMonth() + 3)}
                                                    dateFormat="MMMM d, yyyy"
                                                    showDisabledMonthNavigation
                                                    placeholderText="Click to select a date"
                                                  />
                                                  <label htmlFor='date' className='date-icon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={20} height={20}>
                                                      <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                                    </svg>
                                                  </label>
                                                  
                                                  {scheduleFormik.touched.dt && scheduleFormik.errors.dt ? <div style={{color: '#ff0000'}}>{scheduleFormik.errors.dt}</div> : null}
                                                  </div>
                                                  </div>
                                            </div>
                                            
                                          </div>
                                          <div className="right-sec">
                                            <div className="form-group">
                                              <label>Select Preferred Time <span style={{ color: '#ff0000'}}>*</span></label>
                                              <div className="row">
                                                <div className="left-select">
                                                  
                                                  <input className="hlfinput" name="hr" type="time" autoCapitalize="none" autoComplete="new-password" onChange={scheduleFormik.handleChange}/>

                                                  {scheduleFormik.touched.hr && scheduleFormik.errors.hr ? <div style={{color: '#ff0000'}}>{scheduleFormik.errors.hr}</div> : null}
                                                
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="buttom-sec">
                                            <div className="form-group">
                                              <label>I want to talk through <span style={{ color: '#ff0000'}}>*</span></label>
                                              <ul>
                                                <li>
                                                  <label htmlFor='skype'>
                                                    <input type="radio" id='skype' autoCapitalize="none" autoComplete="new-password" name="contact_medium" onBlur={scheduleFormik.handleBlur} onChange={(e) => scheduleFormik.setFieldValue('contact_medium', e.target.checked ? 'skype':null)} value={'skype'} />
                                                    <span>
                                                      <SiSkypeforbusiness fontSize={20} /> Skype
                                                    </span>
                                                  </label>
                                                </li>
                                                <li>
                                                  <label htmlFor='whatsapp'>
                                                    <input type="radio" id='whatsapp' autoCapitalize="none" autoComplete="new-password" name="contact_medium" onBlur={scheduleFormik.handleBlur} onChange={(e) => scheduleFormik.setFieldValue('contact_medium', e.target.checked ? 'whatsapp':null)} value={'whatsapp'}/>
                                                    <span>
                                                      <SiWhatsapp fontSize={20} /> WhatsApp
                                                    </span>
                                                  </label>
                                                </li>
                                                <li>
                                                  <label htmlFor='zoom'>
                                                    <input type="radio" id='zoom' autoCapitalize="none" autoComplete="new-password" name="contact_medium" onBlur={scheduleFormik.handleBlur} onChange={(e) => scheduleFormik.setFieldValue('contact_medium', e.target.checked ? 'zoom':null)} value={'zoom'}/>
                                                    <span>
                                                      <BsCameraVideo fontSize={20}/>Zoom
                                                    </span>
                                                  </label>
                                                </li>
                                              </ul>
                                              {scheduleFormik.touched.contact_medium  && scheduleFormik.errors.contact_medium ? <div style={{color: '#ff0000'}}>{scheduleFormik.errors.contact_medium}</div> : null}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="buttom-sec">
                                            <div className="pull-right">
                                              <button type="submit" disabled={btnLoader} className="default-btn next-step">Book my time
                                                {btnLoader ? (
                                                    <span className='btn-ring-animation'></span>
                                                ):(
                                                    <BsArrowRight />
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                          
                                        </div>
                                        {isError && (
                                              <div className='alert alert-error mt-3'>
                                                  {isError} 
                                              </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                )}
        

                                {stepCount === 3 && (
                                  <div className="complete">
                                    <div className="row">
                                     
                                      <div className="right">
                                        <h2>Thank you for your interest</h2>
                                        <h3>Your booking details will be sent to you via email. Please stay in touch.</h3>
                                        <ul>
                                          <li>
                                            <button type='button' className="default-btn orange" onClick={(e) => HandelCloseWindow(e)}>Close this window</button>
                                          </li>
                                          {/* <li>
                                            <Link to={`/portfolio`} className="default-btn gray">View Portfolio</Link>
                                          </li> */}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              </form>
                          </div>
                        </div>
                      ):(
                        <QuickContactModal inIn={isIndia} isTryBtn={isNewTry}/>
                      )}
                  </div>
                  {isSchedule && (
                  <div className="info">
                    <p>No obligation, cancel anytime.</p>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>    
    </div>

  )
}
