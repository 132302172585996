import React , { useState }from 'react';
import {BsArrowRight} from 'react-icons/bs';
import { useFormik } from 'formik';
import validator from 'validator';
import './Model.scss';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';

export default function QuickContactModal({isTryBtn, inIn}) {
    var _ = require('lodash');
    const [btnLoader, setBtnLoader] = useState(false);
    const [isThankYou, setIsThankYou] = useState(false);
    const [isError, setIsError] = useState(false);

    const navigate = useNavigate();

    const removeExtraSpace = (s) => {
       var rSpase = s.replace(/\s{2,}/g, ' ');
       return _.trimStart(rSpase);
    };

    let location = window.location.href;

    const quickContact  = {
        name: "",
        email: "",
        phone: "",
        website: "",
        message: "",
        pageName: location,
        tryNow: isTryBtn ? isTryBtn:'',
    };

    const validate = values => {
        const errors = {};
    
        if (!values.name || !_.trim(values.name)) {
        errors.name = 'Name is required';
        } else if (values.name.length > 50) {
        errors.name = 'Must be 15 characters or less';
        } else if (values.name.match("[<>]")) {
        errors.name = 'Please provide a valid name';
        }
    
        if (!values.email ) {
        errors.email = 'Email is required';
        } else if ( !validator.isEmail(values.email)) {
        errors.email = 'Invalid email address';
        } 

        if (!values.phone) {
            errors.phone = 'Phone number is required';
        } else if ( values.phone.length < 11) {
            errors.phone = 'Invalid phone number';
        }

    
        

        if (values.website && values.website !== ' ') {
        if (!validator.isURL(values.website)) {
            errors.website = 'Invalid website address';
        } else if (values.website.match("[<>]")) {
            errors.website = 'Please provide a valid website';
        } 
        } 

        // if (!values.message || !_.trim(values.message)) {
        //     errors.message = 'Message is required';
        // } else if (values.message.match("[<>]")) {
        //     errors.message = ' "<>" These are not allowed.';
        // } 
    
        return errors;
    };

    function myGreeting() {
        setIsThankYou(false);
        setIsError(false);
    };

    const quickFormik = useFormik({
        initialValues: quickContact,
        validate,
        onSubmit: (values, {resetForm}) => {
            setBtnLoader(true);

            // console.log(values)
            axios.post(`/quickContact`, values)
            .then(function (response) {
                resetForm({values: ""});
                navigate('/thank-you');
                setIsThankYou(true);
                
            })
            .catch(function (error) {
                setIsError(error?.message ? error.message: "Network Error")
            })
            .finally(() => {
                setBtnLoader(false)
                setTimeout(myGreeting, 10000);
            });
        },
    });

  return (

    <div className="schedule_consultation-box">

        <form onSubmit={quickFormik.handleSubmit} id="quickFormik">
            <div className="details" style={{ display: "block" }}>
                <div className="row">
                    <div className="right">
                        <div className="row">
                            <div className="left-sec">
                                <div className="form-group">
                                <label>Name <span style={{ color: '#ff0000'}}>*</span></label>
                                <input type="text" onChange={quickFormik.handleChange} autoCapitalize="none" autoComplete="new-password" 
                                    value={removeExtraSpace(quickFormik.values.name)} onBlur={quickFormik.handleBlur} name="name" placeholder="Enter your name"/>
                                    {quickFormik.touched.name && quickFormik.errors.name ? <div style={{color: '#ff0000'}}>{quickFormik.errors.name}</div> : null}
                                </div>
                            </div>
                            <div className="right-sec">
                                <div className="form-group">
                                <label>Phone <span style={{ color: '#ff0000'}}>*</span></label>
                                <PhoneInput 
                                    name="phone"
                                    placeholder="Enter phone number"
                                    international
                                    defaultCountry={inIn ? "IN":"US"}
                                    autoCapitalize="none" autoComplete="new-password" 
                                    countryCallingCodeEditable={false}
                                    value={quickFormik.values.phone}
                                    onBlur={quickFormik.handleBlur}
                                    onChange={(val) => quickFormik.setFieldValue('phone', val)}/>

                                {/* <input type="tel" onChange={quickFormik.handleChange}
                                    value={removeExtraSpace(quickFormik.values.phone)} onBlur={quickFormik.handleBlur} name="phone" placeholder={1234567890}/> */}
                                    {quickFormik.touched.phone && quickFormik.errors.phone ? <div style={{color: '#ff0000'}}>{quickFormik.errors.phone}</div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="left-sec">
                                <div className="form-group">
                                <label>Email <span style={{ color: '#ff0000'}}>*</span></label>
                                <input onChange={quickFormik.handleChange} autoCapitalize="none" autoComplete="new-password" 
                                    value={removeExtraSpace(quickFormik.values.email)} onBlur={quickFormik.handleBlur} type="email" name="email" placeholder="emailid@example.com" />
                                    {quickFormik.touched.email && quickFormik.errors.email ? <div style={{color: '#ff0000'}}>{quickFormik.errors.email}</div> : null}
                                </div>
                            </div>
                            <div className="right-sec">
                                <div className="form-group">
                                    <label>Website (If any)</label>
                                    <input onChange={quickFormik.handleChange} autoCapitalize="none" autoComplete="new-password"
                                        value={removeExtraSpace(quickFormik.values.website)} onBlur={quickFormik.handleBlur} type="text" name="website" placeholder="www.website.com" />
                                        {quickFormik.touched.website && quickFormik.errors.website ? <div style={{color: '#ff0000'}}>{quickFormik.errors.website}</div> : null}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="buttom-sec">
                                <div className="form-group">
                                    <label>Message</label>
                                    <textarea name="message" onChange={quickFormik.handleChange}
                                        value={removeExtraSpace(quickFormik.values.message)} onBlur={quickFormik.handleBlur}></textarea>
                                        {quickFormik.touched.message && quickFormik.errors.message ? <div style={{color: '#ff0000'}}>{quickFormik.errors.message}</div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="buttom-sec">
                                <div className="pull-right">
                                    <button type="submit" disabled={btnLoader} className="default-btn next-step">
                                        Submit
                                        {btnLoader ? (
                                            <span className='btn-ring-animation'></span>
                                        ):(
                                            <BsArrowRight />
                                        )}
                                        
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        
        {isThankYou && (
            <div className='alert alert-success mt-3'>
                Thank you for getting in touch! 
            </div>
        )}
        {isError && (
            <div className='alert alert-error mt-3'>
                {isError} 
            </div>
        )}
    </div>
                
  )
}
