import React, { useEffect, useRef, useState } from 'react';
import 'react-phone-number-input/style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import Router from './Router';
import ReactGA from 'react-ga';
import ExitModal from './Components/ExitModal/ExitModal';
import PageOpenModal from './Components/PageOpenModal/PageOpenModal';
import { Transition } from '@headlessui/react';

function App() {
  // const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

 
  // const userName = localStorage.getItem('exitModal');
  // useEffect(() => {
    
  //   const handleMouseLeave = () => {
  //     console.log(userName);
  //     if(userName === null){
  //       setIsConfirmationModalOpen(true);
  //     }
  //   };

  //   document.addEventListener('mouseleave', handleMouseLeave);
  //   return () => {
  //     document.removeEventListener('mouseleave', handleMouseLeave);
  //   };
  // }, [userName]);

  // const handleCancelExit = () => {
  //   setIsConfirmationModalOpen(false);
  // };

  // const handleConfirmExit = () => {
  //   setIsConfirmationModalOpen(false);
   
  // };
  

 


  // const [showModal, setShowModal] = useState(false);
  // const [modalOpened, setModalOpened] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
     
  //     const scrollPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
  //     if (scrollPercentage >= 80 && modalOpened === false) {
  //       setShowModal(true);
  //       setModalOpened(true);
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [modalOpened]);

  // const closeModal = () => {
  //   setShowModal(false);
  // };

  



  return (
    <div className='App'>
      

      <Router/>

      {/* <Transition 
        show={showModal}
        enter="ease-out duration-300"
        enterFrom="opacity-0 relative-modal"
        enterTo="opacity-100 relative-modal"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 relative-modal"
        leaveTo="opacity-0 relative-modal">
          <PageOpenModal onClose={closeModal} />
      </Transition>

      <Transition 
        show={isConfirmationModalOpen}
        enter="ease-out duration-300"
        enterFrom="opacity-0 relative-modal"
        enterTo="opacity-100 relative-modal"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 relative-modal"
        leaveTo="opacity-0 relative-modal">

        <ExitModal
          onCancel={handleCancelExit}
          onConfirm={handleConfirmExit} />
      </Transition> */}

      
    </div>
    
  );
}

export default App;
