import {  FooterLogo, HeaderLogo, MailIcon} from '../../Assets/index';
import '../Package/App.css'
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { Transition } from "@headlessui/react";
import { useNavigate } from 'react-router-dom';

function ThankYou() {

    const [guidelineModal, setGuidelineModal] = useState(false);

    const HandelGuidelineModal = () => {
        setGuidelineModal(!guidelineModal)
    }

    const navigate = useNavigate();

   
   
    return (
        <section className='sales-page thank-you-page'>
            
            <section className='container'>
                <div className='header-part'>
                    <a className='logo' onClick={() => navigate(-1)}>
                        <img src={HeaderLogo} alt='Reddensoft logo'/>
                    </a>

                   
                    {/* <a href="tel:+1 678 608 2725" className="call-btn">
                        <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
                            <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"/>
                        </svg>
                        </span>
                        <span className="number-box">
                            (678) 608-2725
                        </span>
                    </a> */}
                </div>
            </section>

            <section className='thank-you-box'>
                <h1 id='thankyoupage'>Thank You</h1>
                <p>Thank you for showing your interest. Our team will connect you shortly.</p>

                <button className='btn-stack' onClick={() => navigate(-1)}>
                    Go Back
                </button>
            </section>


            <footer style={{paddingTop: '0', marginTop: '0'}}>

                <div className="copy-riht" style={{ marginTop: '0'}}>
                    <div className="container">
                        <p>ReddenSoft Copyright 2023. All rights reserved.</p>
                    </div>
                </div>
            </footer>

            <Transition 
                show={guidelineModal}
                enter="ease-out duration-300"
                enterFrom="opacity-0 relative-modal"
                enterTo="opacity-100 relative-modal"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 relative-modal"
                leaveTo="opacity-0 relative-modal">
                    <div className='Guideline-modal'>
                        <div className='Guideline-modal-box'>
                            <button className='inline-btn' onClick={() => HandelGuidelineModal()} type='button'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20px"  height="20px">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>

                            <h2>Privacy Policy</h2>

                            <p>
                            This privacy policy of ReddenSoft declares the process and purpose of collecting/ storing/ sharing your data while you are using our services.
                            </p>

                            <p>
                            We recommend you read this entire privacy policy thoroughly to get a clear picture of your rights and choices. Using our services means you are agreeing with the privacy policy we have.
                            </p>

                            <h4>
                            Information that we collect:-
                            </h4>

                            <h6>
                                Personal information
                            </h6>

                            <p>
                            We collect the basic information from you like your name, phone number, email id, and website/ name of the organization while you schedule a meeting with us or ask for a partnership program. The purpose is to further communicate with you and get your job done.
                            </p>

                            <h6>
                            Non-personal information
                            </h6>
                            <p>
                            Collecting non-personal identification information about Users happens when they interact with our Site. It may include the type of computer, browser name, and technical information about the user while accessing our site (i.e., the operating system and the Internet service providers used) and other similar information.
                            </p>

                            <h6>
                            Use of collected information:-
                            </h6>

                            <ul className='list-items'>
                                <li>For the betterment of customer service</li>
                                <li>Providing you with the best-personalized user experience</li>
                                <li>For the Site improvement </li>
                                <li>For Payment Processing</li>
                                <li>For the improvement of promotion/ contest/ survey / other features of the Site </li>
                                <li>For sending periodic emails</li>
                            </ul>

                            <h6>
                            Media
                            </h6>

                            <p>
                            We recommend not sharing inappropriate or sensitive images or documents on the website. On the contrary, using images or documents from the website is strictly prohibited and it could be penalized under copyright law.
                            </p>

                            <h6>
                            Embedded content from other websites
                            </h6>

                            <p>
                            Articles on this site may include embedded content (e.g., videos, images, articles, etc.) from other third-party websites. Embedded content from other websites behaves exactly the same way it appeared on the original website.
                            </p>
                            <p>
                            Those third-party we bsites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content (including tracking your interaction with the embedded content) if you are already logged into those websites.
                            </p>

                            <h6>
                                Cookies policy
                            </h6>

                            <p>
                                Our website may use "cookies" to enhance the visitor’s experience. Cookies are being placed by the web browser on their hard drive for record-keeping purposes and sometimes to track information about them. Users may opt to set their web browser to refuse cookies or give them an alert when cookies are being sent. If they do so, then some parts of the website may not function properly.
                            </p>
                            <p>
                                Visitors of our website can subscribe to different services like subscribing to blogs, starting a project with us or joining the partnership program, and for that, we need your name, email address, and website information to store in cookies. These are for your convenience so that you do not have to fill in your details again when you go for other services on the website. These cookies will last for one year.
                            </p>

                            <h6>
                                Information Sharing
                            </h6>
                            <p>
                                We strictly oppose sharing information with any third party. Information shared with us will only be used for the betterment of your project and it will be kept only with us. We also request you to not disclose any confidential statement about the ongoing project with any other website or third-party domain.
                            </p>
                            
                        </div>
                    </div>  
            </Transition>
        </section>
    );
}

export default ThankYou;
