import React, { useRef, useState } from 'react';
import { 
    David, HeaderLogo, Michel, Bently, Kimberly, portfolionewOne, PortfolionewTwo, PortfolionewThree,
    PortfolionewEight, PortfolionewNine, PortfolionewEleven, PortfolionewTwelve, PortfolionewThirteen, LogoPortfolioOne,
    LogoPortfolioTwo, LogoPortfolioThree, LogoPortfolioFour, LogoPortfolioFive, LogoPortfolioSix, LogoPortfolioSeven, 
    LogoPortfolioEight,LogoPortfolioNine,PortfolioTen,PortfolioEleven,PortfolioTwelve,LogoPortfolioTen,IllustrationOne, 
    IllustrationTwo,IllustrationThree, IllustrationFour, IllustrationFive, IllustrationSix,IllustrationSeven, IllustrationEight, 
    IllustrationNine,lmtdOffer, mostAnimath,cotenTesty,PortfolionewFifteen,PortfolionewSixteen,PortfolionewSeventeen, Eric, webPortfolio2, webPortfolio1, webPortfolio3, webPortfolio5, webPortfolio4, webPortfolio6, webPortfolio7, webPortfolio8, webPortfolio9, webPortfolio10, webPortfolio11, webPortfolio12, webPortfolio13, webPortfolio14, webPortfolio16, webPortfolio17, webPortfolio18, webPortfolio19, webPortfolio15, webPortfolio20, LogoTrust, FooterLogo
} from '../../Assets/index';
import { useEffect } from 'react';
import Slider from 'react-slick';
import { Transition } from '@headlessui/react';
import {FaFacebookF, FaTwitter, FaLinkedinIn} from 'react-icons/fa';
import ScheduleConsultation from '../ScheduleConsultation';
import WebFont from 'webfontloader';
import '../Package/App.css'
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';


function Package() {

    
    const [timerHours, setTimerHours] = useState("0");
    const [timerMinutes, setTimerMinutes] = useState("0");
    const [timerSeconds, setTimerSeconds] = useState("0");
    const [cookies, setCookie] = useCookies(['time']);

    const [guidelineModal, setGuidelineModal] = useState(false);

    const HandelGuidelineModal = () => {
        setGuidelineModal(!guidelineModal)
    }

    let interval = useRef();

    const startTimer = (countdownDate) => {
        const now = new Date().getTime();
        const distance = countdownDate - now;

        
        const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            clearInterval(interval.current);
        } else {
        
            setTimerHours(hours);
            setTimerMinutes(minutes);
            setTimerSeconds(seconds);
        }
    };

    useEffect(() => {
        WebFont.load({
            google: {
            families: ['Roboto Slab:200,300,400', 'Montserrat:300,400,500,700','Patua One', 'Mulish']
            },
            custom: {
            families: ['Patua One'],
            urls: ['https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400&display=swap',
                    'https://fonts.googleapis.com/css2?family=Montserrat:wght@300,400;500;700&display=swap', 'https://fonts.googleapis.com/css2?family=Mulish&family=Patua+One&display=swap'] // URLs of the font CSS files
            }
        });

       

        if(cookies?.offerTime && cookies?.OffetEnd && cookies?.OffetEnd > new Date().getTime()){
            
            interval.current = setInterval(() => {
                startTimer(+cookies?.offerTime);
            }, 1000);

        } else{

            const currentTime = new Date();
            
            const nextDay = new Date();
            nextDay.setHours(nextDay.getHours() + 36);
            nextDay.setMinutes(nextDay.getMinutes() + 2);
            let expires = nextDay.getTime()

            currentTime.setHours(currentTime.getHours() + 12);
            currentTime.setMinutes(currentTime.getMinutes() + 0);
            
            let countdownDate2 = currentTime.getTime();

            setCookie('offerTime', countdownDate2, { path: '/unlimited-graphic-design-packages' });
            setCookie('OffetEnd', expires, { path: '/unlimited-graphic-design-packages' });
            
            interval.current = setInterval(() => {
                startTimer(+countdownDate2);
            }, 1000);
        }

        

       
    }, [cookies, setCookie]);

    var portfolioSlider = {
        infinite:true, 
        speed:500,
        dots: true,
        autoplay: true,
        slidesToShow:3, 
        slidesToScroll:1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1
              }
            }
        ]
    };

    const [scheduleModel, setScheduleModel] = useState(false);
    const [messageModel, setMessageModel] = useState(false);
    const [isTry, setIsTry] = useState();
    const HandelMessageModel = (e, val) => {
        if(val){
            setIsTry(val);
        }else{
            setIsTry();
        }
        setMessageModel(true)
    };


    const toggleModal = () => {
        setScheduleModel(false);
        setMessageModel(false)
    };
      
    const submitBasicFrm = () => {
        document.getElementById("basic").submit();
    };

    const submitAdvancedFrm = () => {
        document.getElementById("advanced").submit();
    };

    const submitPremiumFrm = () => {
        document.getElementById("premium").submit();
    };

    const ref = useRef(null);

    const handleClick = () => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };


    const Modalref = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (Modalref.current && !Modalref.current.contains(event.target)) {
                setGuidelineModal(false);
                setGuideNew(false);
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
        document.removeEventListener('click', handleClickOutside);
        };

    }, []);


    const [guideNew, setGuideNew] = useState(false);


  return (
    <section className='sales-page graphics unlimited-graphic-design-packages'>
        <Helmet>
                   
            <script id="facebook-pixel-script">
                {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js'),
                fbq('init', '273096641131718'),
                fbq('track', 'PageView')
                `}
            </script>
            <noscript id="facebook-pixel-image">
                {`
                    <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=273096641131718&ev=PageView&noscript=1"
                />
                `}
                
            </noscript>
        </Helmet>

        <section className='header-top'>
           <div className='container'>
            <div className='header-part'>
                <a className='logo' href='/'>
                    <img src={HeaderLogo} alt='Reddensoft logo'/>
                </a>

                <div className='header_right'>
                
                    <button onClick={(e) => HandelMessageModel(e, 'Start Free Trial')} className='header-gradeint-btn'>
                        
                        <span>
                            Start Free Trial
                        </span>
                        
                    </button>
                    <button className='header-gradeint-outline mobile-none' onClick={(e) => HandelMessageModel(e)}>
                        <span>
                            Contact Support
                        </span>
                    </button>
                </div>
            </div>
            </div>
        </section>
        
        <section className='banner-bg'>
            <h1>Unleash Your Brand's Visual <br></br>Potential with  <strong>Stunning</strong> Graphic Designs</h1>
           
            <p>Access any of our graphic design plans and enjoy <strong>unlimited design solutions.</strong><br></br> 
             Get complete high-quality graphic designs along with revisions as much as you want.</p>
            <button  onClick={handleClick} className='pckg-btn'>View Packages</button>
         </section>
         
        <section className='Checkout-sec'>
            <div className='title_colabr container'>
                <h2>
                    Checkout
                </h2>
                <p>our newest design goodies</p>
            </div>
        </section>


        {/* Banner */}
        <section className='slider-sec-total top-minus-space'>
             <div className='container'>
             <div className='slider-section'>
                <div className='inside_title'>
                    <h3>Web Design</h3>
                </div>
                

                <div className='slider-image'>
                    <Slider {...portfolioSlider}>
                       
                        <img src={webPortfolio1} alt=''/>
                        <img src={webPortfolio2} alt=''/>
                        <img src={webPortfolio3} alt=''/>
                        <img src={webPortfolio4} alt=''/>
                        <img src={webPortfolio5} alt=''/>
                        <img src={webPortfolio6} alt=''/>
                        <img src={webPortfolio7} alt=''/>
                        <img src={webPortfolio8} alt=''/>
                        <img src={webPortfolio9} alt=''/>
                        <img src={webPortfolio10} alt=''/> 
                        <img src={webPortfolio11} alt=''/>
                        <img src={webPortfolio12} alt=''/>
                        <img src={webPortfolio13} alt=''/>
                        <img src={webPortfolio14} alt=''/>
                        <img src={webPortfolio15} alt=''/> 
                        <img src={webPortfolio16} alt=''/>
                        <img src={webPortfolio17} alt=''/>
                        <img src={webPortfolio18} alt=''/>
                        <img src={webPortfolio19} alt=''/>
                        
                        <img src={webPortfolio20} alt=''/>
                       
                    </Slider>
                </div>
            </div>

             </div>
        </section>


        <section className='slider-sec-total'>
             <div className='container'>
             <div className='slider-section'>
                <div className='inside_title'>
                    <h3>Banners</h3>
                </div>
                

                <div className='slider-image'>
                    <Slider {...portfolioSlider}>
                       
                        <img src={PortfolioEleven} alt=''/>
                        <img src={PortfolioTen} alt=''/>
                        <img src={PortfolioTwelve} alt=''/>
                        <img src={portfolionewOne} alt=''/>
                        <img src={PortfolionewTwo} alt=''/>
                        <img src={PortfolionewThree} alt=''/>
                        <img src={PortfolionewEight} alt=''/>
                        <img src={PortfolionewNine} alt=''/>
                        <img src={PortfolionewTwelve} alt=''/>
                        <img src={PortfolionewThirteen} alt=''/> 
                        <img src={PortfolionewEleven} alt=''/>
                        <img src={PortfolionewFifteen} alt=''/>
                        <img src={PortfolionewSixteen} alt=''/>
                        <img src={PortfolionewSeventeen} alt=''/>
                        
                        
                       
                    </Slider>
                </div>
            </div>

             </div>
        </section>

        {/* Illustration */}
        <section className='slider-sec-total'>
            <div className='container'>
            <div className='slider-section ratio_art'>
            <div className='inside_title'>
                <h3>Illustrations</h3>
            </div>
            

            <div className='slider-image illustration__slider'>
                <Slider {...portfolioSlider}>
                    <img src={IllustrationOne} alt=''/>
                    <img src={IllustrationTwo} alt=''/>
                    <img src={IllustrationThree} alt=''/>
                    <img src={IllustrationFour} alt=''/>
                    <img src={IllustrationFive} alt=''/>
                    <img src={IllustrationSix} alt=''/>
                    <img src={IllustrationSeven} alt=''/> 
                    <img src={IllustrationEight} alt=''/> 
                    <img src={IllustrationNine} alt=''/>
                </Slider>
            </div>
        </div>

            </div>
        </section> 
      
        {/* Logo */}
        <section className='slider-sec-total logos'>
             <div className='container'>
             <div className='slider-section'>
                
                <div className='inside_title'>
                    <h3>Logos</h3>
                </div>
                <div className='slider-image'>
                    <Slider {...portfolioSlider}>
                        <img src={LogoPortfolioOne} alt=''/>
                        <img src={LogoPortfolioTwo} alt=''/>
                        
                        
                        <img src={LogoPortfolioFour} alt=''/>
                        <img src={LogoPortfolioFive} alt=''/>
                        <img src={LogoPortfolioSix} alt=''/>
                        <img src={LogoPortfolioSeven} alt=''/> 
                        <img src={LogoPortfolioThree} alt=''/>
                        <img src={LogoPortfolioEight} alt=''/>
                        <img src={LogoPortfolioNine} alt=''/>
                        <img src={LogoPortfolioTen} alt=''/>

                      
                       
                    </Slider>
                </div>
            </div>

             </div>
        </section>


        {/* offer */}
        <section className='offer_reMake_section'>
          <div className='container'>
            <div className='second-section extra-space re-make_offer'>
                
                <div className='offer_thmbl'>
                    <img src={lmtdOffer} alt=''/>
                </div>

                <div className='gradeint-box'>
                    <div className='ttx'>
                        <h4>
                            <span>Exclusive Offer </span>
                            For <br/> New Clients
                        </h4>
                        
                    </div>
                    
                </div>

                <div className='offer-box'>
                    
                    <h3 className='top_ttle'>50%<span>off</span></h3>
                    {/* <h6>On all three packages</h6> */}
                    <h6>For 2 months</h6>
                    <button onClick={handleClick} className='btn-outline btn-outline-blue'>View Packages</button>

                    { timerHours === '0' && timerMinutes === "0" && timerSeconds === "0"  ? (
                        <div className='time-count-box'>
                            <p>Offer expired for today! Please try tomorrow</p>
                        </div>
                    ):(
                        <div className='time-count-box'>
                            <p>OFFER ENDING SOON</p>
                            <div className='time-count'>
                                <h3>
                                    
                                    <span>
                                        <span>{timerHours < 10 ? '0' : ''}{timerHours}</span>
                                        <span className='text'>Hour</span>
                                    </span> :
                                    <span>
                                        <span>{timerMinutes < 10 ? '0' : ''}{timerMinutes}</span>
                                        <span className='text'>Minutes</span>
                                    </span> :
                                    <span>
                                        <span> {timerSeconds < 10 ? '0' : ''}{timerSeconds}</span>
                                        <span className='text'>Seconds</span>
                                    </span>
                                </h3>
                            </div>
                        </div> 
                    )}
                    
                    
                </div>
            </div>
         </div>
        </section>

        <section className="price_new_section trust-logo">
            <div className='container'>
                <div className='price__title'>
                    <h2>
                        Brands that <span> trust </span> our designers
                    </h2>
                </div>
                <div>
                    <img src={LogoTrust} alt=''/>
                </div>
            </div>
        </section>

     
        <section ref={ref} className='price_new_section bg-gray' id='price-new'>
            <div className='container'>

                <div className='price__title'>
                    <h2>
                        Current <span>Packages</span>
                    </h2>
                </div>

                <div className='popular-pckg'>
                    <div className='mst mb-dspn'>
                        <img src={mostAnimath} alt=""></img>
                    </div>
                    <div className='packages' >
                        <div className='package-box first_package-box'>
                        <h2>Starter</h2> 
                        <p>Unlock Limitless Creativity with our Basic Pricing Package</p>
                        <div className='price-text'>
                            {/* <del>$899 </del> */}
                            <del>$499/mo </del>
                                $249
                            {/* <span>/ 2 months</span> */}
                        </div>
                        <span  className='save-text'> SAVE $249/mo for 2 months!</span>
                            
                            <div className='package-list'>
                                <h3>Services</h3>
                                <ul>
                                    <li>Logo design</li>
                                    <li>UI/UX friendly Website and App designs</li>
                                    <li>Custom illustration</li>
                                    <li>Basic GIFs</li>
                                    <li>Landing Page Designs</li>
                                    <li>Presentation</li>
                                    <li>Canva Designs</li>
                                </ul>
                                
                                <ul className='deactive-list'>
                                    
                                    <li> 
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13">
                                            <path clipRule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fillRule="evenodd"></path>
                                        </svg>
                                        Micro video <small> (60 Seconds)</small>
                                    </li>

                                    <li> 
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13">
                                            <path clipRule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fillRule="evenodd"></path>
                                        </svg>
                                        Print & Digital Designs
                                    </li>

                                    <li> 
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13">
                                            <path clipRule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fillRule="evenodd"></path>
                                        </svg>
                                        Packaging Design
                                    </li>
                                    <li> 
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13">
                                            <path clipRule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fillRule="evenodd"></path>
                                        </svg>
                                        Publication and Typographic Design
                                    </li>
                                    <li> 
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13">
                                            <path clipRule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fillRule="evenodd"></path>
                                        </svg>
                                        Template Customization
                                    </li>
                                </ul>
                                
                                <h3 className='features-text'>Features</h3>
                                <ul>
                                    <li>1-2 day turnaround*</li>
                                    <li>2 Active Requests</li>
                                    <li>Platform independent design</li>
                                    <li>Creative designer</li>
                                    <li>Dedicated Design Team</li>
                                    <li>Onboarding Manager</li>
                                </ul>
                                <ul className='deactive-list'>
                                    <li> 
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clipRule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fillRule="evenodd"></path>
                                        </svg> 
                                        Same-day turnaround
                                    </li>
                                    <li> 
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clipRule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fillRule="evenodd"></path>
                                        </svg>
                                        Real-time collaboration
                                    </li>
                                    <li> 
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clipRule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fillRule="evenodd"></path>
                                        </svg> 
                                        Get source file
                                    </li>
                                    <li> 
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clipRule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fillRule="evenodd"></path>
                                        </svg>
                                        Unlimited revisions
                                    </li>
                                    
                                </ul>
                            </div>

                            <div className='btm-grp'> 
                                <form action="https://www.paypal.com/cgi-bin/webscr" id="basic" method="post">
                                    <input type="hidden" name="cmd" value="_xclick"/>
                                    <input type="hidden" name="business" value="dipaktewary@yahoo.com"/> 
                                    <input type="hidden" name="item_name" value="Starter"/> 
                                    <input type="hidden" name="item_number" value="498"/> 
                                    <input type="hidden" name="amount" value="498.00"/> 
                                    <input type="hidden" id="currency" name="currency" value="USD" />
                                    <input type="hidden" name="return" value="https://reddensoft.us/thank-you"/>
                                    <input type="hidden" name="cancel_return" value="https://reddensoft.us/unlimited-graphic-design-packages"/>
                                    <button className='talk-btn' onClick={(e) => submitBasicFrm(e)}>Buy Now</button>
                                    <button className='talk-btn outline' type='button' onClick={(e) => HandelMessageModel(e, 'Start Free Trial (Starter)')}>Start Free Trial</button>
                                </form>
                            </div>
                        </div>
                        <div className='mst mb-dspb'><img src={mostAnimath} alt=""></img></div>
                        <div className='package-box second_package-box'>
                        
                            <h2>Advanced</h2> 
                            <p>Elevate your brand with our advanced pricing package</p>
                            {/* <div className='price-text'>
                                <del>$1199</del>
                                $699 
                                <span>/ month</span>
                            </div> */}

                            <div className='price-text'>
                                {/* <del>$899 </del> */}
                                <del>$699/mo </del>
                                    $349
                                {/* <span>/ 2 months</span> */}
                            </div>
                            <span className='save-text'> SAVE $349/mo for 2 months!</span>
                        
                        <div className='package-list'>
                            <h3>Services</h3>
                            <ul>
                                <li>Logo design</li>
                                <li>UI/UX friendly Website and App designs</li>
                                <li>Custom illustration</li>
                                <li>Basic GIFs</li>
                                <li>Landing Page Designs</li>
                                <li>Presentation</li>
                                <li>Canva Designs</li>
                                <li>Micro video <small>(60 Seconds)</small></li>
                                <li>Print & Digital Designs</li>
                            </ul>
                            <ul className='deactive-list'>
                                

                                <li> 
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13">
                                        <path clipRule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fillRule="evenodd"></path>
                                    </svg>
                                    Packaging Design
                                </li>
                                <li> 
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13">
                                        <path clipRule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fillRule="evenodd"></path>
                                    </svg>
                                    Publication and Typographic Design
                                </li>
                                <li> 
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13">
                                        <path clipRule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fillRule="evenodd"></path>
                                    </svg>
                                    Template Customization
                                </li>
                            </ul>
                            
                            <h3 className='features-text'>Features</h3>
                            <ul>
                                <li>1-2 day turnaround*</li>
                                <li>2 Active Requests</li>
                                <li>Platform independent design</li>
                                <li>Creative designer</li>
                                <li>Dedicated Design Team</li>
                                <li>Onboarding Manager</li>
                                <li>Same-day turnaround</li>
                                <li>Real-time collaboration</li>
                            </ul>
                            <ul className='deactive-list'>
                                
                                <li> 
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clipRule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fillRule="evenodd"></path>
                                    </svg> 
                                    Get source file
                                </li>
                                <li> 
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 13 15" width="13"><path clipRule="evenodd" d="m2.27501 4.06251v2.18746h-.97498c-.718249 0-1.30003.55938-1.30003 1.25001v6.25012c0 .6906.581781 1.2499 1.30003 1.2499h10.39987c.7182 0 1.3001-.5593 1.3001-1.2499v-6.25012c0-.69063-.5819-1.25001-1.3001-1.25001h-.9749v-2.18746c0-2.2437-1.89165-4.06251-4.225-4.06251s-4.22499 1.81878-4.22499 4.06251zm1.625 2.18746v-2.18746c0-1.38123 1.16346-2.50005 2.59995-2.50005 1.4365 0 2.60003 1.11882 2.60003 2.50005v2.18746zm1.29996 3.43754c0-.69059.58175-1.24997 1.29999-1.24997.71825 0 1.29996.55938 1.29996 1.24997 0 .44069-.2373.82819-.59469 1.05009 0 0 .12698.7376.26978 1.6062 0 .2593-.21774.4687-.48752.4687h-.97505c-.26975 0-.48745-.2094-.48745-.4687l.26978-1.6062c-.35742-.2219-.5948-.6094-.5948-1.05009z" fill="#edeaea" fillRule="evenodd"></path>
                                    </svg>
                                    Unlimited revisions
                                </li>
                                
                            </ul>
                            
                        </div>

                            <div className='btm-grp'>
                                <form action="https://www.paypal.com/cgi-bin/webscr" id="advanced" method="post">
                                    <input type="hidden" name="cmd" value="_xclick"/>
                                    <input type="hidden" name="business" value="dipaktewary@yahoo.com"/> 
                                    <input type="hidden" name="item_name" value="Advanced"/> 
                                    <input type="hidden" name="item_number" value="698"/> 
                                    <input type="hidden" name="amount" value="698.00"/> 
                                    <input type="hidden" id="currency" name="currency" value="USD" />
                                    <input type="hidden" name="return" value="https://reddensoft.us/thank-you"/>
                                    <input type="hidden" name="cancel_return" value="https://reddensoft.us/unlimited-graphic-design-packages"/>
                                    <button className='talk-btn' onClick={(e) => submitAdvancedFrm(e)}>Buy Now</button>
                                    <button className='talk-btn outline' type='button' onClick={(e) => HandelMessageModel(e, 'Start Free Trial (Advanced)')}>Start Free Trial</button>
                                </form>
                            </div>
                        
                        </div>

                        <div className='package-box third_package-box'>
                            <h2>Premium</h2> 
                            <p>Unleash the full potential through our premium package</p>
                            {/* <div className='price-text'>
                                <del>$1599</del>
                                $999
                                <span>/ month</span>
                            </div> */}

                            <div className='price-text'>
                                {/* <del>$899 </del> */}
                                <del>$999/mo </del>
                                    $499
                                {/* <span>/ 2 months</span> */}
                            </div>
                            <span  className='save-text'> SAVE $499/mo for 2 months!</span>

                        
                        <div className='package-list'>
                            <h3>Services</h3>
                            <ul>
                                <li>Logo design</li>
                                <li>UI/UX friendly Website and App designs</li>
                                <li>Custom illustration</li>
                                <li>Basic GIFs</li>
                                <li>Landing Page Designs</li>
                                <li>Presentation</li>
                                <li>Canva Designs</li>
                                <li>Micro video <small>(120 Seconds)</small></li>
                                <li>Print & Digital Designs</li>

                                <li>Packaging Design</li>
                                <li>Publication and Typographic Design</li>
                                <li>Template Customization</li>
                                
                            </ul>
                            <ul></ul>
                            
                            <h3 className='features-text'>Features</h3>
                           
                            <ul>
                                <li>1-2 day turnaround*</li>
                                <li>2 Active Requests</li>
                                <li>Platform independent design</li>
                                <li>Creative designer</li>
                                <li>Dedicated Design Team</li>
                                <li>Onboarding Manager</li>
                                <li>Same-day turnaround</li>
                                <li>Real-time collaboration</li>
                                <li>Get source file</li>
                                <li>Unlimited revisions</li>
                            </ul>
                            
                        </div>

                            <div className='btm-grp'>
                                <form action="https://www.paypal.com/cgi-bin/webscr" id="premium" method="post">
                                    <input type="hidden" name="cmd" value="_xclick"/>
                                    <input type="hidden" name="business" value="dipaktewary@yahoo.com"/> 
                                    <input type="hidden" name="item_name" value="Premium"/> 
                                    <input type="hidden" name="item_number" value="998"/> 
                                    <input type="hidden" name="amount" value="998.00"/> 
                                    <input type="hidden" id="currency" name="currency" value="USD" />
                                    <input type="hidden" name="return" value="https://reddensoft.us/thank-you"/>
                                    <input type="hidden" name="cancel_return" value="https://reddensoft.us/unlimited-graphic-design-packages"/>
                                    <button className='talk-btn' onClick={(e) => submitPremiumFrm(e)}>Buy Now</button>
                                    <button className='talk-btn outline' type='button' onClick={(e) => HandelMessageModel(e, 'Start Free Trial (Premium)')}>Start Free Trial</button>
                                </form>
                                
                            </div>    
                        </div>
                    </div>
                </div>

                <div className='free-trial-box'>
                    <div className='free-trial-left-box'>
                        <h6>Try Our</h6>
                        <h2>FREE TRIAL !</h2>
                        <p>Sign up today for the Free-Trial subscription and you will be able to make free design requests as per the guidelines.</p>
                        <button  onClick={() => setGuideNew(true)} type='button'>View Guidelines</button>
                        <br/>
                        <br/>
                    </div>
                    <div className='free-trial-right-box'>
                        <ul>
                            <li>Active Request for 1 Video</li>
                            <li>Onboarding Project Manager</li>
                            <li>At Most 3 Graphics Design Requests</li>
                            <li>Same Day/ 1-2 Day Turnaround</li>
                            <li>Platform Independent Design</li>
                            <li>Unlimited Revisions</li>
                            <li>Designated Designers</li>
                            <li>No Contract</li>
                            <li>Improved Design Concept</li>
                        </ul>
                        <div>
                            <button className='btn' type='button' onClick={(e) => HandelMessageModel(e, '7-day Free Trial')}>
                                Start your Free 7-day trial
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

     
        <section className='testmonial-section testmonial-section_neo'>
            <div className='container'>
                <div className='testmonial-top'>
                
                    <h4>We are <span>proud</span> to inspire</h4>
                </div>

                <div className='testmonial-lg-box'>
                    <div className='testmonial-user-image'>
                        <img src={Eric} alt=''/>
                    </div>

                    <div className='testmonial-user-details'>
                        <div className='top_head'>
                            <img src={cotenTesty} alt=''/>
                            <h3>I would thoroughly recommend</h3>
                        </div>
                        <p className='des'>
                        “ I would thoroughly recommend Reddensoft’s work. On my project they ‘got’ the brief straight away and the combination their creative input, eye for detail, and in-depth appreciation of production processes meant that we could totally trust to deliver! ”
                        </p>
                        <div className='profn_dtl'>
                            <h4>Mike Boggus</h4>
                            <h6>Entrepreneur</h6>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='testmonial-section testmonial-section_neo box-testi'>
            <div className='container'>
                <div className='testmonial-grid'>
                    <div className='testmonial-box'>
                        <div className='top_head'>
                            <img src={cotenTesty} alt=''/>
                            <h3>Outstanding , creative design services</h3>
                        </div>
                        
                        <p className='des'>
                            “I was really impressed with the level of service that I received from Reddensoft. They were always responsive to my needs, and they were always willing to go the extra mile. I would definitely recommend them to anyone looking for their outstanding , creative design services. You guys are amazing.”
                        </p>
                        <div className='testmonial-user'>
                            <div className='testmonial-user-image'>
                                <img src={David} alt='David Miller'/>
                            </div>

                            <div className='testmonial-user-details'>
                                <div>
                                    <h4>David Miller</h4>
                                    <h6>Entrepreneur</h6>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className='testmonial-box'>
                        <div className='top_head'>
                            <img src={cotenTesty} alt=''/>
                            <h3>They're incredibly results-oriented</h3>
                        </div>
                        <p className='des'>
                        “I've worked with 2 unlimited design service provide in the past, but Reddensoft.us is by far the best. They're incredibly results-oriented, and they're always willing to go the extra mile to help me achieve my goals. I would definitely recommend them to anyone looking for unlimited design service packages.”
                        </p>
                        <div className='testmonial-user'>
                            <div className='testmonial-user-image'>
                                <img src={Michel} alt=''/>
                            </div>

                            <div className='testmonial-user-details'>
                                <div>
                                    <h4>Michel Ambrosio</h4>
                                    <h6>Founder @Quirkel</h6>
                                </div>
                               
                            </div>
                        </div>

                        
                    </div>
                    <div className='testmonial-box'>
                        <div className='top_head'>
                            <img src={cotenTesty} alt=''/>
                            <h3>They are a great partner for our business.</h3>
                        </div>
                        <p className='des'>
                        “They are a great partner for our business. They're always up-to-date on the latest design trends, and they're always willing to go the extra mile to help us achieve our goals. We're confident that we're in good hands with them for any design services. Our clients are super happy. We are having a customized plan and hired few designer for our agency. ”
                        </p>
                        <div className='testmonial-user'>
                            <div className='testmonial-user-image'>
                                <img src={Bently} alt=''/>
                            </div>

                            <div className='testmonial-user-details'>
                                <div>
                                    <h4>Bently Ritter</h4>
                                    <h6>President & CEO at BeneFit Personal Training</h6>
                                </div>
                                
                            </div>
                        </div>

                        
                    </div>
                    <div className='testmonial-box'>
                    <div className='top_head'>
                            <img src={cotenTesty} alt=''/>
                            <h3>Our lead generation has increased by 200%</h3>
                        </div>
                        <p className='des'>
                        “We've been working with reddensoft.US for the past 2 years on our graphic design services. They've helped us to increase our social presence, website with very high quality brand presence. Our lead generation has increased by 200%. We're really happy with the results, and we would definitely recommend them to other businesses. ”
                        </p>
                        <div className='testmonial-user'>
                            <div className='testmonial-user-image'>
                                <img src={Kimberly} alt=''/>
                            </div>

                            <div className='testmonial-user-details'>
                                <div>
                                    <h4>Kimberly</h4>
                                    <h6>Entrepreneur</h6>
                                </div>
                               
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </section>
  

        <footer>
        <div className="container">
          <div className="wap">
            <div className="item">
              <a href="#" className="frt-logo">
                <img src={FooterLogo} alt="" />
              </a>
            </div>
            
            <div className="item">
              <a href="tel:+1 678 608 2725" className="call-btn">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
                    <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"/>
                  </svg>
                </span>
                <span className="number-box">
                  (678) 608-2725
                </span>
              </a>
            </div>

            <div className="item">
              <p>
                <a onClick={() => HandelGuidelineModal()}>
                  Privacy Policy
                </a>
              </p>
            </div>

          </div>
        </div>
        <div className="copy-riht">
          <div className="container">
            <p>ReddenSoft Copyright 2023. All rights reserved.</p>
          </div>
        </div>
      </footer>

        <Transition 
            show={messageModel}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal">
            <ScheduleConsultation isSchedule={false} isNewTry={isTry} toggleModal={toggleModal}/>
        
        </Transition>
       
        <Transition 
            show={scheduleModel}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal">
            <ScheduleConsultation isSchedule={true} toggleModal={toggleModal}/>
        
        </Transition>


        <Transition 
            show={guidelineModal}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal">
                <div className='Guideline-modal'>

                    <div className='Guideline-modal-box' ref={Modalref}>
                      <button className='inline-btn' onClick={() => HandelGuidelineModal()} type='button'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20px"  height="20px">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>

                      <div className="Guideline-modal-inside">
                        <h2>Privacy Policy</h2>

                        <p>
                        This privacy policy of ReddenSoft declares the process and purpose of collecting/ storing/ sharing your data while you are using our services.
                        </p>

                        <p>
                        We recommend you read this entire privacy policy thoroughly to get a clear picture of your rights and choices. Using our services means you are agreeing with the privacy policy we have.
                        </p>

                        <h4>
                          Information that we collect:-
                        </h4>

                        <h6>
                            Personal information
                        </h6>

                        <p>
                        We collect the basic information from you like your name, phone number, email id, and website/ name of the organization while you schedule a meeting with us or ask for a partnership program. The purpose is to further communicate with you and get your job done.
                        </p>

                        <h6>
                           Non-personal information
                        </h6>
                        <p>
                        Collecting non-personal identification information about Users happens when they interact with our Site. It may include the type of computer, browser name, and technical information about the user while accessing our site (i.e., the operating system and the Internet service providers used) and other similar information.
                        </p>

                        <h6>
                          Use of collected information:-
                        </h6>

                        <ul className='list-items'>
                            <li>For the betterment of customer service</li>
                            <li>Providing you with the best-personalized user experience</li>
                            <li>For the Site improvement </li>
                            <li>For Payment Processing</li>
                            <li>For the improvement of promotion/ contest/ survey / other features of the Site </li>
                            <li>For sending periodic emails</li>
                        </ul>

                        <h6>
                        Media
                        </h6>

                        <p>
                        We recommend not sharing inappropriate or sensitive images or documents on the website. On the contrary, using images or documents from the website is strictly prohibited and it could be penalized under copyright law.
                        </p>

                        <h6>
                          Embedded content from other websites
                        </h6>

                        <p>
                          Articles on this site may include embedded content (e.g., videos, images, articles, etc.) from other third-party websites. Embedded content from other websites behaves exactly the same way it appeared on the original website.
                        </p>
                        <p>
                          Those third-party we bsites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content (including tracking your interaction with the embedded content) if you are already logged into those websites.
                        </p>

                        <h6>
                        Cookies policy
                        </h6>

                        <p>
                          Our website may use "cookies" to enhance the visitor’s experience. Cookies are being placed by the web browser on their hard drive for record-keeping purposes and sometimes to track information about them. Users may opt to set their web browser to refuse cookies or give them an alert when cookies are being sent. If they do so, then some parts of the website may not function properly.
                        </p>
                        <p>
                          Visitors of our website can subscribe to different services like subscribing to blogs, starting a project with us or joining the partnership program, and for that, we need your name, email address, and website information to store in cookies. These are for your convenience so that you do not have to fill in your details again when you go for other services on the website. These cookies will last for one year.
                        </p>

                        <h6>
                        Information Sharing
                        </h6>
                        <p>
We strictly oppose sharing information with any third party. Information shared with us will only be used for the betterment of your project and it will be kept only with us. We also request you to not disclose any confidential statement about the ongoing project with any other website or third-party domain.
                        </p>
                      </div>
                    </div>
                </div>  
      </Transition>


      <Transition 
            show={guideNew}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal">
                <div className='Guideline-modal'>
                    <div className='Guideline-modal-box' ref={Modalref}>
                        <button className='inline-btn' onClick={() => setGuideNew(false)} type='button'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="20px"  height="20px">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                        <div className="Guideline-modal-inside">

                        <h2>Guidelines</h2>

                        <p>
                            Experience the design esthetics of our designers. Your 7-day free trial period will be activated after your 
                            signup. We won't charge you extra for this trial period and if you wish you can cancel the final 
                            subscription once the trial period is over.
                        </p>

                        <p>
                            During the trial period, you can get your design job done with a digital hologram watermark embedded in 
                            your design. Moreover, we will provide you with an expert design concept that will help you to get a clear 
                            picture of your project.
                        </p>

                        <p>
                            You can opt for 1 video and at most 3 graphic designs during your trial period.
                        </p>

                        <p>
                            Video length must be within 15 seconds and unlimited video revisions can be done during trial period.
                        </p>

                        <p>
                            For each graphic design request; design service will provide for the single print or digital creative, 
                            or up to 2-3 pages of a multi-page design, depending on the amount of content.
                        </p>

                        <h6>
                            The following requests can't be served during trial period:-
                        </h6>

                        <ul className='list-items'>
                            <li>Trial designs can't be resized</li>
                            <li>Logo with different variations can't be provided</li>
                            <li>Brand mascot design is not allowed</li>
                            <li>Presentation designs can't be allowed</li>
                            <li>Complex illustrations can't be provided</li>
                            <li>Multipage designs are only allowed upto 3 pages depending on the amount of content.</li>
                        </ul>

                        <h5>
                            To know more about the free trial, reach out to us now!
                        </h5>
                        </div>
                    </div>
                </div>  
        </Transition>
    </section>
  );
}

export default Package;
