import React, { useRef, useState } from 'react';
import { ArrowOne, David, HeaderLogo, MailIcon, Michel, Bently, Kimberly, ReviewStart, contactBg, curcelArrow, salesContactArrow, salesServicesFour, salesServicesOne, salesServicesThree, salesServicesTwo, salesTeamImage, salesTeamImagethree, textArrowBg, PortOne, PortTwo, PortThree, PortFive, PortSix} from '../../Assets/index';
import { useFormik } from 'formik';
import axios from 'axios';
import { useEffect } from 'react';
import Slider from 'react-slick';
import { Transition } from '@headlessui/react';
import {BsArrowRight} from 'react-icons/bs';
import PhoneInput from 'react-phone-number-input';
import {FaFacebookF, FaTwitter, FaLinkedinIn} from 'react-icons/fa';
import ScheduleConsultation from '../ScheduleConsultation';
import WebFont from 'webfontloader';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import '../HomePage/App.css'

import { Helmet } from 'react-helmet';

function HomePage() {
    var validator = require('validator');

    // const [timerDays, setTimerDays] = useState("0");
    const [timerHours, setTimerHours] = useState("0");
    const [timerMinutes, setTimerMinutes] = useState("0");
    const [timerSeconds, setTimerSeconds] = useState("0");

    let interval = useRef();

    const startTimer = (countdownDate) => {
        const now = new Date().getTime();
        const distance = countdownDate - now;

        // const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            clearInterval(interval.current);
        } else {
        // setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
        }
    };


    useEffect(() => {
        WebFont.load({
            google: {
            families: ['Roboto Slab:200,300,400', 'Assistant:200,300,400,500,600,700,800',] // Fonts you want to load
            },
            custom: {
            families: ['Patua One'], // Optional: If you want to load a custom font, specify its name here
            urls: ['https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400&display=swap',
                    'https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&family=Patua+One&display=swap'] // URLs of the font CSS files
            }
        });

        //Check Cookies
        const newCookies =()=>{
            const currentTime = new Date();
            
            const nextDay = new Date();
            nextDay.setDate(currentTime.getDate() + 1);

            currentTime.setHours(currentTime.getHours() + 3);
            currentTime.setMinutes(currentTime.getMinutes() + 20);
            
            countdownDate = currentTime.getTime();
            document.cookie = `offerTime=${countdownDate}; expires=${nextDay}; path=/`;
        };
        const cookies = document.cookie.split("; ");
        let countdownDate;
        if (cookies.length > 0) {
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i].split("=");                
                if (cookie[0] === "offerTime") {
                    const cookieValue = cookie[1];
                    const currentTime = new Date(cookieValue).toLocaleString();
                    console.log(cookie[1], typeof(currentTime));
                    countdownDate = cookieValue;
                    break;
                } else {
                    newCookies();
                }
            }
        } else {
            newCookies()
        }
        interval.current = setInterval(() => {
            startTimer(+countdownDate);
        }, 1000);

        return () => clearInterval(interval.current);
    }, []);


  
    const [btnLoader, setBtnLoader] = useState(false);
    const [isThankYou, setIsThankYou] = useState(false);
    const [isError, setIsError] = useState(false);


    var salesBannerSlider = {
        dots: true,
        infinite: true,
        arrows:true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplaySpeed: 2000,
    };

    var firstSlider = {
        infinite:true, 
        speed:500,
        slidesToShow:3, 
        slidesToScroll:1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };


    var _ = require('lodash');

    const removeExtraSpace = (s) => {
       var rSpase = s.replace(/\s{2,}/g, ' ');
       return _.trimStart(rSpase);
    };

    let location = window.location.href;

    const quickContact = {
        name: "",
        email: "",
        phone: "",
        website: "",
        message: "",
        pageName: location,
    };


    const validate = values => {
        const errors = {};
    
        if (!values.name || !_.trim(values.name)) {
        errors.name = 'Name is required';
        } else if (values.name.length > 50) {
        errors.name = 'Must be 15 characters or less';
        } else if (values.name.match("[<>]")) {
        errors.name = 'Please provide a valid name';
        }
    
        if (!values.email ) {
        errors.email = 'Email is required';
        } else if ( !validator.isEmail(values.email)) {
        errors.email = 'Invalid email address';
        }

        if (!values.phone) {
            errors.phone = 'Phone number is required';
        } else if ( !validator.isMobilePhone(values.phone)) {
            errors.phone = 'Invalid phone number';
        }
        

        if (values.website) {
            if (!validator.isURL(values.website)) {
                errors.website = 'Invalid website address';
            } else if (values.website.match("[<>]")) {
                errors.website = 'Please provide a valid website';
            }
        } 

        if (!values.message || !_.trim(values.message)) {
            errors.message = 'Message is required';
        } else if (values.message.match("[<>]")) {
            errors.message = ' "<>" These are not allowed.';
        } 
    
        return errors;
    };

    function myGreeting() {
        setIsThankYou(false);
        setIsError(false);
    }

    const quickFormik = useFormik({
        initialValues: quickContact,
        validate,
        onSubmit: (values, {resetForm}) => {
            setBtnLoader(true);
            
            axios.post(`/quickContact`, values)
            .then(function (response) {
                resetForm({values: ""});
                setIsThankYou(true);
                
            })
            .catch(function (error) {
                setIsError(error?.message ? error.message: "Network Error")
            })
            .finally(() => {
                setBtnLoader(false)
                setTimeout(myGreeting, 10000);
            });
        },
    });



    const [scheduleModel, setScheduleModel] = useState(false);
    const HandelScheduleModel = () => {
        setScheduleModel(true)
    };

    const [messageModel, setMessageModel] = useState(false);
    const HandelMessageModel = () => {
        setMessageModel(true)
    };

    const toggleModal = () => {
        setScheduleModel(false);
        setMessageModel(false)
    };

    const TestimonialTwo = [
        {
            "url": "dajz-iEApdo",
            "text": "I have been working on a “pet” project for quite some time now. It’s..."
        },
        {
            "url": "i-byBdoTZ6Y",
            "text": "I have been in the online business for a very long time now. I just wanted..."
        },
        {
            "url": "aD76eU82KUw",
            "text": "I have a company called Benefit Personal Training and we have been working..."
        },
        {
            "url": "riUi3czB8C0",
            "text": "They have done advanced software programs for me to run my multiple..."
        }
    ]


  return (
    <section className='sales-page'>
        <Helmet>
                   
            <script id="facebook-pixel-script">
                {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js'),
                fbq('init', '273096641131718'),
                fbq('track', 'PageView')
                `}
            </script>
            <noscript id="facebook-pixel-image">
                {`
                    <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=273096641131718&ev=PageView&noscript=1"
                />
                `}
                
            </noscript>
        </Helmet>
        <section className='container'>

            <div className='header-part'>
                <a className='logo' href='/'>
                    <img src={HeaderLogo} alt='Reddensoft logo'/>
                </a>



             <div className='header_right'>
                

                <a className='mail-box' href='mailto:info@reddensoft.com'>
                    <span>
                        <img src={MailIcon} alt='Reddensoft email'/>
                    </span>
                    <span>
                        info@reddensoft.com
                    </span>
                    
                </a>

                <a onClick={(e) => HandelMessageModel(e)} className='header-gradeint-btn'>
                    
                    <span>
                        Reach Us
                    </span>
                    
                </a>


                </div>
            </div>

            <div className='banner-part'>
                
                <h1>
                    Cutting-edge Web/mobile app
                    <span> development, Website design </span> and
                    Digital Marketing Services that
                    Uplifts your Brand Image
                </h1>

                <p className='banner-description'>
                    Reddensoft is an all-in-one digital agency that follows breakthrough technology to deliver promising output. You can achieve DIGITAL success for your business. Reddensoft can help you set the STANDARD HIGH without settling for less. We will not bring another bland website or exaggerate a clueless digital marketing strategy to the table. Instead, our experts will perform to help you tap into the benefits of growing your business ONLINE.
                </p>

                <button onClick={(e) => HandelMessageModel(e)} className=' btn-outline'>Let’s work together</button>

                <div className='review-box'>
                    <div>
                        <h5>Reviewed by 275 clients</h5>
                    </div>
                    <div className='arrow-image'>
                        <img src={ArrowOne} alt=''/>
                    </div>

                    <div className='star-box'>
                        <div>
                            <img src={ReviewStart} alt=''/>
                        </div>
                        <p>4.9 out of 5</p>
                    </div>
                </div>
            </div>


            <div className='team-section'>
                    <div>
                        <div className='first-team-section'>
                            <div className='first-team-image'>
                                <img src={salesTeamImage} alt="reddensoft team"/>
                            </div>
                            <div className='first-team-text'>
                                <h4>
                                    Necessities that <br/>
                                    <span> You Need</span> to fill
                                </h4>

                                <ul>
                                    <li>
                                        Do you own a startup that needs a simple yet responsive website
                                        that works best in today’s online marketing climate?
                                    </li>
                                    <li>
                                        Do you already have a business website and want to take it to the
                                        next level but don’t have a certain budget?
                                    </li>
                                    <li>
                                        Do you want to have a freshly minted e-commerce website for your
                                        business so that you can get more buyers globally?
                                    </li>
                                    <li>
                                        Your existing website is not getting enough traction like your
                                        competitors?
                                    </li>
                              </ul>
                              <p>If these are issues, you must think again about your blueprint to grow your business ONLINE. A simple website with robust digital marketing strategies can be the brain and brawn to turn your business into a perpetual profit center.</p>

                                <button onClick={(e) => HandelScheduleModel(e)} className='btn-outline btn-outline-blue'>Let me Talk to Expert</button>
                            </div>
                        </div>

                    </div>
                    
            </div>


        </section>

        <section className='services-section'>
            <div className='container'>
                <h1>
                    Streamlined <span> Digital Services </span> to Change the Online Fate of Your Business
                </h1>

                <div>
                    <div className='services-row'>
                        <div className='services-image'>
                            <img src={salesServicesOne} alt=''/>
                        </div>
                        <div className='services-text'>
                            <h4>
                                Website development services
                            </h4>
                            <p>
                                We specialize in delivering exceptional web development services that ensure your business website runs smoothly and efficiently. Our team is dedicated to creating a responsive website that meets your needs and exceeds your expectations. Take your business to a new height and give yourself an unfair advantage. Also, you can revamp your existing website by making it more engaging. Our developers are always ready to make it possible as per the market needs.
                            </p>
                        </div>
                    </div>

                    <div className='services-row'>
                        <div className='services-image'>
                            <img src={salesServicesTwo} alt=''/>
                        </div>
                        <div className='services-text'>
                            <h4>
                            Web design services
                            </h4>

                            <p>
                            The look of your website matters to establish a connection between you and your audience. Get the satisfaction of reaching your target audience with the enticing look and feel our dedicated web designing team created. Experience the superior detailing that compels the visitors to take action.
                            </p>
                        </div>
                    </div>

                    <div className='services-row'>
                        <div className='services-image'>
                            <img src={salesServicesThree} alt=''/>
                        </div>
                        <div className='services-text'>
                            <h4>
                            App development services
                            </h4>

                            <p>
                                A mobile app for your business is a digital identity that simplifies the custom checkout process and strengthens the relationship between you and your customers. Our full-stack app development team is always ready to go that extra mile just to maintain your business image by delivering robust mobile applications. Reinforce your brand image with our standards of app development services.
                            </p>
                        </div>
                    </div>

                    <div className='services-row'>
                        <div className='services-image'>
                            <img src={salesServicesFour} alt=''/>
                        </div>
                        <div className='services-text'>
                            <h4>
                            Digital marketing services
                            </h4>
                            <p>
                            Tired of hearing #1 Google ranking in as little as 7 days? Nope, we won’t bombard you with any verbose description that fails to show its potential. Instead, our digital marketing mastermind team will evaluate the situation for a given climate. Next, they will understand the picture and propose a blueprint that aligns best with your client’s brand image and mission/vision. We will work proactively to outperform the competitors while maximizing your conversions within a stipulated time(but not as early as 7 days!). We provide SEO-friendly websites with optimized landing pages, whether you're building from scratch or making modifications. Our websites are content-driven and functionally designed for maximum effectiveness.
                            </p>
                        </div>
                    </div>
                </div>

            
            </div>
        </section>

        <section className='container'>


            <div className='second-section'>
                <div className='curcel-Arrow'>
                    <img src={curcelArrow} alt=''/>
                </div>
                <div className='ribbon-section'>
                    <p>Limited Period </p>
                    <h3>Offer </h3>
                </div>

                <div className='gradeint-box'>
                    <h2>
                        Reddensoft is <br/>offering  <span>great deal</span><br/> for the clients.
                    </h2>

                    <p>
                        If you have any web development <b>project</b> in mind, work with our professionals
                    </p>
                </div>

                <div className='offer-box'>
                    <h5>Hire a team or individual and</h5>
                    <h4>Get</h4>
                    <h3>30%<span>off</span></h3>
                    <h6>on the total cost</h6>
                    <button onClick={(e) => HandelMessageModel(e)} className='btn-outline btn-outline-blue'>I want to Start Now</button>

                    <div className='time-count-box'>
                        <p>OFFER ENDING SOON</p>
                        <div className='time-count'>
                              <h3>
                                  <span>
                                      <span>{timerHours < 10 ? '0' : ''}{timerHours}</span>
                                      <span className='text'>Hour</span>
                                  </span> :
                                  <span>
                                      <span>{timerMinutes < 10 ? '0' : ''}{timerMinutes}</span>
                                      <span className='text'>Minutes</span>
                                  </span> :
                                  <span>
                                     <span> {timerSeconds < 10 ? '0' : ''}{timerSeconds}</span>
                                      <span className='text'>Seconds</span>
                                  </span>
                              </h3>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className='slider-section'>
                <h3>Recent Projects</h3>

                <div className='slider-image'>
                    <Slider {...salesBannerSlider}>
                        <img src={PortOne} alt=''/>
                        <img src={PortTwo} alt=''/>
                        <img src={PortThree} alt=''/>
                        <img src={PortFive} alt=''/>
                        <img src={PortSix} alt=''/>
                        <img src={PortThree} alt=''/>
                    </Slider>
                </div>
            </div>


            <div className='price-section'>
                <h3 style={{textAlign: "center"}}>
                    How can <span className='gredint-text'> 
                    <span> Reddensoft </span></span> help <br/>
                    <span>your Business?</span>
                </h3>

                <div className='price-table'>
                    <div className=''>
                        <div className='table-header'>
                            <div className='table-col'>
                                Services
                            </div>
                            <div className='table-col'>
                                <div className='table-logo'>
                                    <img src={HeaderLogo} alt='reddensoft logo' />
                                </div>
                                We Provide
                            </div>
                            <div className='table-col'>
                            <span> Others so called providers</span>
                            </div>
                        </div>

                        <div className='table-body'>
                            <div className='table-row'>
                                <div className='table-row-inner'>
                                    <div className='table-col' data-label="Services">
                                        <span>24*7</span> dedicated <span>support</span> from the experts
                                    </div>
                                    <div className='table-col' data-label="We Provide">
                                        <b> YES</b>
                                    </div>
                                    <div className='table-col' data-label="Others so called providers">
                                        <b> NO</b>
                                    </div>

                                </div>
                            </div>

                            <div className='table-row'>
                                <div className='table-row-inner'>
                                    <div className='table-col' data-label="Services">
                                        Preparing a <span> team based </span> on your project scope
                                    </div>
                                    <div className='table-col' data-label="We Provide">
                                        <b> YES</b>
                                    </div>
                                    <div className='table-col' data-label="Others so called providers">
                                        <b> NO</b>
                                    </div>

                                </div>
                            </div>

                            <div className='table-row'>
                                <div className='table-row-inner'>
                                    <div className='table-col' data-label="Services">
                                    Dedicated support of <span> team manager</span>
                                    </div>
                                    <div className='table-col' data-label="We Provide">
                                        <b> YES</b>
                                    </div>
                                    <div className='table-col' data-label="Others so called providers">
                                        <b> NO</b>
                                    </div>

                                </div>
                            </div>

                            <div className='table-row'>
                                <div className='table-row-inner'>
                                    <div className='table-col' data-label="Services">
                                    Latest <span>technologies</span> followed 
                                    </div>
                                    <div className='table-col' data-label="We Provide">
                                        <b> YES</b>
                                    </div>
                                    <div className='table-col' data-label="Others so called providers">
                                        <b> NO</b>
                                    </div>

                                </div>
                            </div>

                            <div className='table-row'>
                                <div className='table-row-inner'>
                                    <div className='table-col' data-label="Services">
                                        <span> No hidden </span> code
                                    </div>
                                    <div className='table-col' data-label="We Provide">
                                        <b> YES</b>
                                    </div>
                                    <div className='table-col' data-label="Others so called providers">
                                        <b> NO</b>
                                    </div>

                                </div>
                            </div>

                            <div className='table-row'>
                                <div className='table-row-inner'>
                                    <div className='table-col' data-label="Services">
                                        <span> On-time </span> delivery
                                    </div>
                                    <div className='table-col' data-label="We Provide">
                                        <b> YES</b>
                                    </div>
                                    <div className='table-col' data-label="Others so called providers">
                                        <b> NO</b>
                                    </div>

                                </div>
                            </div>

                            <div className='table-row'>
                                <div className='table-row-inner'>
                                    <div className='table-col' data-label="Services">
                                        Constant <span>following up </span> after post-sales
                                    </div>
                                    <div className='table-col' data-label="We Provide">
                                        <b> YES</b>
                                    </div>
                                    <div className='table-col' data-label="Others so called providers">
                                        <b> NO</b>
                                    </div>

                                </div>
                            </div>

                            <div className='table-row'>
                                <div className='table-row-inner'>
                                    <div className='table-col' data-label="Services">
                                        Get design <span>source files </span>
                                    </div>
                                    <div className='table-col' data-label="We Provide">
                                        <b> YES</b>
                                    </div>
                                    <div className='table-col' data-label="Others so called providers">
                                        <b> NO</b>
                                    </div>

                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div className='team-section'>
                <div>

                    <div className='first-team-section'>
                        <div className='first-team-image'>
                            <img src={salesTeamImagethree} alt="reddensoft team"/>
                        </div>
                        <div className='first-team-text'>
                            <h4>
                                Leverage the
                                <span> Benefits </span> of Utmost
                                Flexibility
                            </h4>

                            <p>
                                If you are unsatisfied with the progress, you can switch between resources that assist you in achieving the best outcome throughout the project lifecycle.
                            </p>

                            <button onClick={(e) => HandelMessageModel(e)} className='btn-outline btn-outline-blue'>Learn More</button>
                        </div>
                    </div>
                </div>
                
                
                <section  id='sales-video-testmonial' className="section_one testmonial-section">
                
                    <div className='testmonial-top'>
                        <p>Why people love working with Reddensoft</p>
                        <h4>Our Clients <span className='text-grad'>
                        <span> Get Results </span>
                        </span></h4>
                    </div>
                    
                    <Slider  {...firstSlider}>

                        {TestimonialTwo?.length > 0 && TestimonialTwo.map((items, i) => {
                            return (
                                <div key={i} className="item">
                                    <div className="inn fadeInUp">
                                        <div className="top_img">
                                        
                                            <LiteYouTubeEmbed 
                                                id={items.url}
                                                title={items.text}
                                            />
                                        </div>
                                        <p>{items.text}</p>
                                    </div>
                                </div>
                            )
                        })}

                    </Slider>
                    

                </section>

                
            </div>

            <div className='contact-section'>
                <div className='top-part'>
                    <div className='text-arrow-bg'>
                        <img src={textArrowBg} alt=''/>
                    </div>
                    <p>Choose your team</p>
                    <h4>What type of <span>team structure </span><br/> are you looking for?</h4>
                    <h6>
                        Grab the opportunity to make it real with expert help
                    </h6>
                </div>
            </div>
            </section>

            <section className='contact-box'>
            <div className='container'>
                <div className='contact-flex'>
                    <div className='contact-heading'>
                        <div className='contact-heading-text'>
                            <span>Send Us</span>
                            Your Requirement
                            <img src={salesContactArrow} alt=''/>
                        </div>
                    
                    </div>
                    <div className='contact-form-box'>
                        
                        <form onSubmit={quickFormik.handleSubmit} id="quickFormik">
                            <div className="first-row">
                                    
                                <div className="form-group">
                                    <label className='form-label'>Name*</label>
                                    <input type="text" className='form-control' onChange={quickFormik.handleChange} autoCapitalize="none" autoComplete="new-password"
                                        value={removeExtraSpace(quickFormik.values.name)} onBlur={quickFormik.handleBlur} name="name" placeholder="Enter your name"/>
                                        {quickFormik.touched.name && quickFormik.errors.name ? <div style={{color: '#ff0000'}}>{quickFormik.errors.name}</div> : null}
                                </div>

                                <div className="form-group">
                                    <label className='form-label'>Phone*</label>
                                    <PhoneInput 
                                        name="phone"
                                        placeholder="Enter phone number"
                                        international
                                        defaultCountry="US"
                                        countryCallingCodeEditable={false}
                                        value={quickFormik.values.phone}
                                        onBlur={quickFormik.handleBlur}
                                        autoCapitalize="none" autoComplete="new-password"
                                        onChange={(val) => quickFormik.setFieldValue('phone', val)}/>

                                    
                                        {quickFormik.touched.phone && quickFormik.errors.phone ? <div style={{color: '#ff0000'}}>{quickFormik.errors.phone}</div> : null}
                                </div>
                            </div>  

                                <div className="form-group">
                                    <label  className='form-label'>Email*</label>
                                    <input className='form-control' onChange={quickFormik.handleChange} autoCapitalize="none" autoComplete="new-password"
                                        value={removeExtraSpace(quickFormik.values.email)} onBlur={quickFormik.handleBlur} type="email" name="email" placeholder="Enter your email" />
                                        {quickFormik.touched.email && quickFormik.errors.email ? <div style={{color: '#ff0000'}}>{quickFormik.errors.email}</div> : null}
                                </div>

                                <div className="form-group">
                                    <label  className='form-label'>Message*</label>
                                    <textarea rows={4} className='form-control' name="message" onChange={quickFormik.handleChange}
                                        value={removeExtraSpace(quickFormik.values.message)} onBlur={quickFormik.handleBlur}></textarea>
                                        {quickFormik.touched.message && quickFormik.errors.message ? <div style={{color: '#ff0000'}}>{quickFormik.errors.message}</div> : null}
                                </div>

                            
                                <div className="buttom-sec">
                                    
                                    <button type="submit" className="btn-outline">
                                        <span>Get me a quote</span>
                                        {btnLoader ? (
                                            <span className='btn-ring-animation'></span>
                                        ):(
                                            <BsArrowRight />
                                        )}
                                        
                                        
                                    </button>
                                    
                                </div>
                                
                        </form>
                    
                        {isThankYou && (
                            <div className='alert alert-success mt-3'>
                                Thank you for getting in touch! 
                            </div>
                        )}
                        {isError && (
                            <div className='alert alert-error mt-3'>
                                {isError} 
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <img className='contact-bg' src={contactBg} alt=''/>
                
            </section>

            <section className="confused-section">
            <div className='container'>
                <h4>Confused? <span>How to build team?</span></h4>
                <p>
                    Consult to our Project managers
                </p>
                <div className='btn-group'>
                    <button onClick={(e) => HandelScheduleModel(e)} className='btn-outline btn-blue'>
                        Let's Talk!
                    </button>
                    <button onClick={(e) => HandelMessageModel(e)} className='btn-outline '>
                        Send Message
                    </button>
                </div>
            </div>
            </section>

            <section className='testmonial-section'>
            <div className='container'>
                <div className='testmonial-top'>
                    <p>Our Testimonials</p>
                    <h4>We are <span className='text-grad'>
                    <span> proud to inspire </span>
                    </span></h4>
                </div>

                <div className='testmonial-grid'>
                    <div className='testmonial-box'>
                        <div className='testmonial-user'>
                            <div className='testmonial-user-image'>
                                <img src={David} alt='David Miller'/>
                            </div>

                            <div className='testmonial-user-details'>
                                <div>
                                    <h4>David Miller</h4>
                                    <h6>Business Man</h6>
                                </div>
                                {/* <a href='' className='twitter-icon' target='_blank'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                    </svg>
                                </a> */}
                            </div>
                        </div>

                        <p className='des'>
                        “I'm David and I've been in the online business for a very long time and I tried lots of different suppliers around the world and done the reefs I'm speaking today is that I just wanted to give a shout out to a team I've been using who I like very much they call ReddenSoft. They are great on app development. They're very good on website development.”
                        </p>
                    </div>

                    <div className='testmonial-box'>
                        <div className='testmonial-user'>
                            <div className='testmonial-user-image'>
                                <img src={Michel} alt=''/>
                            </div>

                            <div className='testmonial-user-details'>
                                <div>
                                    <h4>Michel Ambrosio</h4>
                                    <h6>Founder @Corkle</h6>
                                </div>
                                {/* <a href='' className='twitter-icon' target='_blank'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                    </svg>
                                </a> */}
                            </div>
                        </div>

                        <p className='des'>
                        “Hi, my name is Michel Ambrosio. I'm the owner of a site called quirkel.com is a service that you use to back up your websites and databases. Now the reason I'm telling you this because I'm really looking to tell you about a company called ReddenSoft. ReddenSoft is the development and programming company that I used corkle built.”
                        </p>
                    </div>
                    <div className='testmonial-box'>
                        <div className='testmonial-user'>
                            <div className='testmonial-user-image'>
                                <img src={Bently} alt=''/>
                            </div>

                            <div className='testmonial-user-details'>
                                <div>
                                    <h4>Bently Ritter</h4>
                                    <h6>President & CEO at BeneFit Personal Training</h6>
                                </div>
                                {/* <a href='' className='twitter-icon' target='_blank'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                    </svg>
                                </a> */}
                            </div>
                        </div>

                        <p className='des'>
                        “Hi, my name is Bently Ritter. I have a company called Benefit Personal Training and we have been working with Dipak Tewary and his team at ReddenSoft for several years. They helped us develop our website benefitpersonaltraining.com as well as multiple promotional pieces. Everything they do looks great. It's done in a very timely manner and his prices are fair. So, I would absolutely recommend and his team at ReddenSoft for all of your company needs.”
                        </p>
                    </div>
                    <div className='testmonial-box'>
                        <div className='testmonial-user'>
                            <div className='testmonial-user-image'>
                                <img src={Kimberly} alt=''/>
                            </div>

                            <div className='testmonial-user-details'>
                                <div>
                                    <h4>Kimberly</h4>
                                    <h6>Entrepreneur</h6>
                                </div>
                                {/* <a href='' className='twitter-icon' target='_blank'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                    </svg>
                                </a> */}
                            </div>
                        </div>

                        <p className='des'>
                        “Hi, my name is Kimberly and as a business owner it is most important to obviously have a user-friendly website that has great design some of the most modern and then it's also important to be able to drive traffic to that website. I can tell you that I've worked with Reddensoft for over 10 years now. The whole team there including their leader Dipak Tewary have successfully managed to design seven wonderful websites for me.”
                        </p>
                    </div>
                
                </div>



            </div>
            </section>

        <section className="sales-footer-section">
            <div className='container'>
                <ul className='social-media'>
                    <li>
                        <a rel="noreferrer" href="https://www.facebook.com/Reddensoft/" target="_blank" className="fadeInUp animated">
                            <FaFacebookF size={30}/>
                        </a> 
                    </li>
                    <li>
                        <a rel="noreferrer" href="https://twitter.com/reddensoft" target="_blank" className="fadeInUp animated">
                            <FaTwitter size={30}/>
                        </a> 
                    </li>
                    <li>
                        <a rel="noreferrer" href="https://www.linkedin.com/company/reddensoft" target="_blank">
                            <FaLinkedinIn size={30}/>
                        </a> 
                    </li>
                    
                </ul>
                <div className='footer-logo'>
                    <img src={HeaderLogo} alt='reddensoft logo' />
                </div>
                <p>
                    Copyright @ 2023 reddensoft. <br/>
                    All Rights Reserved.
                </p>
            </div>
        </section>

        <Transition 
            show={messageModel}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal">
            <ScheduleConsultation isSchedule={false} toggleModal={toggleModal}/>
        
        </Transition>
       
         <Transition 
            show={scheduleModel}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal">
            <ScheduleConsultation isSchedule={true} toggleModal={toggleModal}/>
        
        </Transition>
    </section>
  );
}

export default HomePage;
