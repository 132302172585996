import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../Components/HomePage/Index';
import Package from '../Components/Package';
import FullstackDevelope from '../Components/FullstackDevelope';
import UiUx from '../Components/UiUx';
import ThankYou from '../Components/ThankYou';
import HireDesigner from '../Components/HireDesigner';
import FullStackLanding from '../Components/LandingFullstack';
import BackEndDeveloper from '../Components/BackEndDeveloper';
import FrontEndDeveloper from '../Components/FrontEndDeveloper';
import HireWebDeveloper from '../Components/HireWebDeveloper';
import HireBestDesigner from '../Components/HireBestDesigner';
import HireDeveloper from '../Components/HireDeveloper';
import HireDuda from '../Components/HireDuda';
import NewHomePage from '../Components/HomePage/NewHome';
import PackageIndia from '../Components/FullstackDevelopeIndia';
import FullStackLandingIndia from '../Components/LandingFullstackIndia';
import HireDesignerIndia from '../Components/HireDesignerIndia';
import BestdesignIndia from '../Components/HireBestDesignerIndia';
import NewHomePageTwo from '../Components/HomePageTwo/NewHome';
import WebDesignDevelopmentIndia from '../Components/WebDesignDevelopmentIndia';

 
export default function Router() {
  return (
    <Routes>
      
      <Route path="/" element={<NewHomePage />}/>
      <Route path="/home" element={<NewHomePageTwo />}/>
      <Route path="/old-home-page" element={<HomePage />}/>
      <Route path="/unlimited-graphic-design-packages" element={<Package />}/>
      <Route path="/hire-full-stack-developer" element={<FullstackDevelope />}/>
      <Route path="/full-time-Ui-UX-designer" element={<UiUx />}/>
      <Route path="/thank-you" element={<ThankYou />}/>
      <Route path="/hire-web-developer" element={<HireWebDeveloper/>}/>
      <Route path="/hire-front-end-developer" element={<FrontEndDeveloper/>}/>
      <Route path="/hire-back-end-developer" element={<BackEndDeveloper/>}/>
      <Route path="/hire-duda-specialist" element={<HireDuda />}/>

      <Route path="/freelance-full-stack-developers-of-india" element={<PackageIndia />}/>
      <Route path="/best-web-development-company-in-india" element={<FullStackLandingIndia />}/>
      <Route path="/web-design-and-development-price-in-india" element={<WebDesignDevelopmentIndia />}/>
      <Route path="/best-ui-ux-design-company-in-india" element={<HireDesignerIndia />}/>
      <Route path="/best-website-design-company-in-india" element={<BestdesignIndia />}/>
      

      /**************************** sumit pages ******************************/

      <Route path="/hire-ui-ux-designer" element={<HireDesigner />}/>
      <Route path="/hire-full-stack-developer-2" element={<FullStackLanding />}/>
      <Route path="/hire-website-designer" element={<HireBestDesigner />}/>
      <Route path="/hire-developer" element={<HireDeveloper />}/>

    </Routes>
  )
}
