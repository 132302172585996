import React, { useState, useEffect, useRef } from "react";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import {
  HeaderLogo,
  FooterLogo,
  Bannerbtmlogo1,
  Bannerbtmlogo2,
  Bannerbtmlogo3,
  Designerreview1,
  Designersinglereview,
  Designerreview2,
  Designerreview4,
  Designerreview3,
  Bannerbtmlogo5,
  batchone,
  batchtwo,
  designarrow,hireiconone,hireicontwo,hireiconthree,hireiconfour,batchthree, ExpertUIUX6, ExpertUIUX1, ExpertUIUX2, ExpertUIUX3, ExpertUIUX4, ExpertUIUX5, 
} from "../../Assets/index";

import { Transition } from "@headlessui/react";
import ScheduleConsultation from "../ScheduleConsultation";
import WebFont from "webfontloader";
import "./App.css";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import axios from "axios";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import CaseStudiesModal from "../CaseStudiesModal";

import { CSSTransition } from 'react-transition-group';
import { Link, useNavigate } from "react-router-dom";


function HireDesigner() {
  var _ = require('lodash');

  const videoslider = {
    infinite:true, 
    speed:500,
    arrows: false,
    dots: false,
    autoplay: true,
    slidesToShow:4, 
    slidesToScroll:1,
    responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow:1
          }
        }
    ]
  };


  const uiUxSlider = {
    infinite:true, 
    fade: true,
    speed:500,
    arrows: true,
    dots: false,
    autoplay: true,
    slidesToShow:1, 
    slidesToScroll:1,
    
  };



  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Roboto Slab:200,300,400",
          "Montserrat:300,400,500,700",
          "Patua One",
          "Mulish",
        ], // Fonts you want to load
      },
      custom: {
        families: ["Patua One"], // Optional: If you want to load a custom font, specify its name here
        urls: [
          "https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400&display=swap",
          "https://fonts.googleapis.com/css2?family=Montserrat:wght@300,400;500;700&display=swap",
          "https://fonts.googleapis.com/css2?family=Mulish&family=Patua+One&display=swap",
        ], // URLs of the font CSS files
      },
    });

  }, []);

  const [scheduleModel, setScheduleModel] = useState(false);
  const [messageModel, setMessageModel] = useState(false);
  const [isTry, setIsTry] = useState();
  const [ isStartFreeTrial, setIsStartFreeTrial ] = useState(false);

  const HandelMessageModel = (e, val) => {
    if (val) {
      setIsTry(val);
    } else {
      setIsTry();
    }

    if(val === 'Try Free for 15 Days'){
      setIsStartFreeTrial(true);
    }else{
      setIsStartFreeTrial(false);
    }

    setMessageModel(true);
  };

  const toggleModal = () => {
    setScheduleModel(false);
    setMessageModel(false);
    setIsCaseStudies(false);
  };


  // accordian
  const [activeIndex, setActiveIndex] = useState(0);
  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  const accordionItems = [
    {
      id: 1,
      title: "What is the cost of hiring a UI UX designer?",
      content: "Hire our top-of-the-line UI UX designers to work on your project for a rate as low as 20$ per hour. ",
    },
    {
      id: 2,
      title: "How do you approach a UI UX design project?",
      content: "We have based our approach to UI UX design on four main phases, namely, research, design, testing, and launch.",
    },
    {
      id: 3,
      title: "How long does UI UX design take?",
      content: "The duration of UI UX design project can range anywhere between 2 weeks and 6 months.",
    },

    {
      id: 4,
      title: "What are the benefits that I can gain?",
      content: "Save money and time, increased user satisfaction, and an enhanced brand image and reputation. ",
    },
    {
      id: 5,
      title: "How to estimate the total cost of the project?",
      content: "The price of a UI UX design project depends on:",
      list: [
       'Scope and complexity of the project',
      'The timeline and deadline of the project',
        'Designer experience and expertise'

      ]
    },
  ];


  const [isThankYouTwo, setIsThankYouTwo] = useState(false);
  const [isErrorTwo, setIsErrorTwo] = useState(false);
  const [btnLoaderTwo, setBtnLoaderTwo] = useState(false);

  function myGreeting() {
    setIsThankYouTwo(false);
    setIsErrorTwo(false);
  }

  const [guidelineModal, setGuidelineModal] = useState(false);
  const [isResponsiveMenu, setIsResponsiveMenu] = useState(false);

  const pricing = useRef(null);
  const faqMenu = useRef(null);
  const testmonialsMenu = useRef(null);
  const homeMenu = useRef(null);

  const HandelGuidelineModal = () => {
      setGuidelineModal(!guidelineModal)
  }

  const navigate = useNavigate();

  const HandelScheduleModel = () => {
    setScheduleModel(true)
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


 
  const dropdownRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsResponsiveMenu(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const portFolio = useRef(null);

  async function PortfolioApi() {
      axios.get('/portfoliodata')
    .then(await function  (response) {
        if(response && response?.data && response?.data?.data){
            setPortData(response?.data?.data)
        }
    })
    .catch(await function (error) {
        console.log(error)
    })
    .finally(() => {
      console.log('Experiment completed');
      setIsLoader(false);
    }); 
  };

  useEffect(() => {
    setIsLoader(true);
    PortfolioApi()
 
  }, []);

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [portData, setPortData] = useState();
  const [isLoader, setIsLoader] = useState(false);

  var projectSliderNav = {
    asNavFor:nav1, 
    ref: (slider2) => setNav2(slider2),
    slidesToShow:5,
    slidesToScroll: 1,
    autoplay: true,
    arrows:false,
    speed: 1000,
    autoplaySpeed: 3000,
    swipeToSlide:true,
    focusOnSelect:true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          }
        },
        {
            breakpoint: 500,
            settings: {
              slidesToShow: 2,
            }
          }
    ]
  };

  var projectSlider = {
      asNavFor: nav2,
      ref:(slider1) => setNav1(slider1),
      fade:true,
      arrows:false,
  };

  const [isCaseStudies, setIsCaseStudies] = useState(false);

  const HandelCaseStudiesModel = () => {
    setIsCaseStudies(true);
  }


  const videos = [
    {
      id: "dajz-iEApdo",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "I have been working on a 'pet' project for quite some time now. It’s...",
    },
    {
      id: "i-byBdoTZ6Y",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "I have been in the online business for a very long time now. I just wanted...",
    },
    {
      id: "aD76eU82KUw",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "I have a company called Benefit Personal Training and we have been working...",
    },
    {
      id: "riUi3czB8C0",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "They have done advanced software programs for me to run my multiple...",
    },
    {
      id: "dajz-iEApdo",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "I have been working on a 'pet' project for quite some time now. It’s...",
    },
    {
      id: "i-byBdoTZ6Y",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "I have been in the online business for a very long time now. I just wanted...",
    },
    {
      id: "aD76eU82KUw",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "I have a company called Benefit Personal Training and we have been working...",
    },
    {
      id: "riUi3czB8C0",
      title: "What’s new in Material Design for the web (Chrome Dev Summit 2019)",
      description: "They have done advanced software programs for me to run my multiple...",
    },
    
  ];


  const Modalref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsResponsiveMenu(false);
      }

      if (Modalref.current && !Modalref.current.contains(event.target)) {
        setGuidelineModal(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };

  }, []);


  const [openVideoModal, setVideoModal] = useState(false);
  const [currentvideoId, setCurrentvideoId] = useState('riUi3czB8C0');

  const HandelYoutubeVideo = (val) => {
    console.log(val);
    setCurrentvideoId(val);
    setVideoModal(!openVideoModal);
  }

  return (
    <section className="sales-page graphics hair-designer" ref={homeMenu}>
      <Helmet>
        <title>Hire A Pioneer in UI & UX Design.</title>
        <script id="facebook-pixel-script">
          {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js'),
                fbq('init', '273096641131718'),
                fbq('track', 'PageView')
                `}
        </script>
        <noscript id="facebook-pixel-image">
          {`
                    <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=273096641131718&ev=PageView&noscript=1"
                />
                `}
        </noscript>
      </Helmet>

      <section className="banner">

        <div className={` header-section ${scrollPosition > 50 ? "fixed-header slide-in" : ""}`}>
          <div className="container">
            <div className="header-part">
              <Link className="logo" onClick={() => homeMenu.current?.scrollIntoView({behavior: 'smooth'})}>
                <img src={HeaderLogo} alt="Reddensoft logo" />
              </Link>
              <div ref={dropdownRef} className={` ${isResponsiveMenu ? 'active':''}  header_middle`}>
                <ul>
                  <li><a onClick={() => {portFolio.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Our Portfolio</a></li>
                  <li><a onClick={() => {pricing.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Pricing</a></li>
                  <li><a onClick={() => {testmonialsMenu.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Testimonial</a></li>
                  <li><a onClick={() => {faqMenu.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">FAQs</a></li>
                </ul>
              </div>
              <div className="header_right">
                <a onClick={(e) => HandelMessageModel(e,'Try Free for 15 Days')} className="full-stack-top-btn">
                  <span>Try Free for 15 Days</span>
                </a>

                <a onClick={(e) => HandelScheduleModel(e, 'schedule a call')} className="full-stack-top-btn fill-btn mobile-none">
                  <span>Schedule a call</span>
                </a>
                <button className="bars-menu" ref={dropdownRef} onClick={() => setIsResponsiveMenu(!isResponsiveMenu)}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={22} height={22}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="banner-section">
            <div className="banner-left designer_div">
              <h4> A Pioneer in <span> UI/UX </span> Design</h4>
              <h6>Build your team with passionate and experienced UI/UX Designers for Mobile & Web Applications starting from <span>$20.00 /hour</span></h6>
              

              <div className="banner-feture banner_featur_width">
                <div className="banner-first-feture">
                  <p>Get 15 Days Free Trial</p>
                </div>

                <div className="banner-second-feture">
                  <p>Quick Team Set-up & Onboarding</p>
                </div>

                <div className="banner-first-feture">
                  <p>No Expense on Retaining & Training</p>
                </div>

                <div className="banner-second-feture">
                  <p>IP Security and Ownership</p>
                </div>

                <div className="banner-first-feture">
                  <p>Quick Product Delivery</p>
                </div>

                <div className="banner-second-feture">
                  <p>
                    Dedicated Project Manager
                    <span className="image-text">Free</span>
                  </p>
                </div>
              </div>

              <button className="hair-designer-btn" onClick={(e) => HandelMessageModel(e, 'Hire UI & UX Designer')}>Hire UI & UX Designer</button>
              <button className="hair-designer-btn" onClick={(e) => HandelMessageModel(e, 'SCHEDULE A CALL')}>SCHEDULE A CALL</button>
              
              <div className="hiredesigner_batch">
                <div className="hiredesigner_batchsub">
                  <img src={batchone} alt="" />
                </div>
                <div className="hiredesigner_batchsub">
                  <img src={batchthree} alt="" />
                </div>
                <div className="hiredesigner_batchsub">
                  <img src={batchtwo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='hiredesigner_client'>
          <p class="banner-location">Working with Clients across <span>  &nbsp; <img src={designarrow} alt="" /> &nbsp; 
          California &nbsp;|&nbsp; Texas  &nbsp;|&nbsp; Illinois &nbsp;|&nbsp; Florida &nbsp;|&nbsp; New York 
                  &nbsp;|&nbsp; Toronto &nbsp;|&nbsp; Montreal &nbsp;|&nbsp; Vancouver &nbsp;|&nbsp; Calgary &nbsp;|&nbsp; Mexico City &nbsp;|&nbsp; World Wide
          </span></p>
        </div>

        <div className="hair_designer_bannerbtm_boeder">
          <div className="hair_designer_bannerbtm">
            <div className="hair_designer_bannerbtm_box">
              <img src={Bannerbtmlogo5} alt="" />
              <h3>We are rated 4.4 out of 5 on  average across 125 reviews</h3>
            </div>
            <div className="hair_designer_bannerbtm_box">
              <img src={Bannerbtmlogo1} alt="" />
              <h3>We are rated 4.5 out of 5 on  average across 120 reviews</h3>
            </div>
            <div className="hair_designer_bannerbtm_box">
              <img src={Bannerbtmlogo2} alt="" />
              <h3>We are rated 4.5 out of 5 on  average across 100 reviews</h3>
            </div>
            <div className="hair_designer_bannerbtm_box">
              <img src={Bannerbtmlogo3} alt="" />
                <h3>We are rated 4.0 out of 5 on  average across 110 reviews</h3>
            </div>
          </div>
        </div>
  
      </section>

      <section className="Cutting_section">
        <div className="container">
          <div className="Cutting_divide">
            <div className="cutting_text">
              <h2>Cutting-edge <span>Tools Used</span> by our Designers </h2>
              <ul>
                <li><span>UI/UX -</span> Sketch, Adobe XD, Photoshop, Illustrator & Figma</li>
                <li><span>Prototyping -</span> Invision Studio, Adobe XD, Sketch & Figma</li>
                <li><span>Animations -</span> AfterEffects & Adobe Animate </li>
                <li><span>Collaborations -</span> Zeplin, Invision & Adobe Cloud</li>
                <li><span>Graphic Design -</span> Illustrator, Photoshop & Sketch</li>
              </ul>
              <Link  onClick={(e) => HandelMessageModel(e,'Start hiring')}>Start hiring</Link>
            </div>
          </div>
          <div className="Cutting_divide">
            <div className="cutting_box">
              <div className="cutting_boxsub">
                <div className="cutting_boxbackground">
                  <img src={hireiconone} alt="" />
                  <p>Add Resources in Your Existing Team</p>
                </div>
              </div>
              <div className="cutting_boxsub">
                <div className="cutting_boxbackground">
                  <img src={hireicontwo} alt="" />
                  <p>Build Team from Scratch</p>
                </div>
              </div>
              <div className="cutting_boxsub">
                <div className="cutting_boxbackground">
                  <img src={hireiconthree} alt="" />
                  <p>Fixed Cost<br /> Project </p>
                </div>
              </div>
              <div className="cutting_boxsub">
                <div className="cutting_boxbackground">
                  <img src={hireiconfour} alt="" />
                  <p>Safe & Secure </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="key_skills">
        <div className="container">
          <div className="key_skills_flex">
            <div className="key_skills_divide">
              <div className="key_skills_image">
                <Slider {...uiUxSlider}>
                  <img src={ExpertUIUX1} alt="" />
                  <img src={ExpertUIUX2} alt="" />
                  <img src={ExpertUIUX3} alt="" />
                  <img src={ExpertUIUX4} alt="" />
                  <img src={ExpertUIUX5} alt="" />
                  <img src={ExpertUIUX6} alt="" />
                </Slider>
              </div>
            </div>
            <div className="key_skills_divide">
              <div className="key_skills_text">
                <h2>Key Skills Our Expert <span className="bg">UI/UX</span> <span>Designers</span> Have</h2>
                <p>Skill to understand your project niche and the market type to craft a well-researched digital product design that empathize with target users</p>
                <p>Knows and follows digital product design lifecycle and best practices, from doing market research, creating wireframing, prototypes, mockups, and user flow while taking care of Information Architecture (IA)</p>
                <p>Hands-on experience with industry-leading visual design and designing tools</p>
                <p>Well-acquainted with user research and digital product design usability testing best practices</p>
                <p>Knows and follows agile project management tools, including Azure Boards, Notion, Jira Agile and these are the best practices to meet project deadlines</p>
                <p>Work side-by-side with developers to make them understand the design flow, design interaction points & transitions</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section_three three_section" ref={portFolio}>
        <div className="container">
            <div className="text_head_part">
                <h2 className="fadeInUp">Recent <span>Case Studies</span></h2>
                <p className="fadeInUp">We’ve hand-picked some case studies we think you’ll like:</p>
            </div>
        </div>

        {isLoader && !portData && (
          <div className="section-loader">
            <div className="loader--ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <p>Loading...</p>
          </div>
        )}

        {portData ? (
            <>
                <div className="blue_bar">
                    <div className="container">

                        <Slider {...projectSliderNav}>

                            {portData && portData?.length > 0 && portData?.map((items, i) => {
                                return (
                                    <div key={i}>
                                        <span className="small_img">
                                            <img src={items.logo} alt="" />
                                            <h5>{items.title}</h5>
                                        </span>
                                    </div>
                                )
                            })}
                        </Slider>

                    </div>
                </div>
                <div className="container">
                    <div className="sec_inner_port_holder">
                        <Slider {...projectSlider}>
                            {portData && portData?.length > 0 && portData?.map((items, i) => {
                            return(
                                    <div key={i}>
                                        <div className="sec_inner_port">
                                            <div className="left">
                                                <ul>
                                                    <li><span>Project Name:</span> {items?.title}</li>
                                                    <li></li>
                                                    <li><span>Technology :</span> {items?.technology && JSON.parse(items?.technology)?.length > 0 && JSON.parse(items?.technology)?.reverse().map((technology, t) => {
                                                        return (<> { technology.nm} {t + 1 === JSON.parse(items?.technology)?.length ? '':'  |'}</>)
                                                    })}</li>
                                                    
                                                </ul>
                                                <div dangerouslySetInnerHTML={{__html: items?.description}}></div>
                                                <Link onClick={(e) => HandelCaseStudiesModel(e, `Read more ${items?.title}`)} className="btn">Read details</Link>
                                            </div>
                                            <div className="right">
                                                <div className="laps">
                                                  <img src={items?.image} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )  
                            })}
                        </Slider>
                    </div>
                </div>
            </>
          ):(!isLoader ? (
              <div className="container">
                  <div style={{textAlign: 'center',}}>
                      <span style={{margin: '10px auto'}}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" width={'40'} height={'40'}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                          </svg>
                      </span>

                      <p>Case Studies not found!</p>
                  </div>
              </div>
          ):(null) 
        )} 
      </section>

      <section className="hire_review">
        <div className="container">
          <div className="allreview_box">
           <div className="allreview_box_left">
            <div className="reviewbox">
            <div className="reviewbox_round_img"> <img src={Designerreview2} alt=''/> </div>
              <div className="reviewbox_text">
                <h3>Dipak Tewary</h3>
                <div className="single">
                 <img src={Designersinglereview} alt=''/>
                 <h4>4.8 <span>(4600)</span></h4>
                </div>
                <p>Designer with a diverse portfolio, and 10+ years of experience.</p>
                <ul>
                  <li onClick={(e) => HandelMessageModel(e, 'Hire Dipak Tewary for UI & UX ')}><button>UI & UX</button></li>
                  <li onClick={(e) => HandelMessageModel(e, 'Hire Dipak Tewary for Web and App Design')}><button>Web and App Design</button></li>
                </ul>
                <button className="allreview_box_btn" onClick={(e) => HandelMessageModel(e, 'Hire Designer Dipak Tewary')}>Hire Designer</button>
              </div>
            </div>

            <div className="reviewbox">
            <div className="reviewbox_round_img"> <img src={Designerreview4} alt=''/> </div>
              <div className="reviewbox_text">
                <h3>Kallol Sarkar</h3>
                <div className="single">
                 <img src={Designersinglereview} alt=''/>
                 <h4>4.5 <span>(4040)</span></h4>
                </div>
                <p>With an experience of 8+ years, he can handle different types of projects, such as education, e-commerce, and more. </p>
                <ul>
                  <li><button onClick={(e) => HandelMessageModel(e, 'Hire Kallol Sarkar for UI & UX')}>UI & UX</button></li>
                  <li><button onClick={(e) => HandelMessageModel(e, 'Hire Kallol Sarkar for Web and App Design')}>Web and App Design</button></li>
                </ul>
                <button className="allreview_box_btn" onClick={(e) => HandelMessageModel(e, 'Hire Designer Kallol Sarkar')}>Hire Designer</button>
              </div>
            </div>
            </div>
           

            <div className="allreview_box_mdl">
              <button className="allreview_box_mdl_inside" onClick={(e) => HandelMessageModel(e, 'Start hiring')}>
              {/* <h3>Our Designer</h3> */}
              <p><span>Looking for Designers<br /> with Critical-thinking Ability?</span><br /> Hiring our Designers will not leave you disappointed</p>
              <h6 className="allreview_box_mdl_btn">Start hiring</h6>
              </button>
            </div>
           
            <div className="allreview_box_left">
            <div className="reviewbox">
              <div className="reviewbox_round_img"> <img src={Designerreview1} alt=''/> </div>
              <div className="reviewbox_text">
                <h3>Sumit Santra</h3>
                <div className="single">
                 <img src={Designersinglereview} alt=''/>
                 <h4>4.7 <span>(4640)</span></h4>
                </div>
                <p>The designer's communication, performance, professionalism, and reliability are a class apart. </p>
                <ul>
                  <li><button onClick={(e) => HandelMessageModel(e, 'Hire Sumit Santra for UI & UX')}>UI & UX</button></li>
                  <li><button onClick={(e) => HandelMessageModel(e, 'Hire Sumit Santra for Web and App Design')}>Web and App Design</button></li>
                </ul>
                <button className="allreview_box_btn" onClick={(e) => HandelMessageModel(e, 'Hire Designer Sumit Santra')}>Hire Designer</button>
              </div>
            </div>

            <div className="reviewbox">
            <div className="reviewbox_round_img"> <img src={Designerreview3} alt=''/> </div>
              <div className="reviewbox_text">
                <h3>Surajit Halder</h3>
                <div className="single">
                 <img src={Designersinglereview} alt=''/>
                 <h4>4.5 <span>(4240)</span></h4>
                </div>
                <p>With 14+ years of experience behind him, leverage his technical proficiency, creativity, and collaboration skills.</p>
                <ul>
                <li><button onClick={(e) => HandelMessageModel(e, 'Hire Surajit Halder for UI & UX')}>UI & UX</button></li>
                  <li><button onClick={(e) => HandelMessageModel(e, 'Hire Surajit Halder for Web and App Design')}>Web and App Design</button></li>
                </ul>
                <button className="allreview_box_btn" onClick={(e) => HandelMessageModel(e, 'Hire Designer Surajit Halder')}>Hire Designer</button>
              </div>
            </div>
            </div>

           </div>
        </div>
      </section>

      <section className="pricing_scaling" id="target-section" ref={pricing}>
        <div className="container">
          <div className="head-title">
            <h2>Easy and flexible <span className="color"> pricing </span> to form a team</h2>
            <p>
              Spend on what you need and get your project done with the most lucrative pricing plans.
            </p>
          </div>
          <div className="wrapper">
            <div className="divider">
              <div className="top-bar">
                <h3>Hire (Hourly)</h3>
                <p>Remote talent</p>
              </div>
              <div className="bottom-bar">
                <div className="scr_circle">
                  <h2>
                    <span>From</span>
                    $20
                    <span>/Hour</span>
                  </h2>
                </div>
                {/* <h4>Hourly (USD)</h4> */}

                <h4>
                  We’ll provide a fully signed NDA for your Project’s confidentiality
                </h4>

                <a onClick={(e) => HandelMessageModel(e, 'Hire (Hourly)')} className="link_up">
                  get started
                </a>
                
              </div>
            </div>

            <div className="divider">
              <div className="top-bar">
                <h3>Hire (Monthly)</h3>
                <p>Remote talent</p>
              </div>
              <div className="bottom-bar">
                <div className="scr_circle">
                  <h2>
                    <span>From</span>
                    $2850
                    <span>/Month</span>
                  </h2>
                </div>
                <h4>3 to 5 Years of Exp. Senior UI/UX Designer - 160 hours.</h4>   
                <a onClick={(e) => HandelMessageModel(e, 'Hire (Monthly)')} className="link_up">
                  get started
                </a>           
              </div>
            </div>

            <div className="divider">
              <div className="top-bar">
                
                <h3>Need more flexibility?</h3>
                <p>[ You can start as little as 1 hr per day ]</p>
              </div>
              <div className="bottom-bar">

                <div className="scr_circle">
                  <h2>
                    <span>Fixed Cost Solution</span>
                   
                  </h2>
                </div>
                
                <h4>Get a Free Estimation based on Your Custom Requirements.</h4>
                
                <a onClick={(e) => HandelMessageModel(e, 'Need more flexibility?')} className="link_up">
                  get started
                </a>
              </div>
            </div>
          </div>

            <div className="price-btn">
              <button onClick={(e) => HandelMessageModel(e, 'Need more flexibility?')} className="btn-stack">
                SCHEDULE AN INTERVIEW
              </button>
            </div>

        </div>
        
      </section>

      <section className="videoslider" ref={testmonialsMenu}>

        <div className="trusted-section">
              <div className="trusted-logo-box">
              <h2>Our Amazing Customer <span>Success Stories</span></h2>
                <div>
                  <Slider {...videoslider}>
                    {videos.map((video, index) => (
                      <div key={index} className={'youtube-video'} onClick={() => HandelYoutubeVideo(video.id)}>
                        <LiteYouTubeEmbed
                          id={video.id}
                          title={video.title}
                        />
                        <h6><span>{video.description}</span></h6>
                      </div>
                    ))}
                  </Slider>
                </div>

        </div>
        </div>
      </section>

      <section  className="faq-sec-part" ref={faqMenu}>
        <div className="container" >
 

          <h3>Frequently Asked Questions</h3>
          <div className="acordin-sec">
            <div className="accordion">
              <div className="accordion-half">
                  {accordionItems.slice(0, 3).map((item, index) => (
                    <div key={index} className="accordion-item">
                      <button
                        className={`accordion-title accordion-item-header ${
                          activeIndex === item.id ? "active" : ""
                        }`}
                        onClick={() => handleAccordionClick(item.id)}
                      >
                        {item.title}
                      </button>
                      
                        <CSSTransition in={activeIndex === item.id} >
                          {state => (
                            <div
                              style={{
                                maxHeight: state === 'entered' ? '100px' : '0',
                                overflow: 'hidden',
                                transition: 'max-height 0.2s ease-in-out',
                              }}
                            >
          
                              <div className="accordion-content">{item.content}</div>
                            </div>
                          )}
                        </CSSTransition>
                    </div>
                  ))}
              </div>

              <div className="accordion-half">
                {accordionItems.slice(3, 5).map((item, index) => (
                  <div key={index} className="accordion-item">
                    <button
                      className={`accordion-title ${
                        activeIndex === item.id ? "active" : ""
                      }`}
                      onClick={() => handleAccordionClick(item.id)}
                    >
                      {item.title}
                    </button>
                      
                      <CSSTransition in={activeIndex === item.id} >
                        {state => (
                          <div
                            style={{
                              maxHeight: state === 'entered' ? '100px' : '0',
                              overflow: 'hidden',
                              transition: 'max-height 0.2s ease-in-out',
                            }}
                          >
        
                            <div className="accordion-content">{item.content}</div>
                            {item.list && (
                              <ul>
                                {item?.list?.map((list,i)=>(
                                  <li key={i}>{list}</li>
                                ))}
                                
                              </ul>
                            )}
                          </div>
                        )}
                      </CSSTransition>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact_ups">
        <div className="container">
          <div className="contact_content">
            <div className="ttx">
              <h2>Hire designer on hourly basis and get your project done.</h2>
              <p>
                We ensure faster delivery of your product by expert designer. <br/>Starting from $20/hr only.{" "}
              </p>
              <h5>Our expert will help you out to organize your project requirement:</h5>
              <ul>
                <li>Set the minimal plan to get it done</li>
                <li>
                Ensure a faster delivery{" "}
                </li>
                
              </ul>
            </div>
            <div className="frm-box-rp">
              <div className="frm-box">
                <h3>Not sure where to start?</h3>
                <p>Let’s have a chat</p>
                <Formik
                  initialValues={{ name:"", email: "", tryNow: "Not sure where to start" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = "Email is required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }

                    if (!values.name || !_.trim(values.name)) {
                      errors.name = 'Name is required';
                      } else if (values.name.length > 50) {
                      errors.name = 'Must be 15 characters or less';
                      } else if (values.name.match("[<>]")) {
                      errors.name = 'Please provide a valid name';
                      }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setBtnLoaderTwo(true);
                    setSubmitting(false);
                    axios
                      .post(`/lets-chat`, values)
                      .then(function (response) {
                        // console.log(response);
                        navigate('/thank-you');
                        setIsThankYouTwo(true);
                      })
                      .catch(function (error) {
                        // console.log(error)
                        setIsErrorTwo(
                          error?.message ? error.message : "Network Error"
                        );
                      })
                      .finally(() => {
                        setBtnLoaderTwo(false);
                        setTimeout(myGreeting, 5000);
                        values.email = "";
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className=" "
                      id=""
                    >
                      <label htmlFor="">Your name</label>
                      <input type="text" autoCapitalize="none" autoComplete="new-password" onChange={handleChange} onBlur={handleBlur} name="name" value={values.name} />
                      {errors.name && touched.name && (
                          <div
                            style={{
                              color: `rgb(255, 0, 0)`,
                              marginTop: `2px`,
                            }}
                          >
                            {errors.name}
                          </div>
                        )}

                      <label htmlFor="">Your company email</label>
                      <input type="email" autoCapitalize="none" autoComplete="new-password" onChange={handleChange} onBlur={handleBlur} name="email" value={values.email} />
                      {errors.email && touched.email && (
                          <div
                            style={{
                              color: `rgb(255, 0, 0)`,
                              marginTop: `2px`,
                            }}
                          >
                            {errors.email}
                          </div>
                        )}

                      <button type="submit" className="link_up">
                        book a call with us 
                        {btnLoaderTwo && (
                          <span className="btn-ring-animation"></span>
                        )}
                      </button>
                    </form>
                  )}
                  </Formik>

                  {isThankYouTwo && (
                    <div className="alert alert-success mt-3">
                      Thank you for your interest.
                    </div>
                  )}
                  {isErrorTwo && (
                    <div style={{ color: `rgb(255, 0, 0)`, marginTop: `2px` }}>
                      {isErrorTwo}
                    </div>
                  )}

              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="wap">
            <div className="item">
              <a href="#" className="frt-logo">
                <img src={FooterLogo} alt="" />
              </a>
              
             
              
            </div>
            {/* <div className="item">
              
              <p><a href="mailto:info@reddensoft.com">info@reddensoft.com</a></p>
            </div> */}
            <div className="item">
              <a href="tel:+1 678 608 2725" className="call-btn">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
                    <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"/>
                  </svg>
                </span>
                <span className="number-box">
                  (678) 608-2725
                </span>
              </a>
            </div>

            <div className="item">
              <p>
                <a onClick={() => HandelGuidelineModal()}>
                  Privacy Policy
                </a>
              </p>
            </div>

          </div>
        </div>
        <div className="copy-riht">
          <div className="container">
            <p>ReddenSoft Copyright 2023. All rights reserved.</p>
            {/* <ul>
              
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              
            </ul> */}
          </div>
        </div>
      </footer>

      <Transition
        show={messageModel}
        enter="ease-out duration-300"
        enterFrom="opacity-0 relative-modal"
        enterTo="opacity-100 relative-modal"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 relative-modal"
        leaveTo="opacity-0 relative-modal"
      >
        <ScheduleConsultation
          isSchedule={false}
          isNewTry={isTry}
          isFree={isStartFreeTrial}
          toggleModal={toggleModal}
        />
      </Transition>

      <Transition
        show={scheduleModel}
        enter="ease-out duration-300"
        enterFrom="opacity-0 relative-modal"
        enterTo="opacity-100 relative-modal"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 relative-modal"
        leaveTo="opacity-0 relative-modal"
      >
        <ScheduleConsultation isSchedule={true} toggleModal={toggleModal} />
      </Transition>

      <Transition
        show={isCaseStudies}
        enter="ease-out duration-300"
        enterFrom="opacity-0 relative-modal"
        enterTo="opacity-100 relative-modal"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 relative-modal"
        leaveTo="opacity-0 relative-modal"
      >
        <CaseStudiesModal modalName="designers" toggleModal={toggleModal} />
      </Transition>

      <Transition 
            show={guidelineModal}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal">
                <div className='Guideline-modal'>

                    <div className='Guideline-modal-box' ref={Modalref}>
                      <button className='inline-btn' onClick={() => HandelGuidelineModal()} type='button'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20px"  height="20px">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>

                      <div className="Guideline-modal-inside">
                        <h2>Privacy Policy</h2>

                        <p>
                        This privacy policy of ReddenSoft declares the process and purpose of collecting/ storing/ sharing your data while you are using our services.
                        </p>

                        <p>
                        We recommend you read this entire privacy policy thoroughly to get a clear picture of your rights and choices. Using our services means you are agreeing with the privacy policy we have.
                        </p>

                        <h4>
                          Information that we collect:-
                        </h4>

                        <h6>
                            Personal information
                        </h6>

                        <p>
                        We collect the basic information from you like your name, phone number, email id, and website/ name of the organization while you schedule a meeting with us or ask for a partnership program. The purpose is to further communicate with you and get your job done.
                        </p>

                        <h6>
                           Non-personal information
                        </h6>
                        <p>
                        Collecting non-personal identification information about Users happens when they interact with our Site. It may include the type of computer, browser name, and technical information about the user while accessing our site (i.e., the operating system and the Internet service providers used) and other similar information.
                        </p>

                        <h6>
                          Use of collected information:-
                        </h6>

                        <ul className='list-items'>
                            <li>For the betterment of customer service</li>
                            <li>Providing you with the best-personalized user experience</li>
                            <li>For the Site improvement </li>
                            <li>For Payment Processing</li>
                            <li>For the improvement of promotion/ contest/ survey / other features of the Site </li>
                            <li>For sending periodic emails</li>
                        </ul>

                        <h6>
                        Media
                        </h6>

                        <p>
                        We recommend not sharing inappropriate or sensitive images or documents on the website. On the contrary, using images or documents from the website is strictly prohibited and it could be penalized under copyright law.
                        </p>

                        <h6>
                          Embedded content from other websites
                        </h6>

                        <p>
                          Articles on this site may include embedded content (e.g., videos, images, articles, etc.) from other third-party websites. Embedded content from other websites behaves exactly the same way it appeared on the original website.
                        </p>
                        <p>
                          Those third-party we bsites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content (including tracking your interaction with the embedded content) if you are already logged into those websites.
                        </p>

                        <h6>
                        Cookies policy
                        </h6>

                        <p>
                          Our website may use "cookies" to enhance the visitor’s experience. Cookies are being placed by the web browser on their hard drive for record-keeping purposes and sometimes to track information about them. Users may opt to set their web browser to refuse cookies or give them an alert when cookies are being sent. If they do so, then some parts of the website may not function properly.
                        </p>
                        <p>
                          Visitors of our website can subscribe to different services like subscribing to blogs, starting a project with us or joining the partnership program, and for that, we need your name, email address, and website information to store in cookies. These are for your convenience so that you do not have to fill in your details again when you go for other services on the website. These cookies will last for one year.
                        </p>

                        <h6>
                        Information Sharing
                        </h6>
                        <p>
We strictly oppose sharing information with any third party. Information shared with us will only be used for the betterment of your project and it will be kept only with us. We also request you to not disclose any confidential statement about the ongoing project with any other website or third-party domain.
                        </p>
                      </div>
                    </div>
                </div>  
      </Transition>

      <Transition 
          show={openVideoModal}
          enter="ease-out duration-300"
          enterFrom="opacity-0 relative-modal"
          enterTo="opacity-100 relative-modal"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 relative-modal"
          leaveTo="opacity-0 relative-modal">

        <div className="videoModal">
          
          <div className="videoModalInside">
            <button className='inline-btn' onClick={() => setVideoModal(!openVideoModal)} type='button'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20px"  height="20px">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            {/* <LiteYouTubeEmbed
              id={currentvideoId}
              autoplay={true}
              title={"I have been working on a 'pet' project for quite some time now."}
            /> */}

            <iframe style={{
             position: "absolute",
             top: 0,
             left: 0,
             width: "100%",
             height: "400px"
             }} src={`https://www.youtube.com/embed/${currentvideoId}?autoplay=1&mute=1`}
              frameBorder='0'
              allow='autoplay; encrypted-media'
              allowFullScreen
              title='video'
            />
          </div>
        </div>
      </Transition>

    </section>
  );
}

export default HireDesigner;
