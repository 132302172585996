import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { CookiesProvider } from "react-cookie";
import TawkToChat from './Components/TawkAPI/TawkAPI';


const ApiConfig = {
  "LocalURL" :"https://reddensoft.us",
  "ServerURL" :"https://reddensoft.us"
}
// https://works-reddensoft.com/reddensite/
// https://works-reddensoft.com

let URL;
if (window.location.hostname === "localhost") {
  URL = `${ApiConfig.LocalURL}/admin/api`;
} else {
  URL = `${ApiConfig.ServerURL}/admin/api`;
}

axios.defaults.baseURL = URL;

// ReactGA.initialize('UA-276663116-1');



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CookiesProvider>
        <App />
        <TawkToChat/>
      </CookiesProvider>
    </BrowserRouter>
  </React.StrictMode>
);


