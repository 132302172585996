import React, { useRef, useState } from 'react';
import { HeaderLogo, FooterLogo, 
    designerexpertbackground,experticonone,experticontwo,experticonthree,experticonfour, 
    elevatedsirone,elevatedsirtwo,elevatedsirthree,elevatedsirfour,elevatedstar,customerquestion, 
    customerprofile,fastaffordablybanner,designerexpertarrow,batchone,batchtwo,designarrow,Bannerbtmlogo5,
    Bannerbtmlogo1,Bannerbtmlogo2,Bannerbtmlogo3,customerprofileone,customerprofiletwo,customerprofilethree,
    customerprofilefour, batchWebDesign, } from '../../Assets/index';
import { useEffect } from 'react';
import { Transition } from '@headlessui/react';
import ScheduleConsultation from '../ScheduleConsultation';
import WebFont from 'webfontloader';
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './App.css'
import { CSSTransition } from 'react-transition-group';
import CaseStudiesModal from "../CaseStudiesModal";

function Bestdesign() {
    var _ = require('lodash');
    let interval = useRef();

    useEffect(() => {
        WebFont.load({
            google: {
            families: ['Roboto Slab:200,300,400', 'Assistant:200,300,400,500,600,700,800',] // Fonts you want to load
            },
            custom: {
            families: ['Patua One'], // Optional: If you want to load a custom font, specify its name here
            urls: ['https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400&display=swap',
                    'https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&family=Patua+One&display=swap'] // URLs of the font CSS files
            }
        });
        return () => clearInterval(interval.current);
    }, [])
    
    useEffect(() => {
    WebFont.load({
        google: {
        families: [
            "Roboto Slab:200,300,400",
            "Montserrat:300,400,500,700",
            "Patua One",
            "Mulish",
        ], // Fonts you want to load
        },
        custom: {
        families: ["Patua One"], // Optional: If you want to load a custom font, specify its name here
        urls: [
            "https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400&display=swap",
            "https://fonts.googleapis.com/css2?family=Montserrat:wght@300,400;500;700&display=swap",
            "https://fonts.googleapis.com/css2?family=Mulish&family=Patua+One&display=swap",
        ], // URLs of the font CSS files
        },
    });

    }, []);
    
    const [scheduleModel, setScheduleModel] = useState(false);
    const [messageModel, setMessageModel] = useState(false);
    const [isTry, setIsTry] = useState();
    const [ isStartFreeTrial, setIsStartFreeTrial ] = useState(false);
    const HandelMessageModel = (e, val) => {
        if (val) {
            setIsTry(val);
        } else {
            setIsTry();
        }


        if(val === 'Try Free for 15 Days'){
        setIsStartFreeTrial(true);
      }else{
        setIsStartFreeTrial(false);
      }

        setMessageModel(true);
    };

    const toggleModal = () => {
        setScheduleModel(false);
        setMessageModel(false);
        setIsCaseStudies(false);
    };
    
    
      // accordian
    const [activeIndex, setActiveIndex] = useState(0);
    const handleAccordionClick = (index) => {
        setActiveIndex(index === activeIndex ? -1 : index);
    };
    
    const [isThankYou, setIsThankYou] = useState(false);
    const [isError, setIsError] = useState(false);

    const [isThankYouTwo, setIsThankYouTwo] = useState(false);
    const [isErrorTwo, setIsErrorTwo] = useState(false);
    const [btnLoaderTwo, setBtnLoaderTwo] = useState(false);
    
    function myGreeting() {
        setIsThankYou(false);
        setIsError(false);
        setIsThankYouTwo(false);
        setIsErrorTwo(false);
    }

    const [guidelineModal, setGuidelineModal] = useState(false);

    const HandelGuidelineModal = () => {
        setGuidelineModal(!guidelineModal)
    }

    const navigate = useNavigate();

    const HandelScheduleModel = () => {
        setScheduleModel(true)
    } 

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    const [navOne, setNavOne] = useState();
    const [navTwo, setNavTwo] = useState();

    var salesBannerSlider = {
        dots: false,
        infinite: true,
        arrows:true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: false,
        autoplaySpeed: 2000,
    };

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const pricing = useRef(null);
    const faqMenu = useRef(null);
    const testmonialsMenu = useRef(null);
    const homeMenu = useRef(null);

    const dropdownRef = useRef(null);
    useEffect(() => {
      function handleClickOutside(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsResponsiveMenu(false);
        }
      }
  
      document.addEventListener('click', handleClickOutside);
  
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);

    let location = window.location.href;
    const Modalref = useRef(null);
    useEffect(() => {
      function handleClickOutside(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsResponsiveMenu(false);
        }
  
        if (Modalref.current && !Modalref.current.contains(event.target)) {
          setGuidelineModal(false);
        }
      }
  
      document.addEventListener('click', handleClickOutside);
  
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
  
    }, []);

    const [isResponsiveMenu, setIsResponsiveMenu] = useState(false);

    const portFolio = useRef(null);
    async function PortfolioApi() {
        axios.get('/portfoliodata')
      .then(await function  (response) {
          if(response && response?.data && response?.data?.data){
              setPortData(response?.data?.data)
          }
      })
      .catch(await function (error) {
          console.log(error)
      })
      .finally(() => {
        console.log('Experiment completed');
        setIsLoader(false);
      }); 
    }

    useEffect(() => {
        setIsLoader(true);
        PortfolioApi()
     
      }, []);

    const [portData, setPortData] = useState();
    const [isLoader, setIsLoader] = useState(false);

    var projectSliderNav = {
        asNavFor:navOne, 
        ref: (slider2) => setNavTwo(slider2),
        slidesToShow:5,
        slidesToScroll: 1,
        autoplay: true,
        arrows:false,
        speed: 1000,
        autoplaySpeed: 3000,
        swipeToSlide:true,
        focusOnSelect:true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                
              }
            },
            {
                breakpoint: 500,
                settings: {
                  slidesToShow: 2,
                }
              }
        ]
      };
    
    var projectSlider = {
        asNavFor: navTwo,
        ref:(slider1) => setNavOne(slider1),
        fade:true,
        arrows:false,
    };

    const HandelCaseStudiesModel = () => {
        setIsCaseStudies(true);
    }

    const [isCaseStudies, setIsCaseStudies] = useState(false);

    const accordionItems = [
        {
          id: 1,
          title: "What is the cost of hiring a designer?",
          content: "Hire our top-of-the-line designers to work on your project for a rate as low as 20$ per hour. ",
        },
        {
          id: 2,
          title: "How do you approach a design project?",
          content: "We have based our approach to design on four main phases, namely, research, design, testing, and launch.",
        },
        {
          id: 3,
          title: "How long does design take?",
          content: "The duration of design project can range anywhere between 2 weeks and 6 months.",
        },
    
        {
          id: 4,
          title: "What are the benefits that I can gain?",
          content: "Save money and time, increased user satisfaction, and an enhanced brand image and reputation. ",
        },
        {
          id: 5,
          title: "How to estimate the total cost of the project?",
          content: "The price of a UI UX design project depends on:",
          list: [
           'Scope and complexity of the project',
          'The timeline and deadline of the project',
            'Designer experience and expertise'
    
          ]
        },
      ];

    var sirprofileslider = {
        asNavFor:nav1,
        ref:(slider2) => setNav2(slider2),
        slidesToShow:3,
        swipeToSlide:true,
        // focusOnSelect:true,
        autoPlay:true,
        vertical:true,
        verticalScrolling:true,
        speed: 300,
        arrows:true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    vertical:true,
                    verticalScrolling:true,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    arrows:false,
                }
            },
        ]
    }  


    return (
        <section className='sales-page hair-designer' ref={homeMenu}>
            <Helmet>
                    
                <script id="facebook-pixel-script">
                    {`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js'),
                    fbq('init', '273096641131718'),
                    fbq('track', 'PageView')
                    `}
                </script>
                <noscript id="facebook-pixel-image">
                    {`
                        <img height="1" width="1" style="display:none"
                        src="https://www.facebook.com/tr?id=273096641131718&ev=PageView&noscript=1"
                    />
                    `}
                    
                </noscript>
            </Helmet>

            <section className="banner">
                <div className={` header-section ${scrollPosition > 50 ? "fixed-header slide-in" : ""}`}>
                    <div className="container">
                        <div className="header-part">
                            <Link className="logo" onClick={() => homeMenu.current?.scrollIntoView({behavior: 'smooth'})}>
                                <img src={HeaderLogo} alt="Reddensoft logo" />
                            </Link>
                            <div ref={dropdownRef} className={` ${isResponsiveMenu ? 'active':''}  header_middle`}>
                                <ul>
                                    <li><a onClick={() => {portFolio.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Our Portfolio</a></li>
                                    <li><a onClick={() => {pricing.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Pricing</a></li>
                                    <li><a onClick={() => {testmonialsMenu.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Testimonial</a></li>
                                    <li><a onClick={() => {faqMenu.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">FAQs</a></li>
                                </ul>
                            </div>
                            <div className="header_right">
                                <a onClick={(e) => HandelMessageModel(e,'Try Free for 15 Days')} className="full-stack-top-btn">
                                    <span>Try Free for 15 Days</span>
                                </a>

                                <a onClick={(e) => HandelScheduleModel(e, 'schedule a call')} className="full-stack-top-btn fill-btn mobile-none">
                                    <span>Schedule a call</span>
                                </a>
                                <button className="bars-menu" ref={dropdownRef} onClick={() => setIsResponsiveMenu(!isResponsiveMenu)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={22} height={22}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="banner-section">
                        <div className="banner-left designer_div">
                            <h2>Get Quicker Solutions of On-Demand Workforce</h2>
                            <h3>IN QUEST FOR BEST</h3>
                            <h4>WEBSITE DESIGNERS</h4>
                            <h6>Build your team with passionate and experienced Web Designers for Mobile & Web Applications starting from <span>$2500.00 /month</span></h6>

                            <div className="banner-feture banner_featur_width">
                                <div className="banner-first-feture">
                                <p>Get 15 Days Free Trial</p>
                                </div>

                                <div className="banner-second-feture">
                                <p>Quick Team Set-up & Onboarding</p>
                                </div>

                                <div className="banner-first-feture">
                                <p>No Expense on Retaining & Training</p>
                                </div>

                                <div className="banner-second-feture">
                                <p>IP Security and Ownership</p>
                                </div>

                                <div className="banner-first-feture">
                                <p>Quick Product Delivery</p>
                                </div>

                                <div className="banner-second-feture">
                                <p>
                                    Dedicated Project Manager
                                    <span className="image-text">Free</span>
                                </p>
                                </div>
                            </div>

                            <button className="hair-designer-btn" onClick={(e) => HandelMessageModel(e, 'Hire UI & UX Designer')}>HIRE BEST WEBSITE DESIGNER</button>
                            <button className="hair-designer-btn" onClick={(e) => HandelMessageModel(e, 'SCHEDULE A CALL')}>SCHEDULE A CALL</button>
                            <div className="hiredesigner_batch">
                                <div className="hiredesigner_batchsub">
                                    <img src={batchone} alt="" />
                                </div>
                                <div className="hiredesigner_batchsub">
                                    <img src={batchtwo} alt="" />
                                </div>
                                <div className="hiredesigner_batchsub">
                                    <img src={batchWebDesign} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='hiredesigner_client'>
                    <p class="banner-location">Working with Clients across<span>  &nbsp; <img src={designarrow} alt="" /> &nbsp; 
                    California &nbsp;|&nbsp; Texas  &nbsp;|&nbsp; Illinois &nbsp;|&nbsp; Florida &nbsp;|&nbsp; New York 
                  &nbsp;|&nbsp; Toronto &nbsp;|&nbsp; Montreal &nbsp;|&nbsp; Vancouver &nbsp;|&nbsp; Calgary &nbsp;|&nbsp; Mexico City &nbsp;|&nbsp; World Wide
                    </span></p>
                </div>

                <div className="hair_designer_bannerbtm_boeder">
                    <div className="hair_designer_bannerbtm">
                        <div className="hair_designer_bannerbtm_box">
                            <img src={Bannerbtmlogo5} alt="" />
                            <h3>We are rated 4.4 out of 5 on  average across 125 reviews</h3>
                        </div>
                        <div className="hair_designer_bannerbtm_box">
                            <img src={Bannerbtmlogo1} alt="" />
                            <h3>We are rated 4.5 out of 5 on  average across 120 reviews</h3>
                        </div>
                        <div className="hair_designer_bannerbtm_box">
                            <img src={Bannerbtmlogo2} alt="" />
                            <h3>We are rated 4.5 out of 5 on  average across 100 reviews</h3>
                        </div>
                        <div className="hair_designer_bannerbtm_box">
                            <img src={Bannerbtmlogo3} alt="" />
                            <h3>We are rated 4.0 out of 5 on  average across 110 reviews</h3>
                        </div>
                    </div>
                </div>

                <section className='facts_section'>
                <div className='container'>
                    <div className='heading'>
                        <h3>Brainstorming</h3>
                        <h2>Facts</h2>
                    </div>
                    <div className='facts_number'>
                        <div className='facts_flex'>
                            <div className='facts_sub'>
                                <h2>15+</h2>
                                <p>Years of Experience</p>
                            </div>
                            <div className='facts_sub'>
                                <h2>250+</h2>
                                <p>Agile Developers</p>
                            </div>
                            <div className='facts_sub'>
                                <h2>1100+</h2>
                                <p>Global Clients</p>
                            </div>
                            <div className='facts_sub'>
                                <h2>1200+</h2>
                                <p>Projects</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </section>

            <section className='expert_webdesigner' style={{ backgroundImage: `url(${designerexpertbackground})` }}>
                <div className='expert_webdesigner_iconbox'>
                    <div className='expert_webdesigner_icon'>
                        <img src={designerexpertarrow} alt='' />
                    </div>
                </div>
                <div className='container'>
                    <div className='expert_webdesigner_flex'>
                        <div className='expert_webdesigner_left'>
                            <h2>Experienced</h2>
                            <h3>WEB DESIGNERS</h3>
                            <h4>To Create Impactful Web Experience</h4>
                            <p>Web designers streamline effective solutions by planning and coding that elevates the presentation of web pages. Our Designers are capable of maintaining the latest trends of business websites to attract potential audiences.</p>
                            <Link onClick={(e) => HandelMessageModel(e)}>HIRE A TALENT</Link>
                        </div>
                        <div className='expert_webdesigner_right'>
                            <div className='expert_webdesigner_right_width'>
                                <div className='expert_webdesigner_right_box'>
                                    <div className='icon'>
                                        <img src={experticonone} alt='' />
                                    </div>
                                    <div className='text'>
                                        <h3>Web Designs</h3>
                                        <p>Planning, conceptualizing and implementing plans for web designs is vital when it comes to online branding. Our designer team presents exceptional designs as per website requirement to win over the market.</p>
                                    </div>
                                </div>
                                <div className='expert_webdesigner_right_box'>
                                    <div className='icon'>
                                        <img src={experticontwo} alt='' />
                                    </div>
                                    <div className='text'>
                                        <h3>Graphic Designs</h3>
                                        <p>If you want to grab impactful graphics to get the attention of viewers, we are here to help. We can assist you with creative graphic designers who are able to interpret visual compositions through color, forms, infographics and typographics.</p>
                                    </div>
                                </div>
                                <div className='expert_webdesigner_right_box'>
                                    <div className='icon'>
                                        <img src={experticonthree} alt='' />
                                    </div>
                                    <div className='text'>
                                        <h3>Logo Designs</h3>
                                        <p>Well-designed logos create professionalism in order to get engaged with clients. As the logo speaks for the company's first image in the market, we can help you to build strong and effective logo designs.</p>
                                    </div>
                                </div>
                                <div className='expert_webdesigner_right_box'>
                                    <div className='icon'>
                                        <img src={experticonfour} alt='' />
                                    </div>
                                    <div className='text'>
                                        <h3>Visual Designs</h3>
                                        <p>To attract potential audiences, it is relevant to build visual designs that aim for aesthetic and suitable images. Visual designs are required to be impressive, for which we can assure to uplift the quality of your website through our designing team.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='elevated_section'>
                <div className='container'>
                    <div className='elevated_flex'>
                        <div className='elevated_sub_leftbox'>
                            <div className='elevated_sub_left'>
                                <h3>We cater</h3>
                                <h4>Elevated</h4>
                                <h2>Performances</h2>
                                <p>Entrepreneurs search for exceptional designs with seamless experience for online branding. Therefore, we nurture their needs by presenting our dedicated designing teams to support your brand to stand out from competitors. Let's take the first step to pick a creative designing team.</p>
                                <Link onClick={(e) => HandelMessageModel(e)}>Let’s Pick your designing Team</Link>
                            </div>
                        </div>
                        <div className='elevated_sub_rightbox'>
                            <div className='elevated_sub_right'>
                                <div className='elevated_sub_right_heading'>
                                    <h4>Meet our diverse team of world-class creators, designers, and problem solvers.</h4>
                                </div>
                                <div className='elevated_sub_right_slider'>
                                    <Slider 
                                        asNavFor={nav2} 
                                        arrows={false}
                                        ref={(slider1) => setNav1(slider1)}
                                        fade={true}
                                        autoPlay={true}
                                        cssEase={'linear'}
                                        className='elevated_slider_content'
                                        >
                                        <div>
                                            <div className='elevated_slider_details'>
                                                <div className='profile_img'>
                                                    <img src={elevatedsirone} alt='' />
                                                </div>
                                                <h2>Dipak Tewary</h2>
                                                <h3>
                                                    <img className='profile_star' src={elevatedstar} alt='' />
                                                    4.8
                                                    <span>(4600)</span>
                                                </h3>
                                                <p>Designer with a diverse portfolio, and 10+ years of experience.</p>
                                                <h4>UI & UX  <span>|</span>  Web and App design</h4>
                                                <Link onClick={(e) => HandelMessageModel(e)}>Hire Designer</Link>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='elevated_slider_details'>
                                                <div className='profile_img'>
                                                    <img src={elevatedsirtwo} alt='' />
                                                </div>
                                                <h2>Sumit Santra</h2>
                                                <h3>
                                                    <img className='profile_star' src={elevatedstar} alt='' />
                                                    4.7
                                                    <span>(4640)</span>
                                                </h3>
                                                <p>The designer's communication, performance, professionalism, and reliability are a class apart.</p>
                                                <h4>UI & UX  <span>|</span>  Web and App design</h4>
                                                <Link onClick={(e) => HandelMessageModel(e)}>Hire Designer</Link>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='elevated_slider_details'>
                                                <div className='profile_img'>
                                                    <img src={elevatedsirthree} alt='' />
                                                </div>
                                                <h2>Kallol Sarkar</h2>
                                                <h3>
                                                    <img className='profile_star' src={elevatedstar} alt='' />
                                                    4.5
                                                    <span>(4040)</span>
                                                </h3>
                                                <p>Designer with a diverse portfolio, and 8+ years of experience.</p>
                                                <h4>UI & UX  <span>|</span>  Web and App design</h4>
                                                <Link onClick={(e) => HandelMessageModel(e)}>Hire Designer</Link>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='elevated_slider_details'>
                                                <div className='profile_img'>
                                                    <img src={elevatedsirfour} alt='' />
                                                </div>
                                                <h2>Surajit Halder</h2>
                                                <h3>
                                                    <img className='profile_star' src={elevatedstar} alt='' />
                                                    4.5
                                                    <span>(4240)</span>
                                                </h3>
                                                <p>Designer with a diverse portfolio, and 14+ years of experience.</p>
                                                <h4>UI & UX  <span>|</span>  Web and App design</h4>
                                                <Link onClick={(e) => HandelMessageModel(e)}>Hire Designer</Link>
                                            </div>
                                        </div>
                                    </Slider>
                                    <Slider {...sirprofileslider}
                                        className='elevated_slider_option'
                                        >
                                        <div>
                                            <div className='elevated_slider_nav'>
                                                <div className='profile_navimg'>
                                                    <img src={elevatedsirone} alt='' />
                                                </div>
                                                <p>Dipak Tewary</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='elevated_slider_nav'>
                                                <div className='profile_navimg'>
                                                    <img src={elevatedsirtwo} alt='' />
                                                </div>
                                                <p>Sumit Santra</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='elevated_slider_nav'>
                                                <div className='profile_navimg'>
                                                    <img src={elevatedsirthree} alt='' />
                                                </div>
                                                <p>Kallol Sarkar</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='elevated_slider_nav'>
                                                <div className='profile_navimg'>
                                                    <img src={elevatedsirfour} alt='' />
                                                </div>
                                                <p>Surajit Halder</p>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="client_slider" ref={testmonialsMenu}>
                <div className="container">
                    <Slider {...salesBannerSlider}>
                        <div>
                            <div className='client_slider_box'>
                                <div className='client_slider_imagebox'>
                                    <div className='client_slider_image'>
                                        <img src={customerprofile} alt='' />
                                    </div>
                                </div>
                                <div className='client_slider_text'>
                                    <img className='question' src={customerquestion} alt='' />
                                    <p>I was really impressed with the level of service that I received from Reddensoft. They were always responsive to my needs, and they were always willing to go the extra mile. I would definitely recommend them to anyone looking for their outstanding , creative design services. You guys are amazing.</p>
                                    <h4>Mike Boggus</h4>
                                    <h5>Entrepreneur</h5>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='client_slider_box'>
                                <div className='client_slider_imagebox'>
                                    <div className='client_slider_image'>
                                        <img src={customerprofileone} alt='' />
                                    </div>
                                </div>
                                <div className='client_slider_text'>
                                    <img className='question' src={customerquestion} alt='' />
                                    <p>I was really impressed with the level of service that I received from Reddensoft. They were always responsive to my needs, and they were always willing to go the extra mile. I would definitely recommend them to anyone looking for their outstanding , creative design services. You guys are amazing.</p>
                                    <h4>David Miller</h4>
                                    <h5>Entrepreneur</h5>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='client_slider_box'>
                                <div className='client_slider_imagebox'>
                                    <div className='client_slider_image'>
                                        <img src={customerprofiletwo} alt='' />
                                    </div>
                                </div>
                                <div className='client_slider_text'>
                                    <img className='question' src={customerquestion} alt='' />
                                    <p>I've worked with 2 unlimited design service provide in the past, but Reddensoft.us is by far the best. They're incredibly results-oriented, and they're always willing to go the extra mile to help me achieve my goals. I would definitely recommend them to anyone looking for unlimited design service packages.</p>
                                    <h4>Michel Ambrosio</h4>
                                    <h5>Founder @Quirkel</h5>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='client_slider_box'>
                                <div className='client_slider_imagebox'>
                                    <div className='client_slider_image'>
                                        <img src={customerprofilethree} alt='' />
                                    </div>
                                </div>
                                <div className='client_slider_text'>
                                    <img className='question' src={customerquestion} alt='' />
                                    <p>They are a great partner for our business. They're always up-to-date on the latest design trends, and they're always willing to go the extra mile to help us achieve our goals. We're confident that we're in good hands with them for any design services. Our clients are super happy. We are having a customized plan and hired few designer for our agency. </p>
                                    <h4>Bently Ritter</h4>
                                    <h5>President & CEO at BeneFit Personal Training</h5>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='client_slider_box'>
                                <div className='client_slider_imagebox'>
                                    <div className='client_slider_image'>
                                        <img src={customerprofilefour} alt='' />
                                    </div>
                                </div>
                                <div className='client_slider_text'>
                                    <img className='question' src={customerquestion} alt='' />
                                    <p>We've been working with reddensoft.US for the past 2 years on our graphic design services. They've helped us to increase our social presence, website with very high quality brand presence. Our lead generation has increased by 200%. We're really happy with the results, and we would definitely recommend them to other businesses.</p>
                                    <h4>Kimberly</h4>
                                    <h5>Entrepreneur</h5>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>

            <section className="section_three three_section " ref={portFolio}>
                <div className="container">
                    <div className="text_head_part">
                        <h2 className="fadeInUp">Recent <span>Case Studies</span></h2>
                        <p className="fadeInUp">We’ve hand-picked some case studies we think you’ll like:</p>
                    </div>
                </div>

                {isLoader && !portData && (
                <div className="section-loader">
                    <div className="loader--ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                    <p>Loading...</p>
                </div>
                )}

                {portData ? (
                    <>
                        <div className="blue_bar">
                            <div className="container">

                                <Slider {...projectSliderNav}>

                                    {portData && portData?.length > 0 && portData?.map((items, i) => {
                                        return (
                                            <div key={i}>
                                                <span className="small_img">
                                                    <img src={items.logo} alt="" />
                                                    <h5>{items.title}</h5>
                                                </span>
                                            </div>
                                        )
                                    })}
                                </Slider>

                            </div>
                        </div>
                        <div className="container">
                            <div className="sec_inner_port_holder">
                                <Slider {...projectSlider}>
                                    {portData && portData?.length > 0 && portData?.map((items, i) => {
                                    return(
                                            <div key={i}>
                                                <div className="sec_inner_port">
                                                    <div className="left">
                                                        <ul>
                                                            <li><span>Project Name:</span> {items?.title}</li>
                                                            <li></li>
                                                            <li><span>Technology :</span> {items?.technology && JSON.parse(items?.technology)?.length > 0 && JSON.parse(items?.technology)?.reverse().map((technology, t) => {
                                                                return (<> { technology.nm} {t + 1 === JSON.parse(items?.technology)?.length ? '':'  |'}</>)
                                                            })}</li>
                                                            
                                                        </ul>
                                                        <div dangerouslySetInnerHTML={{__html: items?.description}}></div>
                                                        <Link onClick={(e) => HandelCaseStudiesModel(e, `Read more ${items?.title}`)} className="btn">Read details</Link>
                                                    </div>
                                                    <div className="right">
                                                        <div className="laps">
                                                        <img src={items?.image} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )  
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </>
                ):(!isLoader ? (
                    <div className="container">
                        <div style={{textAlign: 'center',}}>
                            <span style={{margin: '10px auto'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" width={'40'} height={'40'}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                </svg>
                            </span>

                            <p>Case Studies not found!</p>
                        </div>
                    </div>
                ):(null) 
                )} 
            </section>

            <section className='fast_affordably' style={{ backgroundImage: `url(${fastaffordablybanner})` }}>
                <div className='container'>
                    <div className='fast_affordably_box'>
                        <h2>Fast and Affordable</h2>
                        <h3>We believe in Quick and Transparent Hiring Process.</h3>
                        <p>We’ll assist to handpick designers for you, agreeing on timeline, roadmap, either fixed, monthly or hourly for meeting your needs.</p>
                        <Link onClick={(e) => HandelMessageModel(e, 'Hire A Website Designer')}>Hire A Website Designer</Link>
                    </div>
                </div>
            </section>

            <section className="pricing_scaling" id="target-section" ref={pricing}>
                <div className="container">
                <div className="head-title">
                    <h2>Easy and flexible <span className="color"> pricing </span> to form a team</h2>
                    <p>
                    Spend on what you need and get your project done with the most lucrative pricing plans.
                    </p>
                </div>
                <div className="wrapper">
                    <div className="divider">
                        <div className="top-bar">
                            <h3>Hire (Hourly)</h3>
                            <p>Remote talent</p>
                        </div>
                        <div className="bottom-bar">
                            <div className="scr_circle">
                                <h2>
                                    <span>From</span>
                                    $20
                                    <span>/Hour</span>
                                </h2>
                            </div>
                            <h4>
                                We’ll provide a fully signed NDA for your Project’s confidentiality
                            </h4>

                            <a onClick={(e) => HandelMessageModel(e, 'Hire (Hourly)')} className="link_up">
                                get started
                            </a>
                            
                        </div>
                    </div>

                    <div className="divider">
                    <div className="top-bar">
                        <h3>Hire (Monthly)</h3>
                        <p>Remote talent</p>
                    </div>
                    <div className="bottom-bar">
                        <div className="scr_circle">
                        <h2>
                            <span>From</span>
                            $2850
                            <span>/Month</span>
                        </h2>
                        </div>
                        <h4>3 to 5 Years of Exp. Senior Website Designer - 160 hours.</h4>   
                        <a onClick={(e) => HandelMessageModel(e, 'Hire (Monthly)')} className="link_up">
                        get started
                        </a>           
                    </div>
                    </div>

                    <div className="divider">
                    <div className="top-bar">
                        
                        <h3>Need more flexibility?</h3>
                        <p>[ You can start as little as 1 hr per day ]</p>
                    </div>
                    <div className="bottom-bar">

                        <div className="scr_circle">
                        <h2>
                            <span>Fixed Cost Solution</span>
                        
                        </h2>
                        </div>
                        
                        <h4>Get a Free Estimation based on Your Custom Requirements.</h4>
                        
                        <a onClick={(e) => HandelMessageModel(e, 'Need more flexibility?')} className="link_up">
                        get started
                        </a>
                    </div>
                    </div>
                </div>

                    <div className="price-btn">
                    <button onClick={(e) => HandelMessageModel(e, 'Need more flexibility?')} className="btn-stack">
                        SCHEDULE AN INTERVIEW
                    </button>
                    </div>

                </div>
                
            </section>
              

            <section  className="faq-sec-part" ref={faqMenu}>
                <div className="container" >
        

                <h3>Frequently Asked Questions</h3>
                <div className="acordin-sec">
                    <div className="accordion">
                    <div className="accordion-half">
                        {accordionItems.slice(0, 3).map((item, index) => (
                            <div key={index} className="accordion-item">
                            <button
                                className={`accordion-title accordion-item-header ${
                                activeIndex === item.id ? "active" : ""
                                }`}
                                onClick={() => handleAccordionClick(item.id)}
                            >
                                {item.title}
                            </button>
                            
                                <CSSTransition in={activeIndex === item.id} >
                                {state => (
                                    <div
                                    style={{
                                        maxHeight: state === 'entered' ? '100px' : '0',
                                        overflow: 'hidden',
                                        transition: 'max-height 0.2s ease-in-out',
                                    }}
                                    >
                
                                    <div className="accordion-content">{item.content}</div>
                                    </div>
                                )}
                                </CSSTransition>
                            </div>
                        ))}
                    </div>

                    <div className="accordion-half">
                        {accordionItems.slice(3, 5).map((item, index) => (
                        <div key={index} className="accordion-item">
                            <button
                            className={`accordion-title ${
                                activeIndex === item.id ? "active" : ""
                            }`}
                            onClick={() => handleAccordionClick(item.id)}
                            >
                            {item.title}
                            </button>
                            
                            <CSSTransition in={activeIndex === item.id} >
                                {state => (
                                <div
                                    style={{
                                    maxHeight: state === 'entered' ? '100px' : '0',
                                    overflow: 'hidden',
                                    transition: 'max-height 0.2s ease-in-out',
                                    }}
                                >
                
                                    <div className="accordion-content">{item.content}</div>
                                    {item.list && (
                                    <ul>
                                        {item?.list?.map((list,i)=>(
                                        <li key={i}>{list}</li>
                                        ))}
                                        
                                    </ul>
                                    )}
                                </div>
                                )}
                            </CSSTransition>
                        </div>
                        ))}
                    </div>
                    </div>
                </div>
                </div>
            </section>

            

            <section className="contact_ups">
                <div className="container">
                <div className="contact_content">
                    <div className="ttx">
                    <h2>Hire designer on hourly basis and get your project done.</h2>
                    <p>
                        We ensure faster delivery of your product by expert designer. <br/>Starting from $2500/month only.{" "}
                    </p>
                    <h5>Our expert will help you out to organize your project requirement:</h5>
                    <ul>
                        <li>Set the minimal plan to get it done</li>
                        <li>
                        Ensure a faster delivery{" "}
                        </li>
                        
                    </ul>
                    </div>
                    <div className="frm-box-rp">
                    <div className="frm-box">
                        <h3>Not sure where to start?</h3>
                        <p>Let’s have a chat</p>
                        <Formik
                        initialValues={{ name:"", email: "", tryNow: "Not sure where to start" }}
                        validate={(values) => {
                            const errors = {};
                            if (!values.email) {
                            errors.email = "Email is required";
                            } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                values.email
                            )
                            ) {
                            errors.email = "Invalid email address";
                            }

                            if (!values.name || !_.trim(values.name)) {
                            errors.name = 'Name is required';
                            } else if (values.name.length > 50) {
                            errors.name = 'Must be 15 characters or less';
                            } else if (values.name.match("[<>]")) {
                            errors.name = 'Please provide a valid name';
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setBtnLoaderTwo(true);
                            setSubmitting(false);
                            axios
                            .post(`/lets-chat`, values)
                            .then(function (response) {
                                // console.log(response);
                                navigate('/thank-you');
                                setIsThankYouTwo(true);
                            })
                            .catch(function (error) {
                                // console.log(error)
                                setIsErrorTwo(
                                error?.message ? error.message : "Network Error"
                                );
                            })
                            .finally(() => {
                                setBtnLoaderTwo(false);
                                setTimeout(myGreeting, 5000);
                                values.email = "";
                            });
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form
                            onSubmit={handleSubmit}
                            className=" "
                            id=""
                            >
                            <label htmlFor="">Your name</label>
                            <input type="text" autoCapitalize="none" autoComplete="new-password" onChange={handleChange} onBlur={handleBlur} name="name" value={values.name} />
                            {errors.name && touched.name && (
                                <div
                                    style={{
                                    color: `rgb(255, 0, 0)`,
                                    marginTop: `2px`,
                                    }}
                                >
                                    {errors.name}
                                </div>
                                )}

                            <label htmlFor="">Your company email</label>
                            <input type="email" autoCapitalize="none" autoComplete="new-password" onChange={handleChange} onBlur={handleBlur} name="email" value={values.email} />
                            {errors.email && touched.email && (
                                <div
                                    style={{
                                    color: `rgb(255, 0, 0)`,
                                    marginTop: `2px`,
                                    }}
                                >
                                    {errors.email}
                                </div>
                                )}

                            <button type="submit" className="link_up">
                                book a call with us 
                                {btnLoaderTwo && (
                                <span className="btn-ring-animation"></span>
                                )}
                            </button>
                            </form>
                        )}
                        </Formik>

                        {isThankYouTwo && (
                            <div className="alert alert-success mt-3">
                            Thank you for your interest.
                            </div>
                        )}
                        {isErrorTwo && (
                            <div style={{ color: `rgb(255, 0, 0)`, marginTop: `2px` }}>
                            {isErrorTwo}
                            </div>
                        )}

                    </div>
                    </div>
                </div>
                </div>
            </section>

            <footer>
                <div className="container">
                <div className="wap">
                    <div className="item">
                    <a href="#" className="frt-logo">
                        <img src={FooterLogo} alt="" />
                    </a>
                    </div>
                    <div className="item">
                    <a href="tel:+1 678 608 2725" className="call-btn">
                        <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
                            <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"/>
                        </svg>
                        </span>
                        <span className="number-box">
                            (678) 608-2725
                        </span>
                    </a>
                    </div>

                    <div className="item">
                    <p>
                        <a onClick={() => HandelGuidelineModal()}>
                        Privacy Policy
                        </a>
                    </p>
                    </div>

                </div>
                </div>
                <div className="copy-riht">
                <div className="container">
                    <p>ReddenSoft Copyright 2023. All rights reserved.</p>
                </div>
                </div>
            </footer>

            <Transition 
                show={messageModel}
                enter="ease-out duration-300"
                enterFrom="opacity-0 relative-modal"
                enterTo="opacity-100 relative-modal"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 relative-modal"
                leaveTo="opacity-0 relative-modal">
                <ScheduleConsultation isSchedule={false} isFree={isStartFreeTrial} toggleModal={toggleModal}/>
            
            </Transition>
        
            <Transition 
                show={scheduleModel}
                enter="ease-out duration-300"
                enterFrom="opacity-0 relative-modal"
                enterTo="opacity-100 relative-modal"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 relative-modal"
                leaveTo="opacity-0 relative-modal">
                <ScheduleConsultation isSchedule={true} toggleModal={toggleModal}/>
            
            </Transition>

            <Transition 
                show={guidelineModal}
                enter="ease-out duration-300"
                enterFrom="opacity-0 relative-modal"
                enterTo="opacity-100 relative-modal"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 relative-modal"
                leaveTo="opacity-0 relative-modal">
                    <div className='Guideline-modal'>

                        <div className='Guideline-modal-box' ref={Modalref}>
                        <button className='inline-btn' onClick={() => HandelGuidelineModal()} type='button'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20px"  height="20px">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                        <div className="Guideline-modal-inside">
                            <h2>Privacy Policy</h2>

                            <p>
                            This privacy policy of ReddenSoft declares the process and purpose of collecting/ storing/ sharing your data while you are using our services.
                            </p>

                            <p>
                            We recommend you read this entire privacy policy thoroughly to get a clear picture of your rights and choices. Using our services means you are agreeing with the privacy policy we have.
                            </p>

                            <h4>
                            Information that we collect:-
                            </h4>

                            <h6>
                                Personal information
                            </h6>

                            <p>
                            We collect the basic information from you like your name, phone number, email id, and website/ name of the organization while you schedule a meeting with us or ask for a partnership program. The purpose is to further communicate with you and get your job done.
                            </p>

                            <h6>
                            Non-personal information
                            </h6>
                            <p>
                            Collecting non-personal identification information about Users happens when they interact with our Site. It may include the type of computer, browser name, and technical information about the user while accessing our site (i.e., the operating system and the Internet service providers used) and other similar information.
                            </p>

                            <h6>
                            Use of collected information:-
                            </h6>

                            <ul className='list-items'>
                                <li>For the betterment of customer service</li>
                                <li>Providing you with the best-personalized user experience</li>
                                <li>For the Site improvement </li>
                                <li>For Payment Processing</li>
                                <li>For the improvement of promotion/ contest/ survey / other features of the Site </li>
                                <li>For sending periodic emails</li>
                            </ul>

                            <h6>
                            Media
                            </h6>

                            <p>
                            We recommend not sharing inappropriate or sensitive images or documents on the website. On the contrary, using images or documents from the website is strictly prohibited and it could be penalized under copyright law.
                            </p>

                            <h6>
                            Embedded content from other websites
                            </h6>

                            <p>
                            Articles on this site may include embedded content (e.g., videos, images, articles, etc.) from other third-party websites. Embedded content from other websites behaves exactly the same way it appeared on the original website.
                            </p>
                            <p>
                            Those third-party we bsites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content (including tracking your interaction with the embedded content) if you are already logged into those websites.
                            </p>

                            <h6>
                            Cookies policy
                            </h6>

                            <p>
                            Our website may use "cookies" to enhance the visitor’s experience. Cookies are being placed by the web browser on their hard drive for record-keeping purposes and sometimes to track information about them. Users may opt to set their web browser to refuse cookies or give them an alert when cookies are being sent. If they do so, then some parts of the website may not function properly.
                            </p>
                            <p>
                            Visitors of our website can subscribe to different services like subscribing to blogs, starting a project with us or joining the partnership program, and for that, we need your name, email address, and website information to store in cookies. These are for your convenience so that you do not have to fill in your details again when you go for other services on the website. These cookies will last for one year.
                            </p>

                            <h6>
                            Information Sharing
                            </h6>
                            <p>
    We strictly oppose sharing information with any third party. Information shared with us will only be used for the betterment of your project and it will be kept only with us. We also request you to not disclose any confidential statement about the ongoing project with any other website or third-party domain.
                            </p>
                        </div>
                        </div>
                    </div>  
            </Transition>

            <Transition
                show={isCaseStudies}
                enter="ease-out duration-300"
                enterFrom="opacity-0 relative-modal"
                enterTo="opacity-100 relative-modal"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 relative-modal"
                leaveTo="opacity-0 relative-modal"
            >
                <CaseStudiesModal modalName="designers" toggleModal={toggleModal} />
            </Transition>

        </section>
    );
}

export default Bestdesign;
