import React, { useState, useEffect, useRef } from "react";
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import {
  HeaderLogo,
  FooterLogo,
  webLandPageOne,
  webLandPageTwo,
  webLandPageThree,
  webLandPageFour,
  webLandPageFive,
  webLandPageSix,
  webLandPageSaven,
  batchone,
  batchtwo,
  Bannerbtmlogo1,
  Bannerbtmlogo5,
  Bannerbtmlogo2,
  Bannerbtmlogo3,
  designarrow,
  Eric,
  David,
  Michel,
  PGFavors,
  ADukes,
  Kimberly,
  dudaDeveloper1,
  dudaDeveloper2,
  dudaDeveloper3,
  dudaDeveloper4,
  dudaDeveloper5,
  ReviewFullStart,
  batchDuda
} from "../../Assets/index";
import Slider from "react-slick";
import { Transition } from "@headlessui/react";
import ScheduleConsultation from "../ScheduleConsultation";
import WebFont from "webfontloader";
import "./App.css";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import axios from "axios";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { CSSTransition } from 'react-transition-group';

import { Link, useNavigate } from "react-router-dom";

function HireDuda() {
  var _ = require('lodash');

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const settings3 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false,
        }
      }
    ]
  };

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Roboto Slab:200,300,400",
          "Montserrat:300,400,500,700",
          "Patua One",
          "Mulish",
        ], // Fonts you want to load
      },
      custom: {
        families: ["Patua One"], // Optional: If you want to load a custom font, specify its name here
        urls: [
          "https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400&display=swap",
          "https://fonts.googleapis.com/css2?family=Montserrat:wght@300,400;500;700&display=swap",
          "https://fonts.googleapis.com/css2?family=Mulish&family=Patua+One&display=swap",
        ], // URLs of the font CSS files
      },
    });

  }, []);

  const [scheduleModel, setScheduleModel] = useState(false);
  const [messageModel, setMessageModel] = useState(false);
  const [isTry, setIsTry] = useState();

  const [ isStartFreeTrial, setIsStartFreeTrial ] = useState(false);

  const HandelMessageModel = (e, val) => {
    if (val) {
      setIsTry(val);
    } else {
      setIsTry();
    }

    if(val === 'Try Free for 7 Days'){
      setIsStartFreeTrial(true);
    }else{
      setIsStartFreeTrial(false);
    }

    setMessageModel(true);
  };

  const toggleModal = () => {
    setScheduleModel(false);
    setMessageModel(false);
  };

  const [isThankYouTwo, setIsThankYouTwo] = useState(false);
  const [isErrorTwo, setIsErrorTwo] = useState(false);
  const [btnLoaderTwo, setBtnLoaderTwo] = useState(false);

  function myGreeting() {
    setIsThankYouTwo(false);
    setIsErrorTwo(false);
  };
  const [guidelineModal, setGuidelineModal] = useState(false);
  const HandelGuidelineModal = () => {
      setGuidelineModal(!guidelineModal)
  };

  const navigate = useNavigate();
  const HandelScheduleModel = () => {
    setScheduleModel(true)
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isResponsiveMenu, setIsResponsiveMenu] = useState(false);


  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsResponsiveMenu(false);
      }

      if (Modalref.current && !Modalref.current.contains(event.target)) {
        setGuidelineModal(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const dropdownRef = useRef(null);
  const pricing = useRef(null);
  const faqMenu = useRef(null);
  const testmonialsMenu = useRef(null);
  const homeMenu = useRef(null);
  const portFolio = useRef(null);
  const Modalref = useRef(null);



  const [activeIndex, setActiveIndex] = useState(0);
  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  const accordionItems = [
    {
      id: 1,
      title: "What support will I get from Duda Specialists? ",
      content: "Our Duda specialists will help you out to get the most business-efficient and contemporary web templates that are being designed using duda web builder. For any further website customization, our expert team will reach out to you and get it done.",
    },
    {
      id: 2,
      title: "What will be the working hours to complete my project?",
      content: "20 hours weekly is to be contributed by the part-time remote talents and 40 hours weekly is to be maintained by the full-time talents. And for further flexibility, you can take small steps for 1 hour per day. In case of urgency, if you need additional help from the developer, yes we can work for you over the weekend.",
    },
    {
      id: 3,
      title: "Is the 7-days free trial completely free?",
      content: "Yes, it's entirely free for your convenience. We permit this free trial to evaluate your satisfactory work. You get to hire the trial resource and if not desirable, we will assign one more resource to match your expectations. If nothing suits you, then feel free to cancel it right away.",
    },

    {
      id: 4,
      title: "Do I get the chance to work directly with the Duda Specialists?",
      content: "Yes, you get the chance to work directly with our team as this allows them for crystal clear understanding and can match your project’s requirements.",
    },
    {
      id: 5,
      title: "What are the pricing packages?",
      content: "For part-time remote hiring, $20.00/ hour is fixed, and for full-time remote talent, $15.00/ hour.",
    },
  ];

  const cvData = {
    Dipak:{
      Name:'Dipak Tiwery',
      Experience: '4+ Years of experience  in Duda',
      Skill:['Duda Platform', 'Drag-and-Drop Editor','Custom Widget', 'HTML', 'CSS', 'JavaScript',
      'Responsive Design', 'Version Control'],
      WorkExprience:[
        {Name:'Reddensoft Infotech Pvt. Ltd.', des:'DUDA Consultant (Jan 2019 - Contd.)'}
      ],
      Project:['https://www.fiberglassspecialtiesnd.com'],
      Responsibilities:[
        'Create and develop websites using the Duda platform.', 
        'Customize Duda templates to align with the branding and design guidelines.' ,
        'Ensure that websites built on Duda are responsive and display properly on various devices.',
        'Add and configure widgets and third-party integrations, such as contact forms, social media feeds, calendars, and more.'
      ],
      Education:[
        {Name:'Bachelor of Technology', des:'(Electronics & Telecommunication)'},
      ]
    },
    Sumit:{
      Name:'Sumit Santra',
      Experience: '3+ Years of experience in Duda',
      Skill:['Duda Platform', 'Drag-and-Drop Editor','Custom Widget', 'HTML', 'CSS', 'JavaScript',
      'Responsive Design', 'Version Control'],
      WorkExprience:[
        {Name:'Reddensoft Infotech Pvt. Ltd.', des:'Duda Developer (Sep 2020 - Contd.)'}
      ],
      Project:['https://iron-works.multiscreensite.com'],
      Responsibilities:[
        'Create and develop websites using the Duda platform.', 
        'Customize Duda templates to align with the branding and design guidelines.' ,
        'Ensure that websites built on Duda are responsive and display properly on various devices.',
        'Add and configure widgets and third-party integrations, such as contact forms, social media feeds, calendars, and more.'
      ],
      Education:[
        {Name:'Master of Science', des:'(Computer Science)'},
      ]
    },
    Kallol:{
      Name:'Kallol Sarkar',
      Experience: '5+ Years of experience in Duda',
      Skill:['Duda Platform', 'Drag-and-Drop Editor','Custom Widget', 'HTML', 'CSS', 'JavaScript',
      'Responsive Design', 'Version Control'],
      WorkExprience:[
        {Name:'Reddensoft Infotech Pvt. Ltd.', des:'Duda Consultant (May 2018 - Contd.)'}
      ],
      Project:['https://www.osc.ai'],
      Responsibilities:[
        'Create and develop websites using the Duda platform.', 
        'Customize Duda templates to align with the branding and design guidelines.' ,
        'Ensure that websites built on Duda are responsive and display properly on various devices.',
        'Add and configure widgets and third-party integrations, such as contact forms, social media feeds, calendars, and more.'
      ],
      Education:[
        {Name:'MCA', des:'(Master in Computer Application)'},
      ]
    },
    Sandip:{
      Name:'Sandip Baur',
      Experience: '2+ Years of experience in Duda',
      Skill:['Duda Platform', 'Drag-and-Drop Editor','Custom Widget', 'HTML', 'CSS', 'JavaScript',
      'Responsive Design', 'Version Control', ],
      WorkExprience:[
        {Name:'Reddensoft Infotech Pvt. Ltd.', des:'Duda Developer (Aug 2021 - Contd.)'}
      ],
      Project:['https://www.222hennepin.com'],
      Responsibilities:[
        'Create and develop websites using the Duda platform.', 
        'Customize Duda templates to align with the branding and design guidelines.' ,
        'Ensure that websites built on Duda are responsive and display properly on various devices.',
        'Add and configure widgets and third-party integrations, such as contact forms, social media feeds, calendars, and more.'
      ],
      Education:[
        {Name:'BCA', des:'(Bachelor in Computer Application)'},
      ]
    },
    Arijit:{
      Name:'Arijit Das',
      Experience: '3+ Years of experience in Duda',
      Skill:['Duda Platform', 'Drag-and-Drop Editor','Custom Widget', 'HTML', 'CSS', 'JavaScript',
      'Responsive Design', 'Version Control'],
      WorkExprience:[
        {Name:'Reddensoft Infotech Pvt. Ltd.', des:'Duda Developer (Jun 2020 - Contd.)'}
      ],
      Project:['https://www.tower12.com'],
      Responsibilities:[
        'Create and develop websites using the Duda platform.', 
        'Customize Duda templates to align with the branding and design guidelines.' ,
        'Ensure that websites built on Duda are responsive and display properly on various devices.',
        'Add and configure widgets and third-party integrations, such as contact forms, social media feeds, calendars, and more.'
      ],
      Education:[
        {Name:'B.Tech', des:'(Bachelor of Technology)'},
      ]
    }
  };

  const [isCvmodal, seticCvmodal] = useState(false);
  const [isCvmodalActive, seticCvmodalActive] = useState('')
  const HandelCvModal = (val) => {
    seticCvmodalActive(val)
    seticCvmodal(true);
  };

  const cvRef = useRef(null)

  const handleClickOutside = (event) => {
    if (cvRef.current && !cvRef.current.contains(event.target)) {
      seticCvmodal(false); 
    };
 
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <section className="sales-page graphics hair-duda">
      <Helmet>
        <title>Hire A Duda Developer</title>
        <script id="facebook-pixel-script">
          {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js'),
                fbq('init', '273096641131718'),
                fbq('track', 'PageView')
                `}
        </script>
        <noscript id="facebook-pixel-image">
          {`
                    <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=273096641131718&ev=PageView&noscript=1"
                />
                `}
        </noscript>
      </Helmet>

      <section className="banner">
        
          <div className={` header-section ${scrollPosition > 50 ? "fixed-header slide-in" : ""}`}>
            <div className="container">
              <div className="header-part">
                <a className="logo" onClick={() => homeMenu.current?.scrollIntoView({behavior: 'smooth'})}>
                  <img src={HeaderLogo} alt="Reddensoft logo" />
                </a>
                <div ref={dropdownRef} className={` ${isResponsiveMenu ? 'active':''}  header_middle`}>
                  <ul>
                    <li><a onClick={() => {portFolio.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Our Portfolio</a></li>
                    <li><a onClick={() => {pricing.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Pricing</a></li>
                    <li><a onClick={() => {testmonialsMenu.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Testimonial</a></li>
                    <li><a onClick={() => {faqMenu.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">FAQs</a></li>
                  </ul>
                </div>

                <div className="header_right">
                  <a onClick={(e) => HandelMessageModel(e, 'Try Free for 7 Days')}
                    className="full-stack-top-btn "
                  >
                    <span>Try Free for 7 Days</span>
                  </a>

                  <a onClick={(e) => HandelScheduleModel(e, 'Schedule a Call')}
                    className="full-stack-top-btn fill-btn mobile-none"
                  >
                    <span>Schedule a Call</span>
                  </a>
                  

                  <button className="bars-menu" ref={dropdownRef} onClick={() => setIsResponsiveMenu(!isResponsiveMenu)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={22} height={22}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                  </button>

                </div>
              </div>
            </div>
          </div>
          
          <div className="container">
          <div className="banner-section">
            <div className="banner-center text-center">
              <p>
                Unleash the Power of Duda for Your Business
              </p>
              <h2>Hire The Best <span>Duda</span> Specialists</h2>

              <h5>
                Build your team with passionate and experienced Duda Specialists for your 
                Mobile & Web Application starting from <span> $15.00 /hour</span>.
              </h5>

              <div className="banner-feture banner_featur_width">
                <div className="banner-first-feture">
                  <p>Get 7 Days Free Trial</p>
                </div>

                <div className="banner-second-feture">
                  <p>Quick Team Set-up & Onboarding</p>
                </div>

                <div className="banner-first-feture">
                  <p>No Expense on Retaining & Training</p>
                </div>

                <div className="banner-second-feture">
                  <p>IP Security and Ownership</p>
                </div>

                <div className="banner-first-feture">
                  <p>Quick Product Delivery</p>
                </div>

                <div className="banner-second-feture">
                  <p>
                    Dedicated Project Manager
                    <span className="image-text">Free</span>
                  </p>
                </div>
              </div>
              
              <button className="duda-designer-btn hair-designer-btn" onClick={(e) => HandelMessageModel(e, 'HIRE A DUDA DEVELOPER')}>HIRE A DUDA DEVELOPER</button>
              <button className="duda-designer-btn hair-designer-btn schedule-btn-des-none" onClick={(e) => HandelScheduleModel(e, 'Schedule a Call')}>SCHEDULE A CALL</button>
              
            
              <div className="hiredesigner_batch">
                <div className="hiredesigner_batchsub">
                  <img src={batchone} alt="" />
                </div>
                <div className="hiredesigner_batchsub">
                  <img src={batchDuda} alt="" />
                </div>
                <div className="hiredesigner_batchsub">
                  <img src={batchtwo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='hiredesigner_client'>
          <p class="banner-location">Working with Clients across<span>  &nbsp; <img src={designarrow} alt="" /> &nbsp; 
          California &nbsp;|&nbsp; Texas  &nbsp;|&nbsp; Illinois &nbsp;|&nbsp; Florida &nbsp;|&nbsp; New York 
                  &nbsp;|&nbsp; Toronto &nbsp;|&nbsp; Montreal &nbsp;|&nbsp; Vancouver &nbsp;|&nbsp; Calgary &nbsp;|&nbsp; Mexico City &nbsp;|&nbsp; World Wide</span></p>
        </div>

        <div className="hair_designer_bannerbtm_boeder">
          <div className="hair_designer_bannerbtm">
            <div className="hair_designer_bannerbtm_box">
              <img src={Bannerbtmlogo5} alt="" />
              <h3>We are rated 4.4 out of 5 on  average across 125 reviews</h3>
            </div>
            <div className="hair_designer_bannerbtm_box">
              <img src={Bannerbtmlogo1} alt="" />
              <h3>We are rated 4.5 out of 5 on  average across 120 reviews</h3>
            </div>
            <div className="hair_designer_bannerbtm_box">
              <img src={Bannerbtmlogo2} alt="" />
              <h3>We are rated 4.5 out of 5 on  average across 100 reviews</h3>
            </div>
            <div className="hair_designer_bannerbtm_box">
              <img src={Bannerbtmlogo3} alt="" />
                <h3>We are rated 4.0 out of 5 on  average across 110 reviews</h3>
            </div>
          </div>
        </div>

      </section>

      <section className="expert__designer">
        <div className="container">
          <div className="title">
            <h2>Expert <span>Duda</span> <mark>Designer</mark> & <mark>Developers</mark>  to Build Powerful Web Experiences!</h2>
          </div>
          <div className="d-flex-wap">
            <div className="divider divider_first_child">
                <p><span>Duda website builder</span> is a dynamic and breakthrough business platform that overcomes the conventional ways of <span>creating websites.</span> The essence of our <span>Duda Custom Development services</span> is that we yield modern business websites oriented towards conversions.</p>
                <a onClick={(e) => HandelMessageModel(e, 'Hire A Duda Expert')}>Hire A Duda Expert</a>
            </div>
            <div className="divider">
                <div className="circle__rnbo">
                  <div className="ricl-item">
                    <p>Duda CMS <br/> Development</p>
                  </div>
                  <div className="ricl-item">
                    <p>Duda Website <br/> Development</p>
                  </div>
                  <div className="ricl-item">
                    <p>Duda Website <br/> Development</p>
                  </div>
                  <div className="ricl-item">
                    <p>Conversional <br/> design layout for <br/> your business <br/> website</p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our__works" ref={portFolio}>
          <div className="top_bar">
            <div className="container">
              <div className="title">
                <h2>Our <span>Works</span> </h2>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="webSlider">
              <Slider {...settings}>
                <div>
                  <div className="tham_img portfolio-img">
                    <img src={webLandPageOne} alt="Reddensoft logo" />
                  </div>
                </div>
                
                <div>
                  <div className="tham_img portfolio-img">
                    <img src={webLandPageTwo} alt="Reddensoft logo" />
                  </div>
                </div>

                <div>
                  <div className="tham_img portfolio-img">
                    <img src={webLandPageThree} alt="Reddensoft logo" />
                  </div>
                </div>

                <div>
                  <div className="tham_img portfolio-img">
                    <img src={webLandPageSaven} alt="Reddensoft logo" />
                  </div>
                </div>

                <div>
                  <div className="tham_img portfolio-img">
                    <img src={webLandPageFour} alt="Reddensoft logo" />
                  </div>
                </div>

                <div>
                  <div className="tham_img portfolio-img">
                    <img src={webLandPageFive} alt="Reddensoft logo" />
                  </div>
                </div>

                <div>
                  <div className="tham_img portfolio-img">
                    <img src={webLandPageSix} alt="Reddensoft logo" />
                  </div>
                </div>
              </Slider>
            </div>
          </div>
      </section>

      <section className="mind_blowing">
        <div className="container">
          <div className="title">
            <h3>Mind Blowing Facts on Duda</h3>
          </div>
          <div className="d-flex-wap">
            <div className="divider">
                <div className="item-one">
                  <h4>Duda <br/> sites gets <br/> over</h4>
                  <h2>100 <span>Million</span> </h2>
                  <p>monthly visits</p>
                </div>
            </div>
            <div className="divider">
                <div className="item-two">
                  <h4>Over</h4>
                  <h2>450,000</h2>
                  <p>companies use <span>Duda</span> </p>
                </div>
            </div>
            <div className="divider">
                <div className="item-one item-three">
                  <h4>There are <br/> over</h4>
                  <h2>14 <span>Million</span> </h2>
                  <p>sites built on <span>Duda</span></p>
                </div>
            </div>
          </div>
        </div>
      </section>


      <section className="your-customers">
        <div className="container">
          <div className="text-area">
            <h5>Our Customers</h5>
            <h2>Deserve the Best Experience </h2>
            <button onClick={(e) => HandelMessageModel(e, 'Let’s Pick your Duda Team')} className="your-customers-btn">Let’s Pick your Duda Team</button>
          </div>
          <div className="your-customers-white-text-box">
            <h5>
              Online businesses seek high-performing websites that initiate to increase market profitability. 
              We emphasize on maintaining website-build for businesses to support their effective and sustainable growth.
            </h5>
            <h4> 
              Ready to take the first step?  
            </h4>
          </div>
        </div>
      </section>

      <section className="duda_work_with">
        <div className="container">
          <div className="title">
            <h2>
              Let’s take the first step to pick a <span> Duda </span>  Specialist! 
            </h2>
          </div>
          <div className="webSlider">
            <Slider {...settings2}>
              <div>
                <div className="duda-developer-img">
                  <img src={dudaDeveloper1} alt="Reddensoft logo" />
                  <div className="duda-developer-text">
                    <h5>Dipak Tewary</h5>
                    <p>4+ Years of experience in Duda</p>
                    <button className="hire_duda_designer_btn" onClick={()=>HandelCvModal('Dipak')}>View CV</button>
                  </div>
                </div>
              </div>
              <div>
                <div className="duda-developer-img">
                  <img src={dudaDeveloper2} alt="Reddensoft logo" />
                  <div className="duda-developer-text">
                    <h5>Sumit Santra</h5>
                    <p>3+ Years of experience in Duda</p>
                    <button className="hire_duda_designer_btn" onClick={()=>HandelCvModal('Sumit')}>View CV</button>
                  </div>
                </div>
              </div>
              <div>
                <div className="duda-developer-img">
                  <img src={dudaDeveloper3} alt="Reddensoft logo" />
                  <div className="duda-developer-text">
                    <h5>Kallol Sarkar</h5>
                    <p>5+ Years of experience in Duda</p>
                    <button className="hire_duda_designer_btn" onClick={()=>HandelCvModal('Kallol')}>View CV</button>
                  </div>
                </div>
              </div>
              <div>
                <div className="duda-developer-img">
                  <img src={dudaDeveloper4} alt="Reddensoft logo" />
                  <div className="duda-developer-text">
                    <h5>Sandip Baur</h5>
                    <p>2+ Years of experience in Duda</p>
                    <button className="hire_duda_designer_btn" onClick={()=>HandelCvModal('Sandip')}>View CV</button>
                  </div>
                </div>
              </div>
              <div>
                <div className="duda-developer-img">
                  <img src={dudaDeveloper5} alt="Reddensoft logo" />
                  <div className="duda-developer-text">
                    <h5>Arijit Das</h5>
                    <p>3+ Years of experience in Duda</p>
                    <button className="hire_duda_designer_btn" onClick={()=>HandelCvModal('Arijit')}>View CV</button>
                  </div>
                </div>
              </div>
            </Slider>
          </div>

        </div>
      </section>

      <section className="duda-testmonial" ref={testmonialsMenu}>
        <div className="container">
          <h1>Our Amazing Customer <span> Success Stories</span></h1>
          <Slider {...settings3}>
              <div>
                <div className="duda-testmonial-box">
                  <div className="duda-testmonial-text-box">
                      <p>
                        “I always wanted to open my art and craft sessions for all people. Reddensoft’s Duda Experts have helped me out 
                        to build a strong online presence in the market.”
                      </p>
                      <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                  </div>
                  <div className="duda-testmonial-user-details">
                    <div className="duda-testmonial-user-img">
                      <img src={Eric} alt="Reddensoft logo" />
                    </div>
                    <div className="duda-testmonial-user-name">
                      <h5>Mike Boggus</h5>
                      <p>Entrepreneur</p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="duda-testmonial-box">
                  <div className="duda-testmonial-text-box">
                      <p>
                        “I have been able to open my restaurant’s website by picking web templates that represent creative designs and 
                        manage my website’s USPs. Reddensoft gave us an extra mileage to stand out from the crowd.”
                      </p>
                      <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                  </div>
                  <div className="duda-testmonial-user-details">
                    <div className="duda-testmonial-user-img">
                      <img src={David} alt="Reddensoft logo" />
                    </div>
                    <div className="duda-testmonial-user-name">
                      <h5>David Miller</h5>
                      <p>Entrepreneur</p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="duda-testmonial-box">
                  <div className="duda-testmonial-text-box">
                      <p>
                        “Why to waste time! When I got to have such impressive designs by Duda Web Builder. 
                        The Duda Experts of Reddensoft guided me have professional templates to flourish my 
                        online business workflow.” 
                      </p>
                      <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                  </div>
                  <div className="duda-testmonial-user-details">
                    <div className="duda-testmonial-user-img">
                      <img src={Michel} alt="Reddensoft logo" />
                    </div>
                    <div className="duda-testmonial-user-name">
                      <h5>Michel Ambrosio</h5>
                      <p>Founder @Quirkel</p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="duda-testmonial-box">
                  <div className="duda-testmonial-text-box">
                      <p>
                        “Duda Experts have been a great help as it assisted me to select exclusive web templates before 
                        I launched my business of music blogging in the market.” 
                      </p>
                      <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                  </div>
                  <div className="duda-testmonial-user-details">
                    <div className="duda-testmonial-user-img">
                      <img src={PGFavors} alt="Reddensoft logo" />
                    </div>
                    <div className="duda-testmonial-user-name">
                      <h5>PG Favors</h5>
                      <p>Entrepreneur</p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="duda-testmonial-box">
                  <div className="duda-testmonial-text-box">
                      <p>
                        “Getting effective web solutions is quite challenging but not with Reddensoft. 
                        I have chosen templates that offer profit for my blog posts and equally cater my audience.” 
                      </p>
                      <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                  </div>
                  <div className="duda-testmonial-user-details">
                    <div className="duda-testmonial-user-img">
                      <img src={ADukes} alt="Reddensoft logo" />
                    </div>
                    <div className="duda-testmonial-user-name">
                      <h5>A Dukes</h5>
                      <p>Entrepreneur</p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="duda-testmonial-box">
                  <div className="duda-testmonial-text-box">
                      <p>
                        “I am overwhelmed with web templates provided by Reddensoft that have assisted me to
                         begin my business campaign online effectively.”
                      </p>
                      <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                  </div>
                  <div className="duda-testmonial-user-details">
                    <div className="duda-testmonial-user-img">
                      <img src={Kimberly} alt="Reddensoft logo" />
                    </div>
                    <div className="duda-testmonial-user-name">
                      <h5>Kimberly</h5>
                      <p>Entrepreneur</p>
                    </div>
                  </div>
                </div>
              </div>

          </Slider>  
        </div>
      </section>


      <section className="pricing_scaling" id="target-section" ref={pricing}>
        <div className="container">
          <div className="head-title">
            <h2>Easy and flexible <span className="color"> pricing </span> to form a team</h2>
            <p>
              Spend on what you need and get your project done with the most lucrative pricing plans.
            </p>
          </div>
          <div className="wrapper">
            <div className="divider">
              <div className="top-bar">
                <h3>Hire (Hourly)</h3>
                <p>Remote talent</p>
              </div>
              <div className="bottom-bar">
                <div className="scr_circle">
                  <h2>
                    <span>From</span>
                    $20
                    <span>/Hour</span>
                  </h2>
                </div>
                {/* <h4>Hourly (USD)</h4> */}

                <h4>
                  We’ll provide a fully signed NDA for your Project’s confidentiality
                </h4>

                <a onClick={(e) => HandelMessageModel(e, 'Hire (Hourly)')} className="link_up">
                  get started
                </a>
                
              </div>
            </div>

            <div className="divider">
              <div className="top-bar">
                <h3>Hire (Monthly)</h3>
                <p>Remote talent</p>
              </div>
              <div className="bottom-bar">
                <div className="scr_circle">
                  <h2>
                    <span>From</span>
                    $2850
                    <span>/Month</span>
                  </h2>
                </div>
                <h4>3 to 5 Years of Exp. Senior Duda Developer - 160 hours.</h4>   
                <a onClick={(e) => HandelMessageModel(e, 'Hire (Monthly)')} className="link_up">
                  get started
                </a>           
              </div>
            </div>

            <div className="divider">
              <div className="top-bar">
                
                <h3>Need more flexibility?</h3>
                <p>[ You can start as little as 1 hr per day ]</p>
              </div>
              <div className="bottom-bar">

                <div className="scr_circle">
                  <h2>
                    <span>Fixed Cost Solution</span>
                   
                  </h2>
                </div>
                
                <h4>Get a Free Estimation based on Your Custom Requirements.</h4>
                
                <a onClick={(e) => HandelMessageModel(e, 'Need more flexibility?')} className="link_up">
                  get started
                </a>
              </div>
            </div>
          </div>

            <div className="price-btn">
              <button onClick={(e) => HandelMessageModel(e, 'Need more flexibility?')} className="btn-stack">
                SCHEDULE AN INTERVIEW
              </button>
            </div>

        </div>
        
      </section>


      <section className="duda-faq">
        <div className="container">
          <div ref={faqMenu} className="faq-section"></div>
          <h3>Frequently Asked Questions</h3>
          <div className="acordin-sec">
            <div className="accordion">
              <div className="accordion-half">
                {accordionItems.slice(0, 3).map((item, index) => (
                  <div key={index} className="accordion-item">
                    <button
                      className={`accordion-title accordion-item-header ${
                        activeIndex === item.id ? "active" : ""
                      }`}
                      onClick={() => handleAccordionClick(item.id)}
                    >
                      {item.title}
                    </button>
                     
                      <CSSTransition in={activeIndex === item.id} >
                        {state => (
                          <div
                            style={{
                              maxHeight: state === 'entered' ? '500px' : '0',
                              overflow: 'hidden',
                              transition: 'max-height 0.2s ease-in-out',
                            }}
                          >
        
                            <div className="accordion-content">{item.content}</div>
                          </div>
                        )}
                      </CSSTransition>
                  </div>
                ))}

              </div>

              <div className="accordion-half">
                {accordionItems.slice(3, 5).map((item, index) => (
                  <div key={index} className="accordion-item">
                    <button
                      className={`accordion-title ${
                        activeIndex === item.id ? "active" : ""
                      }`}
                      onClick={() => handleAccordionClick(item.id)}
                    >
                      {item.title}
                    </button>
                      
                      <CSSTransition in={activeIndex === item.id} >
                        {state => (
                          <div
                            style={{
                              maxHeight: state === 'entered' ? '700px' : '0',
                              overflow: 'hidden',
                              transition: 'max-height 0.2s ease-in-out',
                            }}
                          >
        
                            <div className="accordion-content">{item.content}</div>
                          </div>
                        )}
                      </CSSTransition>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact_ups">
        <div className="container">
          <div className="contact_content">
            <div className="ttx">
              <h2>Hire Duda developers on hourly basis and get your project done. </h2>
              <p>
                We ensure faster delivery of your product by Duda developers. Starting from $15/hr only.{" "}
              </p>
              <h5>Our expert will help you out to organize your project requirement:</h5>
              <ul>
                <li>Set the minimal plan to get it done</li>
                <li>
                  Ensure a faster delivery{" "}
                </li>
                
              </ul>
            </div>
            <div className="frm-box-rp">
              <div className="frm-box">
                <h3>Not sure where to start?</h3>
                <p>Let’s have a chat</p>
                <Formik
                  initialValues={{ name:"", email: "", tryNow: "Not sure where to start" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = "Email is required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }

                    if (!values.name || !_.trim(values.name)) {
                      errors.name = 'Name is required';
                      } else if (values.name.length > 50) {
                      errors.name = 'Must be 15 characters or less';
                      } else if (values.name.match("[<>]")) {
                      errors.name = 'Please provide a valid name';
                      }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setBtnLoaderTwo(true);
                    setSubmitting(false);
                    axios
                      .post(`/lets-chat`, values)
                      .then(function (response) {
                        // console.log(response);
                        navigate('/thank-you');
                        setIsThankYouTwo(true);
                      })
                      .catch(function (error) {
                        // console.log(error)
                        setIsErrorTwo(
                          error?.message ? error.message : "Network Error"
                        );
                      })
                      .finally(() => {
                        setBtnLoaderTwo(false);
                        setTimeout(myGreeting, 5000);
                        values.email = "";
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className=" "
                      id=""
                    >
                      <label htmlFor="">Your name</label>
                      <input type="text" autoCapitalize="none" autoComplete="new-password" onChange={handleChange} onBlur={handleBlur} name="name" value={values.name} />
                      {errors.name && touched.name && (
                          <div
                            style={{
                              color: `rgb(255, 0, 0)`,
                              marginTop: `2px`,
                            }}
                          >
                            {errors.name}
                          </div>
                        )}

                      <label htmlFor="">Your company email</label>
                      <input type="email" autoCapitalize="none" autoComplete="new-password" onChange={handleChange} onBlur={handleBlur} name="email" value={values.email} />
                      {errors.email && touched.email && (
                          <div
                            style={{
                              color: `rgb(255, 0, 0)`,
                              marginTop: `2px`,
                            }}
                          >
                            {errors.email}
                          </div>
                        )}

                      <button type="submit" className="link_up">
                        book a call with us 
                        {btnLoaderTwo && (
                          <span className="btn-ring-animation"></span>
                        )}
                      </button>
                    </form>
                  )}
                  </Formik>

                  {isThankYouTwo && (
                    <div className="alert alert-success mt-3">
                      Thank you for your interest.
                    </div>
                  )}
                  {isErrorTwo && (
                    <div style={{ color: `rgb(255, 0, 0)`, marginTop: `2px` }}>
                      {isErrorTwo}
                    </div>
                  )}

              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="wap">
            <div className="item">
              <a href="#" className="frt-logo">
                <img src={FooterLogo} alt="" />
              </a>
              
             
              
            </div>
            {/* <div className="item">
              
              <p><a href="mailto:info@reddensoft.com">info@reddensoft.com</a></p>
            </div> */}
            <div className="item">
              <a href="tel:+1 678 608 2725" className="call-btn">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
                    <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"/>
                  </svg>
                </span>
                <span className="number-box">
                  <small>Call Us</small><br/>
                    +1 678 608 2725
                </span>
              </a>
            </div>

            <div className="item">
              <p>
                <a onClick={() => HandelGuidelineModal()}>
                  Privacy Policy
                </a>
              </p>
            </div>

          </div>
        </div>
        <div className="copy-riht">
          <div className="container">
            <p>ReddenSoft Copyright 2023. All rights reserved.</p>
            {/* <ul>
              
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              
            </ul> */}
          </div>
        </div>
      </footer>

      <Transition
        show={messageModel}
        enter="ease-out duration-300"
        enterFrom="opacity-0 relative-modal"
        enterTo="opacity-100 relative-modal"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 relative-modal"
        leaveTo="opacity-0 relative-modal"
      >
        <ScheduleConsultation
          isSchedule={false}
          isFree={isStartFreeTrial}
          isNewTry={isTry}
          toggleModal={toggleModal}
        />
      </Transition>

      <Transition
        show={scheduleModel}
        enter="ease-out duration-300"
        enterFrom="opacity-0 relative-modal"
        enterTo="opacity-100 relative-modal"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 relative-modal"
        leaveTo="opacity-0 relative-modal"
      >
        <ScheduleConsultation isSchedule={true} toggleModal={toggleModal} />
      </Transition>

      <Transition 
            show={guidelineModal}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal">
                <div className='Guideline-modal'>
                
               
                    <div className='Guideline-modal-box'>
                   
                    <button className='inline-btn' onClick={() => HandelGuidelineModal()} type='button'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20px"  height="20px">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <h2>Privacy Policy</h2>

                        <p>
                        This privacy policy of ReddenSoft declares the process and purpose of collecting/ storing/ sharing your data while you are using our services.
                        </p>

                        <p>
                        We recommend you read this entire privacy policy thoroughly to get a clear picture of your rights and choices. Using our services means you are agreeing with the privacy policy we have.
                        </p>

                        <h4>
                          Information that we collect:-
                        </h4>

                        <h6>
                            Personal information
                        </h6>

                        <p>
                        We collect the basic information from you like your name, phone number, email id, and website/ name of the organization while you schedule a meeting with us or ask for a partnership program. The purpose is to further communicate with you and get your job done.
                        </p>

                        <h6>
                           Non-personal information
                        </h6>
                        <p>
                        Collecting non-personal identification information about Users happens when they interact with our Site. It may include the type of computer, browser name, and technical information about the user while accessing our site (i.e., the operating system and the Internet service providers used) and other similar information.
                        </p>

                        <h6>
                          Use of collected information:-
                        </h6>

                        <ul className='list-items'>
                            <li>For the betterment of customer service</li>
                            <li>Providing you with the best-personalized user experience</li>
                            <li>For the Site improvement </li>
                            <li>For Payment Processing</li>
                            <li>For the improvement of promotion/ contest/ survey / other features of the Site </li>
                            <li>For sending periodic emails</li>
                        </ul>

                        <h6>
                        Media
                        </h6>

                        <p>
                        We recommend not sharing inappropriate or sensitive images or documents on the website. On the contrary, using images or documents from the website is strictly prohibited and it could be penalized under copyright law.
                        </p>

                        <h6>
                          Embedded content from other websites
                        </h6>

                        <p>
                          Articles on this site may include embedded content (e.g., videos, images, articles, etc.) from other third-party websites. Embedded content from other websites behaves exactly the same way it appeared on the original website.
                        </p>
                        <p>
                          Those third-party we bsites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content (including tracking your interaction with the embedded content) if you are already logged into those websites.
                        </p>

                        <h6>
                        Cookies policy
                        </h6>

                        <p>
                          Our website may use "cookies" to enhance the visitor’s experience. Cookies are being placed by the web browser on their hard drive for record-keeping purposes and sometimes to track information about them. Users may opt to set their web browser to refuse cookies or give them an alert when cookies are being sent. If they do so, then some parts of the website may not function properly.
                        </p>
                        <p>
                          Visitors of our website can subscribe to different services like subscribing to blogs, starting a project with us or joining the partnership program, and for that, we need your name, email address, and website information to store in cookies. These are for your convenience so that you do not have to fill in your details again when you go for other services on the website. These cookies will last for one year.
                        </p>

                        <h6>
                        Information Sharing
                        </h6>
                        <p>
We strictly oppose sharing information with any third party. Information shared with us will only be used for the betterment of your project and it will be kept only with us. We also request you to not disclose any confidential statement about the ongoing project with any other website or third-party domain.
                        </p>
                        
                    </div>
                </div>  
      </Transition>

      <Transition 
            show={isCvmodal}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal">
                <div className='duda_designer_profile designer_profile_popup'>
                
               
                    <div className='duda_designer_profile_box' ref={cvRef}>
                   
                    <button className='inline-btn' type='button' onClick={()=>seticCvmodal(false)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                    </button>
                    
                    <div className="duda_designer_profile_header">
                      <div className="duda_designer_profile_image">
                        <img src={isCvmodalActive === 'Dipak' ? dudaDeveloper1 : isCvmodalActive === 'Sumit' ?
                        dudaDeveloper2:isCvmodalActive === 'Kallol' ? dudaDeveloper3:isCvmodalActive === 'Sandip' ?
                         dudaDeveloper4: dudaDeveloper5
                        } alt="Reddensoft logo" />
                        </div>
                      <div className="duda_designer_profile_header_text">
                        <h3> {cvData[`${isCvmodalActive}`]?.Name} <br/> <span>{cvData[`${isCvmodalActive}`]?.Experience}</span></h3>
                      </div>
                    </div>

                    <div className="duda_designer_profile_content">
                      <h3 style={{marginTop: '10px'}}><span>Name</span></h3>
                      <p>{cvData[`${isCvmodalActive}`]?.Name}</p>

                      <h3><span>Skills</span></h3>
                      <div className="skill_tag">
                      <ul>
                      {cvData[`${isCvmodalActive}`]?.Skill.map((item,i)=>(
                      <li key={i}>{item}</li> 
                      ))}
                      </ul>
                    </div>

                    <h3><span>Work Experience</span></h3>
                    {cvData[`${isCvmodalActive}`]?.WorkExprience.map((item,i)=>(
                     <div key={i}><p><strong> {item.Name} </strong><br/> 
                     {item.des}</p></div>
                    ))}
                    

                    <h3><span>Latest Project</span></h3>
                    <div className="project_link">
                      <ul>
                        {cvData[`${isCvmodalActive}`]?.Project.map((item,i)=>(
                          <li key={i}><a href={item} target="_blank">{item}</a></li> 
                        ))}
                      </ul>
                    </div>

                    <h3><span>Responsibilities</span></h3>
                    <ul className="list-items">{cvData[`${isCvmodalActive}`]?.Responsibilities.map((items, i) => (
                      <li key={i}>{items}</li>
                    ))}</ul>


                    <h3><span>Education background</span></h3>
                    {cvData[`${isCvmodalActive}`]?.Education.map((item,i)=>(
                     <div key={i}>
                     <p><strong> {item.Name} </strong><br/> 
                     {item.des}</p>
                     </div>
                    ))}
                  </div>

                        
              </div>
          </div>  
      </Transition>

    </section>
  );
}

export default HireDuda;
