import React, { useRef, useState, useEffect } from "react";
import {
  HeaderLogo,
  FooterLogo,
  TrustedLogo1,
  TrustedLogo8,
  TrustedLogo7,
  TrustedLogo6,
  TrustedLogo5,
  TrustedLogo4,
  TrustedLogo3,
  TrustedLogo2,
  TrustedLogo9,
  TrustedLogo10,
  Trustpilot,
  googleLogo,
  Technologies1,
  Technologies2,
  Technologies4,
  Technologies3,
  Technologies5,
  Technologies6,
  Technologies7,
  Technologies8,
  Technologies9,
  Technologies10,
  Technologies11,
  Technologies12,
  Technologies13,
  Technologies14,
  Goodfirms, Clutch, Getexperticon1, Getexperticon2, Getexperticon3, Getexperticon4, OfferingIcon1, OfferingIcon2, OfferingIcon3, OfferingIcon4, OfferingIcon5, OfferingIcon6, OfferingIcon7, OfferingIcon8, WhyreddensoftRight, WhyreddensoftRightstar, ClutchN, HireExperienceWomen, batchone, batchthree, Kimberly, ADukes, PGFavors, Michel, David, Eric, ReviewFullStart, Andrew, NoImage, Bently, James, Jayson, Younges, Ryan, TrastedLogoNew, OfferingIcon9, WhatsApp, indus1, indus2, indus3, indus4, indus5, indus6, indus7, indus8, indus9, indus10, indus11, indus12, customerprofileone, customerquestion, customerprofile, customerprofiletwo, customerprofilethree, GoogleWhite,
} from "../../Assets/index";

import { Transition } from "@headlessui/react";
import ScheduleConsultation from "../ScheduleConsultation";
import WebFont from "webfontloader";
import "../FullstackDevelope/App.css";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import axios from "axios";
import Slider from 'react-slick';
import "./App.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import validator from 'validator';
import CaseStudiesModal from "../CaseStudiesModal";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { CSSTransition } from 'react-transition-group';
import ExitModal from "../ExitModal/ExitModal";





export default function WebDesignDevelopmentIndia() {
  var _ = require('lodash');

  const removeExtraSpace = (s) => {
    var rSpase = s.replace(/\s{2,}/g, ' ');
    return _.trimStart(rSpase);
  };

  const trastSlider = {
    infinite:true, 
    speed:500,
    arrows: false,
    dots: false,
    autoplay: true,
    slidesToShow:8, 
    slidesToScroll:1,
    responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 7
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3
          }
        }
    ]
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Roboto Slab:200,300,400",
          "Montserrat:300,400,500,700",
          "Patua One",
          "Mulish",
        ], 
      },
      custom: {
        families: ["Patua One"], 
        urls: [
          "https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400&display=swap",
          "https://fonts.googleapis.com/css2?family=Montserrat:wght@300,400;500;700&display=swap",
          "https://fonts.googleapis.com/css2?family=Mulish&family=Patua+One&display=swap",
        ],
      },
    });

  }, []);

  const [scheduleModel, setScheduleModel] = useState(false);
  const [messageModel, setMessageModel] = useState(false);
  const [isTry, setIsTry] = useState();


  const HandelScheduleModel = () => {
    setScheduleModel(true)
  };


  const [ isStartFreeTrial, setIsStartFreeTrial ] = useState(false);

  const HandelMessageModel = (e, val) => {
    if (val) {
      setIsTry(val);
    } else {
      setIsTry();
    }

    if(val === 'Try Free for 15 Days'){
      setIsStartFreeTrial(true);
    }else{
      setIsStartFreeTrial(false);
    }
    setMessageModel(true);
  };

  const toggleModal = () => {
    setScheduleModel(false);
    setMessageModel(false);
    setIsCaseStudies(false);
  };





  const [isThankYou, setIsThankYou] = useState(false);
  const [isError, setIsError] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const [isThankYouTwo, setIsThankYouTwo] = useState(false);
  const [isErrorTwo, setIsErrorTwo] = useState(false);
  const [btnLoaderTwo, setBtnLoaderTwo] = useState(false);

  const [btnLoaderThree, setBtnLoaderThree] = useState(false);

  const [isResponsiveMenu, setIsResponsiveMenu] = useState(false);

  function myGreeting() {
    setIsThankYou(false);
    setIsError(false);
    setIsThankYouTwo(false);
    setIsErrorTwo(false);
  }

  const [guidelineModal, setGuidelineModal] = useState(false);

  const HandelGuidelineModal = () => {
      setGuidelineModal(!guidelineModal)
  }

  const navigate = useNavigate();

  const pricing = useRef(null);
  const faqMenu = useRef(null);
  const testmonialsMenu = useRef(null);
  const homeMenu = useRef(null);
  const portFolio = useRef(null);

 

  const [isCaseStudies, setIsCaseStudies] = useState(false);

  const HandelCaseStudiesModel = () => {
    setIsCaseStudies(true);
  }


  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  let location = window.location.href;
  const dropdownRef = useRef(null);
  const Modalref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsResponsiveMenu(false);
      }

      if (Modalref.current && !Modalref.current.contains(event.target)) {
        setGuidelineModal(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);



  async function PortfolioApi() {
    axios.get('/portfoliodata')
   .then(await function  (response) {
       if(response && response?.data && response?.data?.data){
           setPortData(response?.data?.data)
       }
   })
   .catch(await function (error) {
       console.log(error)
   })
   .finally(() => {
     console.log('Experiment completed');
     setIsLoader(false);
   }); 
  }

  useEffect(() => {
    setIsLoader(true);
    PortfolioApi();

    // window.scrollTo({ top: 0, behavior: 'smooth' });
    // const searchParams = new URLSearchParams(window.location.search);
    // const param1 = searchParams.get('price');
    // if(param1 === 'monthlt'){
    //   pricing.current?.scrollIntoView({behavior: 'smooth'});
    //   // pricing.current?.scrollTop = 0;
    // }
    
 
  }, []);

  

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [portData, setPortData] = useState();
  const [isLoader, setIsLoader] = useState(false);

  var projectSliderNav = {
    asNavFor:nav1, 
    ref: (slider2) => setNav2(slider2),
    slidesToShow:5,
    slidesToScroll: 1,
    autoplay: true,
    arrows:false,
    speed: 1000,
    autoplaySpeed: 3000,
    swipeToSlide:true,
    focusOnSelect:true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          }
        },
        {
            breakpoint: 500,
            settings: {
              slidesToShow: 2,
            }
          }
    ]
  };

  var projectSlider = {
      asNavFor: nav2,
      ref:(slider1) => setNav1(slider1),
      fade:true,
      arrows:false,
  };

  const accordionItems = [
    
    {
      id: 2,
      title: "What are the perks of taking help from Reddensoft - web development company? ",
      content: "Reddensoft not only values completion of your online business project but also emphasizes establishing innovative and quality solutions to increase engagement of potential audiences. Through the help of talented and skilled web developers, Reddensoft has been able to maintain the high standards that stand alone in the competitive market.",
    },
    {
      id: 3,
      title: "What are the skill sets of your web developers?",
      content: "Expert web developers of Reddensoft aim to cater exclusive solutions to win over the market and following are the skill-set.",
      items: [
        "Angular, Vue.JS, React.JS, Next.js & Blazor & C#",
        "Node, Laravel, PHP, Python, Django",
        "Flutter, React Native, iOS, Android, Ionic",
        "MongoDB, PostgreSQL, MySQL",
        "AWS, Azure, GCP, Digital Ocean, Heroku",
        "Agile/Scrum Methodology with CI/CD, DevOps",
        "JavaScript / jQuery with HTML5 and CSS",
        "Office, Tableau, Salesforce, JIRA, Confluence, Trello & BaseCamp",
        
      ]
    },

    {
      id: 4,
      title: "Do you maintain and support services after deployment?",
      content: "Yes, definitely we maintain and support services after deployment as it is associated with a critical phase of software development life cycle and ensures that functionality and performance are enhanced over time.",
    },
    {
      id: 5,
      title: "Will I get a Project Manager to work with? ",
      content: "Yes, you will definitely get a project manager to work with as this would help you to make your queries clear directly without any communication gap. ",
    },
    {
      id: 6,
      title: "What will be the contributed hours of developers for my project?",
      content: "Working hours of our web developers will be decided based on your project requirements.",
    },
    {
      id: 7,
      title: "How much will it cost to get assistance from Reddensoft’s designers?",
      content: "In order to know about our pricing packages, you’re required to contact one of our consultants who will let you know the best pricing, which will be fixed as per the project’s niche and size.",
    },
    {
      id: 8,
      title: "How much will the web developers take to complete the project?",
      content: "The estimated time will be decided soon after the project’s requirements are shared and as per the project’s size, our team will work dedicatedly to complete your online business project.",
    },
    {
      id: 9,
      title: "Do your team have a WordPress Plugin Developer? ",
      content: "Yes, we have WordPress Plugin Developers who are experts in both back-end and front-end development. Other than this, these developers can create custom themes and plugins in WordPress. ",
    },
    {
      id: 10,
      title: "Do you build an e-commerce website in Laravel?",
      content: "Yes, Laravel provides quick site alignment with libraries and functionalities that are able to comprehensive and modern e-commerce sites. Moreover, it creates a high level of security so that data and payment details cannot be leaked. ",
    },
    {
      id: 11,
      title: "Do you build SaaS Development using WordPress for SMEs and MSMEs?",
      content: "We also focus on WordPress for SMEs and MSMEs to build your software from scratch including marketing automation platforms. It has become convenient for our developers to create web-based applications for business and organization purposes.",
    },
   
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  const sendMessage = () => {
    const message = encodeURIComponent('Hi...');
    const phoneNumber = '+91 8013415481';
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
    window.open(whatsappLink);
  };

  var salesBannerSlider = {
    dots: false,
    infinite: true,
    arrows:true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    fade: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          autoplaySpeed: 1500,
          arrows:false,
        }
      },
      
    ]
  };



  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

 
  const userName = localStorage.getItem('exitModal');
  useEffect(() => {
    
    const handleMouseLeave = () => {
      console.log(userName);
      if(userName === null){
        setIsConfirmationModalOpen(true);
      }
    };

    document.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [userName]);

  const handleCancelExit = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleConfirmExit = () => {
    setIsConfirmationModalOpen(false);
   
  };



  return (
    <section className="sales-page graphics landibgfullstack india-priceing-full-stack" ref={homeMenu}>

      <button className='whatsapp-btn' onClick={sendMessage}>
        <img src={WhatsApp} alt=''/>
      </button>
      
      <Helmet>
       
        <title>Find The Best Full-stack Developer | Reddensoft, India</title>
        <script id="facebook-pixel-script">
          {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js'),
                fbq('init', '273096641131718'),
                fbq('track', 'PageView')
                `}
        </script>
        <noscript id="facebook-pixel-image">
          {`
                    <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=273096641131718&ev=PageView&noscript=1"
                />
                `}
        </noscript>
      </Helmet>

      <section className="banner">
        <div className={` header-section ${scrollPosition > 50 ? "fixed-header slide-in" : ""}`}>
          <div className="container">
            <div className="header-part">
              <a className="logo" onClick={() => homeMenu.current?.scrollIntoView({behavior: 'smooth'})}>
                <img src={HeaderLogo} alt="Reddensoft logo" />
              </a>

              <div ref={dropdownRef} className={` ${isResponsiveMenu ? 'active':''}  header_middle`}>
                <ul>
                  <li><a onClick={() => {pricing.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Services</a></li>
                  <li><a onClick={() => {portFolio.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Our Portfolio</a></li>
                  
                  <li><a onClick={() => {testmonialsMenu.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Testimonial</a></li>
                  <li><a onClick={() => {faqMenu.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">FAQs</a></li>
                </ul>
              </div>

              <div className="header_right">
                {/* <a
                  onClick={(e) => HandelMessageModel(e, 'Try Free for 15 Days')}
                  className="full-stack-top-btn "
                >
                  <span>+91 80134 15481</span>
                </a> */}
                <a href="tel:+91 80134 15481"
                  className="full-stack-top-btn call-btn-header"
                  style={{display: 'flex', alignItems:'center'}}
                  >

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" width={20} height={20}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                  </svg>

                  <span style={{paddingLeft: '5px'}}>+91 80134 15481</span>
                </a>

                <a
                  onClick={(e) => HandelScheduleModel(e, 'Schedule a Call')}
                  className="full-stack-top-btn fill-btn mobile-none"
                >
                  <span>Schedule a Call</span>
                </a>
                

                <button className="bars-menu" ref={dropdownRef} onClick={() => setIsResponsiveMenu(!isResponsiveMenu)}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={22} height={22}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                </button>



              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="banner-section">
            <div className="banner-left">
              <h4>India’s #1  <span>Best Web Development Company</span></h4>
                {/* <h2>India's Leading <br/> 
                <strong className="fullstack_bg"> Web </strong> <span>Development</span> 

                <br/> Service Provider!
                </h2> */}
               
              <h2>
                Professional <strong className="fullstack_bg"> Web </strong> <span> Design and Development </span>
                Starts From <span> ₹9500/-</span>

              </h2>

              <br/>
              <div className="fullstack_banner_ul">
                <ul>
                  <li>Reach and Grow Your Right Audience</li>
                  <li>Hire / Freelance Our Best Web Developers</li>
                  <li>Build Your Website Most Convertible</li>
                </ul>
                <ul>
                  <li>Get Faster Delivery With High-Quality
                  {/* <span className="image-text">Free</span> */}
                  </li>
                  <li>Customize Your Web To Scale Your Business</li>
                  <li>Get Ownership of Project With IP Security</li>
                </ul>
              </div>
              <br/>
              <p>Our goal is to deliver high-quality solutions that are reasonable yet remarkable.</p>

              <p className="banner-location">
                Working with Clients across- 
                <span> 
                   Kolkata &nbsp;|&nbsp; Delhi-NCR &nbsp;|&nbsp; Mumbai &nbsp;|&nbsp; Ahmedabad &nbsp;|&nbsp; Bengaluru &nbsp;|&nbsp; 
                   Chennai &nbsp;|&nbsp; Hyderabad &nbsp;|&nbsp; Lucknow  &nbsp;|&nbsp; USA &nbsp;|&nbsp; Canada &nbsp;|&nbsp; UK &nbsp;|&nbsp; World Wide 
                </span>
              </p>

              <div>
                <a onClick={(e) => HandelScheduleModel(e, 'Schedule a Call')} className="full-stack-top-btn fill-btn banner-full-btn" >
                  <span>Schedule a Call</span>
                </a>
              </div>
            </div>

            <div className="banner-developer-sec">

              <div className="banner-form">
                <h3>Get A Great Discount For Your Corporate or Ecommerce Website <br/><span className="text-primary small">Please Fill The Below Form To Avail It</span></h3>

                <Formik
                  initialValues={{ name:"", email: "", phone: "", message: "", pageName: location, tryNow: "Get A Great Discount To Build Your Corporate or Ecommerce Website" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = "Email is required";
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                      errors.email = "Invalid email address";
                    }

                    if (!values.phone) {
                      errors.phone = 'Phone number is required';
                    } else if ( !validator.isMobilePhone(values.phone)) {
                      errors.phone = 'Invalid phone number';
                    }

                    if (!values.name || !_.trim(values.name)) {
                      errors.name = 'Name is required';
                    } else if (values.name.length > 50) {
                      errors.name = 'Must be 15 characters or less';
                    } else if (values.name.match("[<>]")) {
                      errors.name = 'Please provide a valid name';
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setBtnLoaderThree(true);
                    setSubmitting(false);
                    axios
                      .post(`/quickContact`, values)
                      .then(function (response) {
                        // console.log(response);
                        navigate('/thank-you');
                      })
                      .catch(function (error) {
                        // console.log(error)
                        setIsErrorTwo(
                          error?.message ? error.message : "Network Error"
                        );
                      })
                      .finally(() => {
                        setBtnLoaderThree(false);
                        setTimeout(myGreeting, 5000);
                        values.email = "";
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                  }) => (

                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input type="text" onChange={handleChange} onBlur={handleBlur} name="name" value={removeExtraSpace(values.name)} className="form-control" autoCapitalize="none" autoComplete="new-password" maxLength="30" size="20" placeholder="Your Name" />
                          {touched.name && errors.name ? <div style={{color: '#ff0000'}}>{errors.name}</div> : null}
                        </div>
                        <div className="form-group">
                          <input type="text" onChange={handleChange} onBlur={handleBlur} name="email" value={removeExtraSpace(values.email)} className="form-control" autoCapitalize="none" autoComplete="new-password" maxLength="30" size="20" placeholder="Email Address" />
                          {touched.email && errors.email ? <div style={{color: '#ff0000'}}>{errors.email}</div> : null}
                        </div>
                        <div className="form-group">
                        <PhoneInput 
                          name="phone"
                          placeholder="Enter phone number"
                          international
                          defaultCountry="IN"
                          autoCapitalize="none" autoComplete="new-password"
                          countryCallingCodeEditable={false}
                          value={values.phone}
                          onBlur={handleBlur}
                          onChange={(val) => setFieldValue('phone', val)}/>
                          {/* <input type="text" onChange={handleChange} onBlur={handleBlur} name="name" value={values.name} className="form-control" maxlength="80" size="20" placeholder="Phone Number" /> */}
                          {touched.phone && errors.phone ? <div style={{color: '#ff0000'}}>{errors.phone}</div> : null}
                        </div>
                        <div className="form-group">
                          <textarea onChange={handleChange} onBlur={handleBlur} name="message" value={removeExtraSpace(values.message)} className="form-control" rows="3" placeholder="Your Message"></textarea>
                          {touched.message && errors.message ? <div style={{color: '#ff0000'}}>{errors.message}</div> : null}
                        </div>
                        <div className="">
                      
                          <button type="submit" className="full-stack-btn" name="contactBtn">
                            <strong> BOOK A FREE CONSULTATION</strong>
                            {btnLoaderThree && (
                              <span className="btn-ring-animation"></span>
                            )}
                          </button>
                        </div>
                    </form>
                  )}

                </Formik>
              </div>

              <div className="hiredesigner_batch">
                <div className="hiredesigner_batchsub">
                  <img src={batchone} alt="" />
                </div>
                <div className="hiredesigner_batchsub">
                  <img src={batchthree} alt="" />
                </div>
                <div className="hiredesigner_batchsub">
                  <img src={TrastedLogoNew} alt="" />
                </div>
              </div>

            </div>
          </div>

          <div>
            <div className="trusted-section">
              <div className="trusted-logo-box">
                <h5>Trusted By Over 1200 Ambitious Brands:</h5>

                <div>
                  <Slider {...trastSlider}>
                    <img src={TrustedLogo1} alt=''/>
                    <img src={TrustedLogo2} alt=''/>
                    <img src={TrustedLogo3} alt=''/>
                    <img src={TrustedLogo4} alt=''/>
                    <img src={TrustedLogo5} alt=''/>
                    <img src={TrustedLogo6} alt=''/>
                    <img src={TrustedLogo7} alt=''/>
                    <img src={TrustedLogo8} alt=''/>
                    <img src={TrustedLogo9} alt=''/>
                    <img src={TrustedLogo10} alt=''/>
                  </Slider>
                </div>
              </div>
            </div>

            <div className="trusted-section">
              <div className="trusted-logo-box">
                <div className="technologi-heading-section">
                  <h5 className="technologi-heading">Technologies we work with:</h5>
                </div>
                

                <div className="technologies-logo">
                    <div>
                      <img src={Technologies1} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies2} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies3} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies4} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies5} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies6} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies7} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies8} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies9} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies10} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies11} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies12} alt=''/>
                    </div>

                    <div>
                      <img src={Technologies13} alt=''/>
                    </div>
                  
                    <div>
                      <img src={Technologies14} alt=''/>
                    </div>
                    
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="get_experts">
        <div className="container">

        <div className="content-top">
          <div className="content-top-left">
            <p>Looking for a</p>
            <h3><span>Creative</span> Web Development Work That Can Bring You More</h3>
            <h2>Convertible Leads?</h2>
          </div>

          <div className="content-top-right">
            <p>
              From personal websites to online ecommerce stores, 
              and corporate websites to custom web apps, Reddensoft 
              is the <span>all-in-one platform</span> to build a beautiful interactive 
              responsive websites that <span> engage visitors</span>.
            </p>

            <div className="content-top-right-button">
              <button type="button" onClick={() => {portFolio.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}}>View Portfolio</button>
              <button type="button" className="orange" onClick={(e) => HandelMessageModel(e, 'SHARE YOUR REQUIREMENTS')}>Let's Talk and Start</button>
            </div>
          </div>
        </div>
          <div className="get_experts_total">
            <div className="get_experts_left">
              <h2>Get Experts For All Your Needs</h2>
              <p className="line">End of sleepless nights for unfinished projects as we are ready to support Software Development Lifecycle along with flexible engagements to meet requirements.</p>
              <div className="number_count">
                  <div className="number_count_sub">
                    <div className="number_count_text">
                      <h3>15+</h3>
                      <p>Years of Experience</p>
                    </div>
                  </div>
                  <div className="number_count_sub">
                    <div className="number_count_text">
                      <h3>250+</h3>
                      <p>Agile Developers</p>
                    </div>
                  </div>
                  <div className="number_count_sub">
                    <div className="number_count_text">
                      <h3>1100+</h3>
                      <p>Global Clients</p>              
                    </div>
                  </div>
                  <div className="number_count_sub">
                    <div className="number_count_text">
                      <h3>1200+</h3>
                      <p>Projects</p>              
                    </div>
                  </div>
              </div>
              <button className="requerment_btn"  onClick={(e) => HandelMessageModel(e, 'SHARE YOUR REQUIREMENTS')}> SHARE YOUR REQUIREMENTS</button>
            </div>
            <div className="get_experts_right">

            <div className="get_experts_right_box">
              <div className="get_experts_right_box_top">
              <img src={Getexperticon1} alt=''/>
              <h3>Add Laravel Developers To Your Team</h3>
              </div>
              <ul>
                  <li>Security for web resources</li>
                  <li>Easy Maintenance & Support</li>
                  <li>Built-In Authentication System</li>
              </ul>
            </div>

            <div className="get_experts_right_box">
              <div className="get_experts_right_box_top">
              <img src={Getexperticon2} alt=''/>
              <h3>Flexible E-Commerce for Project</h3>
              </div>
              <ul>
                  <li>Increases Profit Margin </li>
                  <li>Target Marketing with Better ROI</li>
                  <li>Possibility of a Wider Customer Base</li>
              </ul>
            </div>

            <div className="get_experts_right_box">
              <div className="get_experts_right_box_top">
              <img src={Getexperticon3} alt=''/>
              <h3>Building Web Applications through React.js</h3>
              </div>
              <ul>
                  <li>Creating Dynamic Applications</li>
                  <li>Reusable Components</li>
                  <li>SEO-Friendly</li>
              </ul>
            </div>

            <div className="get_experts_right_box">
              <div className="get_experts_right_box_top">
              <img src={Getexperticon4} alt=''/>
              <h3>Customized Plugins & Themes for WordPress Projects</h3>
              </div>
              <ul>
                  <li>Content Management Potential</li>
                  <li>Secure Web Applications</li>
                  <li>Saves Time & Cost</li>
              </ul>
            </div>

            </div>  
          </div>
        </div>
      </section>

      <section className="offering" ref={pricing}>
        <div className="container">
          <h3>Offering Top-Notch <strong class="fullstack_bg"> Web  </strong> <span> Development</span> Services Since 2008</h3>
          <p>Our Web Developers with versatile skills aligning with your business requirements. Our web developers build secure, scalable, and dynamic enterprise-grade, cost-effective React web apps.</p>
          <div className="offering_allbox">

            <div>
              <img src={OfferingIcon1} alt=''/>
              <h3>SaaS Product Development</h3>
            </div>

            <div>
              <img src={OfferingIcon4} alt=''/>
              <h3>Plugin Development</h3>
            </div>

            <div>
              <img src={OfferingIcon2} alt=''/>
              <h3>Migration & Integration Services</h3>
            </div>

            <div>
              <img src={OfferingIcon3} alt=''/>
              <h3>UI/UX Development</h3>
            </div>

            

            <div>
              <img src={OfferingIcon5} alt=''/>
              <h3>Front-End Development</h3>
            </div>

            <div>
              <img src={OfferingIcon6} alt=''/>
              <h3>Back-End Development</h3>
            </div>

            <div>
              <img src={OfferingIcon7} alt=''/>
              <h3>Upgrade Service</h3>
            </div>

            <div>
              <img src={OfferingIcon8} alt=''/>
              <h3>Support & Maintenance</h3>
            </div>

            <div>
              <img src={OfferingIcon9} alt=''/>
              <h3>Ecommerce Website</h3>
            </div>

            <div>
              <img src={OfferingIcon1} alt=''/>
              <h3>Customizable Solutions</h3>
            </div>

          </div>

          <button class="requerment_btn" onClick={(e) => HandelMessageModel(e, 'Inquire Now')}>BOOK A FREE CONSULTATION</button>
        </div>
      </section>
      
      <section className="why_redensoft">
        <div className="container">
          <div className="why_redensoft_total">
            <div className="why_redensoft_left">
              <h2>Why <span> Reddensoft </span> is the best choice?</h2>
              <div className="why_redensoft_left_allbox">

                <div>
                  <h3>Smart Resource Management</h3>
                  <ul>
                    <li>
                      Get the best resources with the expertise of Laravel, WordPress, react, SAAS projects and many more
                    </li>
                    <li>
                      Flexible team selection and strategic enhancement
                    </li>
                    <li>
                      Expert resources who are flexible to handle both long-term and short-term projects 
                    </li>
                  </ul>
                  {/* <p>Our developers are well-versed with their specific tech stacks and have 15+ years of expertise. Check developers’ work samples, client reviews, and identity verification.</p> */}
                </div>
                <div>
                  <h3>Timely achievement of project milestones</h3>
                  <ul>
                    <li>
                      Progressive web development that meets your expectation
                    </li>
                    <li>
                      Contemporary cloud applications that bring the world to your site in a month
                    </li>
                    <li>
                      Choose the talented developers who ensure product delivery on time 
                    </li>
                  </ul>
                  {/* <p>
                    Our team comprises experienced web developers with a deep knowledge of the latest trends and technologies 
                    in web development. We are committed to delivering top-notch web development services
                    </p> */}
                </div>
                <div>
                  <h3>Most Cost- effective</h3>
                  <ul>
                    <li>
                      As a website development company, we offer the most cost-effective project solutions
                    </li>
                    <li>
                      Acquainted with the best developers at an hourly or monthly rate as you require
                    </li>
                    <li>
                      Enhance cost-efficiency by choosing WordPress plugin developers/React developers/Laravel developers/ website developers/ php developers as per the project’s need 
                    </li>
                  </ul>
                  {/* <p>Focus on your work knowing we help protect your data and privacy with fully-signed NDA. We’re here with 24/7 support if you need it.</p> */}
                </div>
                <div>
                  <h3>Stand Out From the Crowd</h3>
                  <ul>
                    <li>
                      Our progressive web development allows you to build the project either from scratch or from mid-way project revamping
                    </li>
                    <li>
                      Through exclusive solutions, our web developers are able to stand out from other competitors.
                    </li>
                    <li>
                      Hire dedicated php mysql developers, for in-depth analysis and deployment of your project. 
                    </li>
                  </ul>
                  {/* <p>
                    Reddensoft provides customizable web development solutions tailored to our client’s needs. Our team works with you to develop a website that is 
                    unique to your business and meets your requirements.
                  </p> */}
                </div>

              </div>
              <button class="requerment_btn" onClick={(e) => HandelScheduleModel(e, 'Schedule a Call')}>Schedule a Call</button>
            </div>
            <div className="why_redensoft_left">
              <div className="why_redensoft_right_img">
                <div className="rating-box">
                  <img src={WhyreddensoftRightstar} alt=''/>
                  <h5>4.7/5</h5>
                  <p className="text-white">Client Rating</p>
                  <img src={GoogleWhite} alt=''/>
                  <p>ReddenSoft has Consistently Proven to be the Best Technology Partner</p>
                </div>
                <div className="man_img_box"> <img src={WhyreddensoftRight} alt=''/> </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hire_experience">
        <div className="container">
          <div className="hire_experience_total">
            <div className="hire_experience_left"><img src={HireExperienceWomen} alt=''/></div>
            <div className="hire_experience_left">
              <h2>Skill-Sets of Our Expert   <strong class="fullstack_bg"> Web </strong> <span>Developers</span></h2>
              <ul>
                <li>Angular, Vue.JS, React.JS, Next.js & Blazor & C#</li>
                <li>Node, Laravel, PHP, Python, Django</li>
                <li>Flutter, React Native, iOS, Android, Ionic</li>
                <li>MongoDB, PostgreSQL, MySQL</li>
                <li>AWS, Azure, GCP, Digital Ocean, Heroku</li>
                <li>Agile/Scrum Methodology with CI/CD, DevOps</li>
                <li>JavaScript / jQuery with HTML5 and CSS</li>
                <li>Office, Tableau, Salesforce, JIRA, Confluence ,Trello & BaseCamp</li>
              </ul>
              <button class="requerment_btn"  onClick={(e) => HandelMessageModel(e, 'TALK TO OUR EXPERTS TODAY')}>TALK TO OUR EXPERTS TODAY</button>
            </div>
          </div>
        </div>
      </section>

      <section className="offering industries">
        <div className="container">
          <h3>Industries we are serving</h3>
          <p>It is our forte to build software development as per your online business requirements. And successfully, we’ve been able to meet the requirements of various range of online business projects</p>
          <div className="offering_allbox">

            <div>
              <img src={indus1} alt=''/>
              <h3>Education & Learning</h3>
            </div>

            <div>
              <img src={indus2} alt=''/>
              <h3>Healthcare & Fitness</h3>
            </div>

            <div>
              <img src={indus3} alt=''/>
              <h3>Social Networking</h3>
            </div>

            <div>
              <img src={indus4} alt=''/>
              <h3>Travel & Hospitality</h3>
            </div>

            

            <div>
              <img src={indus5} alt=''/>
              <h3>Retail, Ecommerce</h3>
            </div>

            <div>
              <img src={indus6} alt=''/>
              <h3>Food & Restaurant</h3>
            </div>

            <div>
              <img src={indus7} alt=''/>
              <h3>Logistic Distribution</h3>
            </div>

            <div>
              <img src={indus8} alt=''/>
              <h3>IT & ITES</h3>
            </div>

            <div>
              <img src={indus9} alt=''/>
              <h3>Real-Estate</h3>
            </div>

            <div>
              <img src={indus10} alt=''/>
              <h3>Legal</h3>
            </div>

            <div>
              <img src={indus11} alt=''/>
              <h3>Fintech</h3>
            </div>

            <div>
              <img src={indus12} alt=''/>
              <h3>Sports</h3>
            </div>

          </div>

          <button class="requerment_btn" onClick={(e) => HandelMessageModel(e, 'Inquire Now')}>BOOK A FREE CONSULTATION</button>
        </div>
      </section>

      <section className="testimonial-sec-box">
        <section className="section_three" ref={portFolio}>
          <div className="container">
            <div className="text_head_part">
              <h2 className="fadeInUp">Recent <span> Case Studies </span></h2>
              <p className="fadeInUp">We’ve hand-picked some case studies we think you’ll like:</p>
            </div>
          </div>

          {isLoader && !portData && (
            <div className="section-loader">
              <div className="loader--ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p>Loading...</p>
            </div>
          )}
          
          {portData ? (
            <>
              <div className="blue_bar">
                <div className="container">

                  <Slider {...projectSliderNav}>

                      {portData && portData?.length > 0 && portData?.map((items, i) => {
                          return (
                              <div key={i}>
                                  <span className="small_img">
                                      <img src={items.logo} alt="" />
                                      <h5>{items.title}</h5>
                                  </span>
                              </div>
                          )
                      })}
                  </Slider>

                </div>
              </div>
              <div className="container">
                  <div className="sec_inner_port_holder">
                      <Slider {...projectSlider}>
                          {portData && portData?.length > 0 && portData?.map((items, i) => {
                          return(
                                  <div key={i}>
                                      <div className="sec_inner_port">
                                          <div className="left">
                                              <ul>
                                                  <li><span>Project Name:</span> {items?.title}</li>
                                                  <li></li>
                                                  <li><span>Technology :</span> {items?.technology && JSON.parse(items?.technology)?.length > 0 && JSON.parse(items?.technology)?.reverse().map((technology, t) => {
                                                      return (<> { technology.nm} {t + 1 === JSON.parse(items?.technology)?.length ? '':'  |'}</>)
                                                  })}</li>
                                                  
                                              </ul>
                                              <div dangerouslySetInnerHTML={{__html: items?.description}}></div>
                                              <Link onClick={(e) => HandelCaseStudiesModel(e, `Read more ${items?.title}`)} className="btn">Read details</Link>
                                          </div>
                                          <div className="right">
                                              <div className="laps">
                                                <img src={items?.image} alt="" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              )  
                          })}
                      </Slider>
                  </div>
              </div>
            </>
            ):(!isLoader ? (
              <div className="container">
                  <div style={{textAlign: 'center',}}>
                      <span style={{margin: '10px auto'}}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" width={'40'} height={'40'}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                          </svg>
                      </span>

                      <p>Case Studies not found!</p>
                  </div>
              </div>
            ):(null)    
          )}
              
        </section>
      </section>

      <div ref={testmonialsMenu}></div>

      <section  className="faq-sec-part">
        <div className="container">
          <h3>We are proud to inspire</h3>
          <br/><br/>
          <div className="">
            <Slider {...salesBannerSlider}> 

              <div>
                <div className="testmonial-box">
                  <h5>A useful web development company really </h5>
                  <p>
                    “In today's digitally evolving world, the competition to sustain in the market has become tough,
                            for which every step needs to be taken wisely. However, I found Reddensoft, a web development
                            company really useful as my project’s needs get addressed within the stipulated time.”
                  </p>

                  <div className="testmonial-details">
                    <div className="testmonial-user-image">
                      <img src={Eric} alt=''/>
                    </div>
                    <div className="testmonial-user-details">
                      <h5>Mike Boggus</h5>
                      <p>Entrepreneur</p>
                    </div>
                  </div>
                  <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                </div>
                
              </div>

              <div>
                <div className="testmonial-box">
                  <h5>A suitable web development company</h5>
                  <p>
                  “Thanks to Reddensoft who has paid attention to my project’s concern. Through the help of 
                  Reddensoft’s web development, my project had a great impact as they created well-versed sites 
                  that acquire conversions from audiences. ” 
                  <br/>
                  <br/>
                  </p>

                  <div className="testmonial-details">
                    <div className="testmonial-user-image">
                      <img src={customerprofileone} alt=''/>
                    </div>
                    <div className="testmonial-user-details">
                      <h5>David Miller</h5>
                      <p>Entrepreneur</p>
                    </div>
                  </div>
                  <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                </div>
                
              </div>

              <div>
                <div className="testmonial-box">
                  <h5>Reddensoft is a complete package</h5>
                  <p>
                  “Seeking for the best to launch my online business in the market and struggling with whom to trust? 
                    Reddensoft is a complete package that helped me out with my project’s requirements and a team of 
                    Reddensoft made it possible to stand out from others.”
                  </p>

                  <div className="testmonial-details">
                    <div className="testmonial-user-image">
                      <img src={customerprofiletwo} alt=''/>
                    </div>
                    <div className="testmonial-user-details">
                      <h5>Michel Ambrosio</h5>
                      <p>Entrepreneur</p>
                    </div>
                  </div>
                  <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                </div>
                
              </div>

              <div>
                <div className="testmonial-box">
                  <h5>The best web development company</h5>
                  <p>
                    "I was in quest for the best web development company for my project. And then I got to know about 
                      Reddensoft which caters to mind-blowing solutions through a potential team of developers that leave 
                      no stone unturned to match your expectations."
                  </p>

                  <div className="testmonial-details">
                    <div className="testmonial-user-image">
                      <img src={customerprofilethree} alt=''/>
                    </div>
                    <div className="testmonial-user-details">
                      <h5>Bently Ritter</h5>
                      <p>Entrepreneur</p>
                    </div>
                  </div>
                  <img className="testmonial-start" src={ReviewFullStart} alt=""/>
                </div>
              </div>

            </Slider>
          </div>
          
          
        </div>
      </section>
      

      <section className="faq_sec" ref={faqMenu}>
        <div className="container">
    
        <div className="faq_total">
         
         <div className="faq_left">
          <h3>Frequently Asked Questions</h3>
          <h5>Still Have Questions? We’ve Got Answers! </h5>
         </div>
          
          <div className="faq_rifgt">
          <div className="acordin-sec">
            <div className="accordion">
              <div className="accordion-half">
                {accordionItems.map((item, index) => (
                  <div key={index} className="accordion-item">
                    <button
                      className={`accordion-title accordion-item-header ${
                        activeIndex === item.id ? "active" : ""
                      }`}
                      onClick={() => handleAccordionClick(item.id)}
                    >
                      {item.title}
                    </button>
                     
                      <CSSTransition in={activeIndex === item.id} >
                        {state => (
                          <div
                            style={{
                              maxHeight: state === 'entered' ? '600px' : '0',
                              overflow: 'hidden',
                              transition: 'max-height 0.2s ease-in-out',
                            }}
                          >
        
                            <div className="accordion-content" dangerouslySetInnerHTML={{__html: item.content}}></div>

                            {item.items && (
                              <ul>
                                  {item?.items?.map((list,i)=>(
                                  <li key={i}>{list}</li>
                                  ))}
                                  
                              </ul>
                            )}
                          </div>
                        )}
                      </CSSTransition>
                  </div>
                ))}

              </div>

              
            </div>
          </div>
          </div>

          </div>

        </div>
      </section> 

      <section className="contact_ups">
        <div className="container">
          <div className="contact_content">
            <div className="ttx">
              <h2>Let's Solve Your Business Problems and get your project done.</h2>
              <p>
                We ensure faster delivery of your product by expert developers. {" "}
              </p>
              <h5>Our expert will help you out to organize your project requirement:</h5>
              <ul>
                <li>Set the minimal plan to get it done</li>
                <li>
                  Ensure a faster delivery{" "}
                </li>
                
              </ul>
            </div>
            <div className="frm-box-rp">
              <div className="frm-box">
                <h3>Not sure where to start?</h3>
                <p>Let’s have a chat</p>
                  <Formik
                  initialValues={{ name:"", email: "", tryNow: "Not sure where to start" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = "Email is required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }

                    if (!values.name || !_.trim(values.name)) {
                      errors.name = 'Name is required';
                      } else if (values.name.length > 50) {
                      errors.name = 'Must be 15 characters or less';
                      } else if (values.name.match("[<>]")) {
                      errors.name = 'Please provide a valid name';
                      }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setBtnLoaderTwo(true);
                    setSubmitting(false);
                    axios
                      .post(`/lets-chat`, values)
                      .then(function (response) {
                        // console.log(response);
                        navigate('/thank-you');
                        setIsThankYouTwo(true);
                      })
                      .catch(function (error) {
                        // console.log(error)
                        setIsErrorTwo(
                          error?.message ? error.message : "Network Error"
                        );
                      })
                      .finally(() => {
                        setBtnLoaderTwo(false);
                        setTimeout(myGreeting, 5000);
                        values.email = "";
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className=" "
                      id=""
                    >
                      <label htmlFor="">Your name</label>
                      <input type="text" onChange={handleChange} onBlur={handleBlur} name="name" value={removeExtraSpace(values.name)} autoCapitalize="none" autoComplete="new-password" />
                      {errors.name && touched.name && (
                          <div
                            style={{
                              color: `rgb(255, 0, 0)`,
                              marginTop: `2px`,
                            }}
                          >
                            {errors.name}
                          </div>
                        )}

                      <label htmlFor="">Your company email</label>
                      <input type="email" onChange={handleChange} onBlur={handleBlur} name="email" value={values.email} autoCapitalize="none" autoComplete="new-password" />
                      {errors.email && touched.email && (
                          <div
                            style={{
                              color: `rgb(255, 0, 0)`,
                              marginTop: `2px`,
                            }}
                          >
                            {errors.email}
                          </div>
                        )}

                      <button type="submit" className="link_up">
                        book a call with us 
                        {btnLoaderTwo && (
                          <span className="btn-ring-animation"></span>
                        )}
                      </button>
                    </form>
                  )}
                  </Formik>

                  {isThankYouTwo && (
                    <div className="alert alert-success mt-3">
                      Thank you for your interest.
                    </div>
                  )}

                  {isErrorTwo && (
                    <div style={{ color: `rgb(255, 0, 0)`, marginTop: `2px` }}>
                      {isErrorTwo}
                    </div>
                  )}

              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="wap">
            <div className="item">
              <a href="#" className="frt-logo">
                <img src={FooterLogo} alt="" />
              </a>
              
             
              
            </div>
            {/* <div className="item">
              
              <p><a href="mailto:info@reddensoft.com">info@reddensoft.com</a></p>
            </div> */}
            <div className="item">
              <a href="tel:+91 80134 15481" className="call-btn">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
                    <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"/>
                  </svg>
                </span>
                <span className="number-box">
                  +91 80134 15481
                </span>
              </a>
            </div>

            <div className="item">
              <p>
                <a onClick={() => HandelGuidelineModal()}>
                  Privacy Policy
                </a>
              </p>
            </div>

          </div>
        </div>
        <div className="copy-riht">
          <div className="container">
            <p>ReddenSoft Copyright 2023. All rights reserved.</p>
            {/* <ul>
              
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              
            </ul> */}
          </div>
        </div>
      </footer>

      <Transition
        show={messageModel}
        enter="ease-out duration-300"
        enterFrom="opacity-0 relative-modal"
        enterTo="opacity-100 relative-modal"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 relative-modal"
        leaveTo="opacity-0 relative-modal"
      >
        <ScheduleConsultation
          isSchedule={false}
          isFree={isStartFreeTrial}
          isNewTry={isTry}
          isIndia={true}
          toggleModal={toggleModal}
        />
      </Transition>

      <Transition
        show={isCaseStudies}
        enter="ease-out duration-300"
        enterFrom="opacity-0 relative-modal"
        enterTo="opacity-100 relative-modal"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 relative-modal"
        leaveTo="opacity-0 relative-modal"
      >
        <CaseStudiesModal toggleModal={toggleModal} />
      </Transition>

      <Transition
        show={scheduleModel}
        enter="ease-out duration-300"
        enterFrom="opacity-0 relative-modal"
        enterTo="opacity-100 relative-modal"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 relative-modal"
        leaveTo="opacity-0 relative-modal"
      >
        <ScheduleConsultation isSchedule={true} isIndia={true} toggleModal={toggleModal} />
      </Transition>

      <Transition 
            show={guidelineModal}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal">
                <div className='Guideline-modal'>

                    <div className='Guideline-modal-box' ref={Modalref}>
                      <button className='inline-btn' onClick={() => HandelGuidelineModal()} type='button'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20px"  height="20px">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>

                      <div className="Guideline-modal-inside">
                        <h2>Privacy Policy</h2>

                        <p>
                          This privacy policy of ReddenSoft declares the process and purpose of collecting/ storing/ sharing 
                          your data while you are using our services.
                        </p>

                        <p>
                          We recommend you read this entire privacy policy thoroughly to get a clear picture of your rights 
                          and choices. Using our services means you are agreeing with the privacy policy we have.
                        </p>

                        <h4>
                          Information that we collect:-
                        </h4>

                        <h6>
                          Personal information
                        </h6>

                        <p>
                          We collect the basic information from you like your name, phone number, email id, and website/ 
                          name of the organization while you schedule a meeting with us or ask for a partnership program. 
                          The purpose is to further communicate with you and get your job done.
                        </p>

                        <h6>
                           Non-personal information
                        </h6>
                        <p>
                          Collecting non-personal identification information about Users happens when they interact with our 
                          Site. It may include the type of computer, browser name, and technical information about the user 
                          while accessing our site (i.e., the operating system and the Internet service providers used) and other 
                          similar information.
                        </p>

                        <h6>
                          Use of collected information:-
                        </h6>

                        <ul className='list-items'>
                            <li>For the betterment of customer service</li>
                            <li>Providing you with the best-personalized user experience</li>
                            <li>For the Site improvement </li>
                            <li>For Payment Processing</li>
                            <li>For the improvement of promotion/ contest/ survey / other features of the Site </li>
                            <li>For sending periodic emails</li>
                        </ul>

                        <h6>
                          Media
                        </h6>

                        <p>
                        We recommend not sharing inappropriate or sensitive images or documents on the website. On the contrary, 
                        using images or documents from the website is strictly prohibited and it could be penalized under copyright law.
                        </p>

                        <h6>
                          Embedded content from other websites
                        </h6>

                        <p>
                          Articles on this site may include embedded content (e.g., videos, images, articles, etc.) from other 
                          third-party websites. Embedded content from other websites behaves exactly the same way it appeared 
                          on the original website.
                        </p>
                        <p>
                          Those third-party we bsites may collect data about you, use cookies, embed additional third-party 
                          tracking, and monitor your interaction with that embedded content (including tracking your interaction 
                          with the embedded content) if you are already logged into those websites.
                        </p>

                        <h6>
                          Cookies policy
                        </h6>

                        <p>
                          Our website may use "cookies" to enhance the visitor’s experience. Cookies are being placed by the 
                          web browser on their hard drive for record-keeping purposes and sometimes to track information about 
                          them. Users may opt to set their web browser to refuse cookies or give them an alert when cookies 
                          are being sent. If they do so, then some parts of the website may not function properly.
                        </p>
                        <p>
                          Visitors of our website can subscribe to different services like subscribing to blogs, 
                          starting a project with us or joining the partnership program, and for that, we need your name, 
                          email address, and website information to store in cookies. These are for your convenience so 
                          that you do not have to fill in your details again when you go for other services on the website. 
                          These cookies will last for one year.
                        </p>

                        <h6>
                          Information Sharing
                        </h6>
                        <p>
                          We strictly oppose sharing information with any third party. Information shared with us will only be used 
                          for the betterment of your project and it will be kept only with us. We also request you to not disclose 
                          any confidential statement about the ongoing project with any other website or third-party domain.
                        </p>
                      </div>
                    </div>
                </div>  
      </Transition>

      <Transition 
        show={isConfirmationModalOpen}
        enter="ease-out duration-300"
        enterFrom="opacity-0 relative-modal"
        enterTo="opacity-100 relative-modal"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 relative-modal"
        leaveTo="opacity-0 relative-modal">

        <ExitModal
          onCancel={handleCancelExit}
          onConfirm={handleConfirmExit} />
      </Transition>

    </section>
  );
}


