import React , { useState }from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import {BsArrowRight} from 'react-icons/bs';
import { useFormik } from 'formik';
import validator from 'validator';
import './Model.scss';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router-dom';

export default function CaseStudiesModal({toggleModal, proVal, modalName = false}) {
  
    var _ = require('lodash');
    const [btnLoader, setBtnLoader] = useState(false);
    const [isThankYou, setIsThankYou] = useState(false);
    const [isError, setIsError] = useState(false);

    const removeExtraSpace = (s) => {
       var rSpase = s.replace(/\s{2,}/g, ' ');
       return _.trimStart(rSpase);
    };

    console.log(proVal)

    const navigate = useNavigate();

    const quickContact  = {
        name: "",
        email: "",
        projectName: proVal,
        tryNow: "Where should we send the case studies?",
    };


    const validate = values => {
        const errors = {};
    
        if (!values.name || !_.trim(values.name)) {
            errors.name = 'Name is required';
        } else if (values.name.length > 50) {
            errors.name = 'Must be 15 characters or less';
        } else if (values.name.match("[<>]")) {
            errors.name = 'Please provide a valid name';
        }
    
        if (!values.email ) {
            errors.email = 'Email is required';
        } else if ( !validator.isEmail(values.email)) {
            errors.email = 'Invalid email address';
        } 

    
        return errors;
    };

    function myGreeting() {
        setIsThankYou(false);
        setIsError(false);
    };

    const quickFormik = useFormik({
        initialValues: quickContact,
        validate,
        onSubmit: (values, {resetForm}) => {
            setBtnLoader(true);

            // console.log(values)
            axios.post(`/lets-chat`, values)
            .then(function (response) {
                resetForm({values: ""});
                navigate('/thank-you');
                setIsThankYou(true);
                
            })
            .catch(function (error) {
                setIsError(error?.message ? error.message: "Network Error")
            })
            .finally(() => {
                setBtnLoader(false)
                setTimeout(myGreeting, 10000);
            });
        },
    });


  return (
    <div className="schedule_consultation">
        <div className="modal">
        <div className='overlay'></div>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close pink-close" onClick={(e) => toggleModal(e)} >
                <AiOutlineClose fontSize={19} />
              </button>
            </div>
            <div className="modal-body case-studies">
              <div className="schedule_consultation-content">
              
                <div className="sidepanel learge-side-bar">
                    <div className='icon-left'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.2} stroke="currentColor" width={60} height={60}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                        </svg>
                    </div>
                  
                    <h4>Client Success Story from 1 member to 25 {modalName ? modalName: 'developers'}.</h4>

                    <p>
                        We will send you the story how we tranformed an agency and SaaS application from ground up to milion dollar business.
                    </p>
                 
                </div>
              
                <div className="rightpanel learge-side-bar">
                
                  <h4>Where should we send the case studies?</h4>
                
                  
                  <div className="wizard">                   
                    <div className="schedule_consultation-box">

                        <form onSubmit={quickFormik.handleSubmit} id="quickFormik">
                            <div className="details" style={{ display: "block" }}>
                                <div className="row">
                                    <div className="right">
                                        <div className="row">
                                            <div className="buttom-sec">
                                                <div className="form-group">
                                                <label>Name <span style={{ color: '#ff0000'}}>*</span></label>
                                                <input type="text" className='input-style' onChange={quickFormik.handleChange} autoCapitalize="none" autoComplete="new-password"
                                                    value={removeExtraSpace(quickFormik.values.name)} onBlur={quickFormik.handleBlur} name="name" placeholder="Let us know your name"/>
                                                    {quickFormik.touched.name && quickFormik.errors.name ? <div style={{color: '#ff0000'}}>{quickFormik.errors.name}</div> : null}
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="row">
                                            <div className="buttom-sec">
                                                <div className="form-group">
                                                <label>Email <span style={{ color: '#ff0000'}}>*</span></label>
                                                <input className='input-style' onChange={quickFormik.handleChange} autoCapitalize="none" autoComplete="new-password"
                                                    value={removeExtraSpace(quickFormik.values.email)} onBlur={quickFormik.handleBlur} type="email" name="email" placeholder="What is your email?" />
                                                    {quickFormik.touched.email && quickFormik.errors.email ? <div style={{color: '#ff0000'}}>{quickFormik.errors.email}</div> : null}
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                        <div className="row">
                                            <div className="buttom-sec">
                                                <div className="pull-right">
                                                    <button type="submit" disabled={btnLoader} className="default-btn next-step btn-green">
                                                        Send Me To My Email
                                                        {btnLoader ? (
                                                            <span className='btn-ring-animation'></span>
                                                        ):(
                                                            <BsArrowRight />
                                                        )}
                                                        
                                                        
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        {isThankYou && (
                            <div className='alert alert-success mt-3'>
                                Thank you for getting in touch! 
                            </div>
                        )}
                        {isError && (
                            <div className='alert alert-error mt-3'>
                                {isError} 
                            </div>
                        )}
                    </div>
                       
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>    
    </div>

  )
}
