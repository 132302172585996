import React, { useEffect } from 'react';

export default function TawkToChat(){

    useEffect(() => {

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://embed.tawk.to/64c3a80794cf5d49dc66f9ea/1h6e4gof9';
        s.onload = () => {
        // Widget script has been loaded, do any additional setup here if needed.
        };
        document.body.appendChild(s);

        // Clean up the script when the component is unmounted
        return () => {
            document.body.removeChild(s);
        };

    }, []);


    
// var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
// (function(){
// var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
// s1.async=true;
// s1.src='https://embed.tawk.to/64c3a80794cf5d49dc66f9ea/1h6e4gof9';
// s1.charset='UTF-8';
// s1.setAttribute('crossorigin','*');
// s0.parentNode.insertBefore(s1,s0);
// })();
  
  

 
    return null;
  
}
