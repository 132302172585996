import React, { useRef, useState, useEffect } from "react";
import { HeaderLogo,FooterLogo,bannerbtnarrow,expertbackground,epertarrow,reddensoftrlogo,
    worksimageone,worksimagetwo,worksimagethree,worksone,workstwo,reddensoftrlogoone,
    deservebackground,fastaffordablybackground } from "../../Assets/index";

import { Transition } from "@headlessui/react";
import ScheduleConsultation from "../ScheduleConsultation";
import WebFont from "webfontloader";
import "../FullstackDevelope/App.css";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import axios from "axios";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { CSSTransition } from 'react-transition-group';
import CaseStudiesModal from "../CaseStudiesModal";
import { Link, useNavigate } from "react-router-dom";
import './App.css';


function Hiredeveloper() {
    var _ = require('lodash');


    const removeExtraSpace = (s) => {
        var rSpase = s.replace(/\s{2,}/g, ' ');
        return _.trimStart(rSpase);
    };

    useEffect(() => {
        WebFont.load({
        google: {
            families: [
            "Roboto Slab:200,300,400",
            "Montserrat:300,400,500,700",
            "Patua One",
            "Mulish",
            ], // Fonts you want to load
        },
        custom: {
            families: ["Patua One"], // Optional: If you want to load a custom font, specify its name here
            urls: [
            "https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400&display=swap",
            "https://fonts.googleapis.com/css2?family=Montserrat:wght@300,400;500;700&display=swap",
            "https://fonts.googleapis.com/css2?family=Mulish&family=Patua+One&display=swap",
            ], // URLs of the font CSS files
        },
        });

    }, []);

    const [scheduleModel, setScheduleModel] = useState(false);
    const [messageModel, setMessageModel] = useState(false);
    const [isTry, setIsTry] = useState();

    const HandelMessageModel = (e, val) => {
        if (val) {
        setIsTry(val);
        } else {
        setIsTry();
        }
        setMessageModel(true);
    };

    const toggleModal = () => {
        setScheduleModel(false);
        setMessageModel(false);
        setIsCaseStudies(false);
    };


    // accordian
    const [activeIndex, setActiveIndex] = useState(0);
    const handleAccordionClick = (index) => {
        setActiveIndex(index === activeIndex ? -1 : index);
    };

    const accordionItems = [
        {
        id: 1,
        title: "What is the minimum cost of hiring a full-stack developer?",
        content: "You can hire a full-stack developer for $20/ hr.",
        },
        {
        id: 2,
        title: "How can I get them on board?",
        content: "Connect with us about your requirement and you can get a team of full-stack developers to accomplish your project.",
        },
        {
        id: 3,
        title: "How does the full-time process work?",
        content: "In full-time mode, you will get the full stack developer who will get engaged only with your project.",
        },

        {
        id: 4,
        title: "Can I discuss the project before getting the developer on board?",
        content: "Yes, you can discuss with our experts about the project requirement and they will guide you to get the developer onboard.",
        },
        {
        id: 5,
        title: "How to estimate the total cost of the project?",
        content: "Connect with our experts to estimate the project cost at a minimal rate.",
        },
    ];

    const [isThankYou, setIsThankYou] = useState(false);
    const [isError, setIsError] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);

    const [isThankYouTwo, setIsThankYouTwo] = useState(false);
    const [isErrorTwo, setIsErrorTwo] = useState(false);
    const [btnLoaderTwo, setBtnLoaderTwo] = useState(false);

    const [btnLoaderThree, setBtnLoaderThree] = useState(false);

    const [isResponsiveMenu, setIsResponsiveMenu] = useState(false);

    function myGreeting() {
        setIsThankYou(false);
        setIsError(false);
        setIsThankYouTwo(false);
        setIsErrorTwo(false);
    }

    const [guidelineModal, setGuidelineModal] = useState(false);

    const HandelGuidelineModal = () => {
        setGuidelineModal(!guidelineModal)
    }

    const navigate = useNavigate();

    const ref = useRef(null);
    const pricing = useRef(null);
    const faqMenu = useRef(null);
    const testmonialsMenu = useRef(null);
    const homeMenu = useRef(null);

    const HandelPageScroll = () => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    const [isCaseStudies, setIsCaseStudies] = useState(false);

    const HandelCaseStudiesModel = () => {
        setIsCaseStudies(true);
    }


    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    let location = window.location.href;

    var workBannerSlider = {
        dots: true,
        infinite: true,
        arrows: false,
        autoplay: false,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        fade: false,
        autoplaySpeed: 2000,
    };


    return (
    <section className="sales-page graphics full-stack" ref={homeMenu}>
        <Helmet>
            <title>Hire full-stack developers from Reddensoft, from $20.00 /hour.</title>
            <script id="facebook-pixel-script">
            {`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js'),
                    fbq('init', '273096641131718'),
                    fbq('track', 'PageView')
                    `}
            </script>
            <noscript id="facebook-pixel-image">
            {`
                        <img height="1" width="1" style="display:none"
                        src="https://www.facebook.com/tr?id=273096641131718&ev=PageView&noscript=1"
                    />
                    `}
            </noscript>
        </Helmet>

        <section className="header_top_border"></section>

        <section className="banner_header">
            <div className={` header-section ${scrollPosition > 50 ? "fixed-header slide-in" : ""}`}>
                <div className="container">
                    <div className="header-part">
                        <a className="logo" onClick={() => homeMenu.current?.scrollIntoView({behavior: 'smooth'})}>
                            <img src={HeaderLogo} alt="Reddensoft logo" />
                        </a>
                        <div className={` ${isResponsiveMenu ? 'active':''}  header_middle`}>
                            <ul>
                                <li>
                                    <a onClick={() => {pricing.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Pricing</a>
                                </li>
                                <li>
                                    <a onClick={() => {testmonialsMenu.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">Testimonial</a>
                                </li>
                                <li>
                                    <a onClick={() => {faqMenu.current?.scrollIntoView({behavior: 'smooth'}); setIsResponsiveMenu(false)}} className="menu_items">FAQs</a>
                                </li>
                            </ul>
                        </div>
                        <div className="header_right">                    
                            <a onClick={(e) => HandelMessageModel(e, 'Hire a Developer')} className="full-stack-top-btn fill-btn">
                                <span>Try Free (15 Days)</span>
                            </a>
                            <button className="bars-menu" onClick={() => setIsResponsiveMenu(!isResponsiveMenu)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={22} height={22}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner_part">
                <div className="container">
                    <div className="banner_part_text">
                        <h2>faster Solution if On-Demand Workforce</h2>
                        <h1><span>hire</span> the best web developer</h1>
                        <Link to="/">
                            Get started
                            <span>
                                <img src={bannerbtnarrow} alt="" />
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>

        <section className="expert_section" style={{ backgroundImage: `url(${expertbackground})` }}>
            <div className="container">
                <div className="expert_heading">
                    <h2>Expert <span>Web Developers</span> to Build Powerful Web Experiences</h2>
                </div>
                <div className="expert_flex">
                    <div className="expert_para">
                        <p>Website builder is a dynamic and breakthrough business platform that overcomes the conventional ways of creating websites. <span>The essence of our Custom Development services</span> is that we yield modern business websites oriented towards conversions</p>
                    </div>
                    <div className="expert_round">
                        <div className="expert_round_box">
                            <div className="expert_roundspan">
                                <div className="expert_round_text">
                                    <img src={reddensoftrlogo} alt="" />
                                    <h3>Reddensoft delivers High-End</h3>
                                </div>
                            </div>
                            <div className="expert_round_one expert_round_one_position">
                                <h3>Website Development</h3>
                                <img src={epertarrow} alt="" />
                            </div>
                            <div className="expert_round_one expert_round_two_position">
                                <h3>CMS Development</h3>
                                <img src={epertarrow} alt="" />
                            </div>
                            <div className="expert_round_one expert_round_three_position">
                                <h3>Migration Services</h3>
                                <img src={epertarrow} alt="" />
                            </div>
                            <div className="expert_round_one expert_round_four_position">
                                <h3>Conversional design layout for your business website</h3>
                                <img src={epertarrow} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="our_works">
            <div className="top_bar">
                <div className="container">
                    <div className="title">
                        <h2>Our Works</h2>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="works_imagebox">
                    <div className="works_image">
                        <img src={worksimageone} alt="" />
                        <h3>Born Digital <span>By Morgan Wilson</span></h3>
                    </div>
                    <div className="works_image">
                        <img src={worksimagetwo} alt="" />
                        <h3>Team page <span>By Morgan Wilson</span></h3>
                    </div>
                    <div className="works_image">
                        <img src={worksimagethree} alt="" />
                        <h3>Brand Strategy <span>By Morgan Wilson</span></h3>
                    </div>
                </div>             
            </div>
        </section>

        <section className="Work_section">
            <div className="container">
                <div className="Work_section_flex">
                    <div className="Work_section_devide">
                        <div className="Work_devide_text">
                            <h2>Work with reliable, vetted experts</h2>
                            <Link to="/">
                                Get started
                                <span>
                                    <img src={bannerbtnarrow} alt="" />
                                </span>
                            </Link>
                        </div>
                    </div>
                    <div className="Work_section_devide">
                        <div className="Work_devide_logo">
                            <img src={reddensoftrlogoone} alt="" />
                        </div>
                        <div className="Work_devide_slider">
                            <Slider {...workBannerSlider}>
                                <div>
                                    <div className='work_slider_box'>
                                        <div className='work_slider_imagebox'>
                                            <img src={worksone} alt='' />
                                        </div>
                                        <div className='client_slider_text'>
                                            <h4>Mike Anderson</h4>
                                            <p>Senior Developer</p>
                                            <ul>
                                                <li>Php</li>
                                                <li>C#</li>
                                                <li>.NET</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='work_slider_box'>
                                        <div className='work_slider_imagebox'>
                                            <img src={workstwo} alt='' />
                                        </div>
                                        <div className='client_slider_text'>
                                            <h4>Mike Anderson</h4>
                                            <p>Senior Developer</p>
                                            <ul>
                                                <li>Php</li>
                                                <li>C#</li>
                                                <li>.NET</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='work_slider_box'>
                                        <div className='work_slider_imagebox'>
                                            <img src={workstwo} alt='' />
                                        </div>
                                        <div className='client_slider_text'>
                                            <h4>Mike Anderson</h4>
                                            <p>Senior Developer</p>
                                            <ul>
                                                <li>Php</li>
                                                <li>C#</li>
                                                <li>.NET</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="deserve_section">
            <div className="container">
                <div className="deserve_background" style={{ backgroundImage: `url(${deservebackground})` }}>
                    <div className="deserve_flex">
                        <div className="deserve_sub">
                            <div className="deserve_bold_text">
                                <h2><span className="font_size">YOUR CUSTOMERS</span> <span className="font_colour">DESERVE</span> AN ELEVATED EXPERIENCE</h2>
                            </div>
                        </div>
                        <div className="deserve_sub">
                            <div className="deserve_text">
                                <p>When it comes to Online branding, exceptional design and seamless experience is crucial. <span>We’re dedicated to helping</span> your brand stand out from the competition and achieve online success. Ready to take the first step?</p>
                                <Link to="/">
                                    Get started
                                    <span>
                                        <img src={bannerbtnarrow} alt="" />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="mind_blowing mind_blowing_section">
            <div className="container">
                <div className="title">
                    <h3>Mind Blowing Facts</h3>
                </div>
                <div className="d-flex-wap">
                    <div className="divider">
                        <div className="item-one">
                            <h4>Sites gets over</h4>
                            <h2>100</h2>
                            <h3>Million</h3>
                            <p>Monthly visits</p>
                        </div>
                    </div>
                    <div className="divider">
                        <div className="item-two">
                            <div className="item-two-padding">
                                <h4>Over</h4>
                                <h2>450,000</h2>
                                <p>Companies use</p>
                            </div>
                        </div>
                    </div>
                    <div className="divider">
                        <div className="item-one">
                            <h4>Over</h4>
                            <h2>12</h2>
                            <h3>Million</h3>
                            <p>sites build</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='fasts_affordably' style={{ backgroundImage: `url(${fastaffordablybackground})` }}>
            <div className='container'>
                <div className='fast_affordably_box'>
                    <h2>Fast  <br/> <span>& Affordably</span></h2>
                    <h3>Our Hiring process is Quick and transparent .</h3>
                    <p>We’ll handpick developers for you and <span>agree on timeline + roadmap</span>, along with either fixed, monthly, or hourly rates for your needs.</p>
                    <Link onClick={(e) => HandelMessageModel(e)}>
                        Hire expert now
                        <span>
                            <img src={bannerbtnarrow} alt="" />
                        </span>
                    </Link>
                </div>
            </div>
        </section>

        <div ref={testmonialsMenu}></div>

        <section  className="faq-sec-part">
            <div className="container" ref={ref}>
                <h3>Frequently Asked Questions</h3>
                <div className="acordin-sec">
                    <div className="accordion">
                    <div className="accordion-half">
                        {accordionItems.slice(0, 3).map((item, index) => (
                        <div key={index} className="accordion-item">
                            <button className={`accordion-title accordion-item-header ${ activeIndex === item.id ? "active" : "" }`} onClick={() => handleAccordionClick(item.id)}>
                            {item.title}
                            </button>
                            <CSSTransition in={activeIndex === item.id} >
                                {state => (
                                    <div
                                    style={{
                                        maxHeight: state === 'entered' ? '100px' : '0',
                                        overflow: 'hidden',
                                        transition: 'max-height 0.2s ease-in-out',
                                    }}
                                    >
                
                                    <div className="accordion-content">{item.content}</div>
                                    </div>
                                )}
                            </CSSTransition>
                        </div>
                        ))}

                    </div>

                    <div className="accordion-half">
                        {accordionItems.slice(3, 5).map((item, index) => (
                        <div key={index} className="accordion-item">
                            <button
                            className={`accordion-title ${
                                activeIndex === item.id ? "active" : ""
                            }`}
                            onClick={() => handleAccordionClick(item.id)}
                            >
                            {item.title}
                            </button>
                            
                            <CSSTransition in={activeIndex === item.id} >
                                {state => (
                                <div
                                    style={{
                                    maxHeight: state === 'entered' ? '100px' : '0',
                                    overflow: 'hidden',
                                    transition: 'max-height 0.2s ease-in-out',
                                    }}
                                >
                
                                    <div className="accordion-content">{item.content}</div>
                                </div>
                                )}
                            </CSSTransition>
                        </div>
                        ))}
                    </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="contact_ups">
            <div className="container">
                <div className="contact_content">
                    <div className="ttx">
                        <h2>Hire developers on hourly basis and get your project done.</h2>
                        <p>
                            We ensure faster delivery of your product by expert developers. <br/>Starting from $20/hr only.{" "}
                        </p>
                        <h5>Our expert will help you out to organize your project requirement:</h5>
                        <ul>
                            <li>Set the minimal plan to get it done</li>
                            <li>Ensure a faster delivery{" "}</li>
                        </ul>
                    </div>
                    <div className="frm-box-rp">
                        <div className="frm-box">
                            <h3>Not sure where to start?</h3>
                            <p>Let’s have a chat</p>
                            <Formik
                            initialValues={{ name:"", email: "", tryNow: "Not sure where to start" }}
                            validate={(values) => {
                                const errors = {};
                                if (!values.email) {
                                errors.email = "Email is required";
                                } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                    values.email
                                )
                                ) {
                                errors.email = "Invalid email address";
                                }

                                if (!values.name || !_.trim(values.name)) {
                                errors.name = 'Name is required';
                                } else if (values.name.length > 50) {
                                errors.name = 'Must be 15 characters or less';
                                } else if (values.name.match("[<>]")) {
                                errors.name = 'Please provide a valid name';
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setBtnLoaderTwo(true);
                                setSubmitting(false);
                                axios
                                .post(`/lets-chat`, values)
                                .then(function (response) {
                                    // console.log(response);
                                    navigate('/thank-you');
                                    setIsThankYouTwo(true);
                                })
                                .catch(function (error) {
                                    // console.log(error)
                                    setIsErrorTwo(
                                    error?.message ? error.message : "Network Error"
                                    );
                                })
                                .finally(() => {
                                    setBtnLoaderTwo(false);
                                    setTimeout(myGreeting, 5000);
                                    values.email = "";
                                });
                            }}
                            >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <form
                                onSubmit={handleSubmit}
                                className=" "
                                id=""
                                >
                                <label htmlFor="">Your name</label>
                                <input type="text" autoCapitalize="none" autoComplete="new-password" onChange={handleChange} onBlur={handleBlur} name="name" value={removeExtraSpace(values.name)} />
                                {errors.name && touched.name && (
                                    <div
                                        style={{
                                        color: `rgb(255, 0, 0)`,
                                        marginTop: `2px`,
                                        }}
                                    >
                                        {errors.name}
                                    </div>
                                    )}

                                <label htmlFor="">Your company email</label>
                                <input type="email" autoCapitalize="none" autoComplete="new-password" onChange={handleChange} onBlur={handleBlur} name="email" value={values.email} />
                                {errors.email && touched.email && (
                                    <div
                                        style={{
                                        color: `rgb(255, 0, 0)`,
                                        marginTop: `2px`,
                                        }}
                                    >
                                        {errors.email}
                                    </div>
                                    )}

                                <button type="submit" className="link_up">
                                    book a call with us 
                                    {btnLoaderTwo && (
                                    <span className="btn-ring-animation"></span>
                                    )}
                                </button>
                                </form>
                            )}
                            </Formik>
                            {isThankYouTwo && (
                                <div className="alert alert-success mt-3">
                                    Thank you for your interest.
                                </div>
                            )}
                            {isErrorTwo && (
                                <div style={{ color: `rgb(255, 0, 0)`, marginTop: `2px` }}>
                                    {isErrorTwo}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer>
            <div className="container">
                <div className="wap">
                    <div className="item">
                    <a href="#" className="frt-logo">
                        <img src={FooterLogo} alt="" />
                    </a>
                    </div>
                    <div className="item">
                    <a href="tel:+1 678 608 2725" className="call-btn">
                        <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
                            <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"/>
                        </svg>
                        </span>
                        <span className="number-box">
                            (678) 608-2725
                        </span>
                    </a>
                    </div>
                    <div className="item">
                    <p>
                        <a onClick={() => HandelGuidelineModal()}>
                        Privacy Policy
                        </a>
                    </p>
                    </div>
                </div>
            </div>
            <div className="copy-riht">
                <div className="container">
                    <p>ReddenSoft Copyright 2023. All rights reserved.</p>
                </div>
            </div>
        </footer>

        <Transition
            show={messageModel}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal"
        >
        <ScheduleConsultation
            isSchedule={false}
            isNewTry={isTry}
            toggleModal={toggleModal}
        />
        </Transition>

        <Transition
            show={isCaseStudies}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal"
        >
            <CaseStudiesModal toggleModal={toggleModal} />
        </Transition>

        <Transition
            show={scheduleModel}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal"
        >
            <ScheduleConsultation isSchedule={true} toggleModal={toggleModal} />
        </Transition>

        <Transition 
            show={guidelineModal}
            enter="ease-out duration-300"
            enterFrom="opacity-0 relative-modal"
            enterTo="opacity-100 relative-modal"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 relative-modal"
            leaveTo="opacity-0 relative-modal">
                <div className='Guideline-modal'>

                    <button className='inline-btn' onClick={() => HandelGuidelineModal()} type='button'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20px"  height="20px">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div className='Guideline-modal-box'>
                        

                        <h2>Privacy Policy</h2>

                        <p>
                        This privacy policy of ReddenSoft declares the process and purpose of collecting/ storing/ sharing your data while you are using our services.
                        </p>

                        <p>
                        We recommend you read this entire privacy policy thoroughly to get a clear picture of your rights and choices. Using our services means you are agreeing with the privacy policy we have.
                        </p>

                        <h4>
                            Information that we collect:-
                        </h4>

                        <h6>
                            Personal information
                        </h6>

                        <p>
                        We collect the basic information from you like your name, phone number, email id, and website/ name of the organization while you schedule a meeting with us or ask for a partnership program. The purpose is to further communicate with you and get your job done.
                        </p>

                        <h6>
                            Non-personal information
                        </h6>
                        <p>
                        Collecting non-personal identification information about Users happens when they interact with our Site. It may include the type of computer, browser name, and technical information about the user while accessing our site (i.e., the operating system and the Internet service providers used) and other similar information.
                        </p>

                        <h6>
                            Use of collected information:-
                        </h6>

                        <ul className='list-items'>
                            <li>For the betterment of customer service</li>
                            <li>Providing you with the best-personalized user experience</li>
                            <li>For the Site improvement </li>
                            <li>For Payment Processing</li>
                            <li>For the improvement of promotion/ contest/ survey / other features of the Site </li>
                            <li>For sending periodic emails</li>
                        </ul>

                        <h6>
                        Media
                        </h6>

                        <p>
                        We recommend not sharing inappropriate or sensitive images or documents on the website. On the contrary, using images or documents from the website is strictly prohibited and it could be penalized under copyright law.
                        </p>

                        <h6>
                            Embedded content from other websites
                        </h6>

                        <p>
                            Articles on this site may include embedded content (e.g., videos, images, articles, etc.) from other third-party websites. Embedded content from other websites behaves exactly the same way it appeared on the original website.
                        </p>
                        <p>
                            Those third-party we bsites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content (including tracking your interaction with the embedded content) if you are already logged into those websites.
                        </p>

                        <h6>
                        Cookies policy
                        </h6>

                        <p>
                            Our website may use "cookies" to enhance the visitor’s experience. Cookies are being placed by the web browser on their hard drive for record-keeping purposes and sometimes to track information about them. Users may opt to set their web browser to refuse cookies or give them an alert when cookies are being sent. If they do so, then some parts of the website may not function properly.
                        </p>
                        <p>
                            Visitors of our website can subscribe to different services like subscribing to blogs, starting a project with us or joining the partnership program, and for that, we need your name, email address, and website information to store in cookies. These are for your convenience so that you do not have to fill in your details again when you go for other services on the website. These cookies will last for one year.
                        </p>

                        <h6>
                        Information Sharing
                        </h6>
                        <p>
    We strictly oppose sharing information with any third party. Information shared with us will only be used for the betterment of your project and it will be kept only with us. We also request you to not disclose any confidential statement about the ongoing project with any other website or third-party domain.
                        </p>
                        
                    </div>
                </div>  
        </Transition>

    </section>
  );
}

export default Hiredeveloper;